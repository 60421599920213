import React, { Component } from 'react';
import Globals from '../Globals';
import { browserHistory, Link } from 'react-router';

import Loader from '../Components/Loader';
import ProspectList from '../Components/Prospect/List';

import ReactGA from 'react-ga';
import moment from 'moment'

import InputMask from 'react-input-mask';

var lodash = require('lodash')

class SearchProspect extends Component {
  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      prospects: [],
      name: '',
      phone: '',
      email: '',
      zip: '',
      city: '',
      avecInactif: false,
      idprospect: '',
      immat: '',
      VIN: '',
      fetchInProgress: false,
      hasSearched: false,
      page: 0,
      page_size: 15,
      order_column: '',
      order_type: 'asc',
    }

    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleChangeZip = this.handleChangeZip.bind(this);
    this.handleChangeCity = this.handleChangeCity.bind(this);
    this.handleChangeIdprospect = this.handleChangeIdprospect.bind(this);
    this.handleChangeAvecIncatif = this.handleChangeAvecIncatif.bind(this);
    this.handleChangeImmat = this.handleChangeImmat.bind(this);
    this.handleChangeVIN = this.handleChangeVIN.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    var name = this.props.location.query.name ? this.props.location.query.name : ''
    var phone = this.props.location.query.phone ? this.props.location.query.phone : ''
    var email = this.props.location.query.email ? this.props.location.query.email : ''
    var zip = this.props.location.query.zip ? this.props.location.query.zip : ''
    var city = this.props.location.query.city ? this.props.location.query.city : ''
    var idprospect = this.props.location.query.idprospect ? this.props.location.query.idprospect : ''
    var avecInactif = this.props.location.query.avecInactif ? this.props.location.query.avecInactif : ''
    var immat = this.props.location.query.immat ? this.props.location.query.immat : ''
    var VIN = this.props.location.query.VIN ? this.props.location.query.VIN : ''
    var order_column = this.props.location.query.order_column ? this.props.location.query.order_column : ''
    var order_type = this.props.location.query.order_type ? this.props.location.query.order_type : ''

    this.setState({
      name: name,
      phone: phone,
      email: email,
      zip: zip,
      city: city,
      idprospect: idprospect,
      avecInactif: avecInactif,
      immat: immat,
      VIN: VIN,
      order_column: order_column,
      order_type: order_type,
    }, () => {
      if (this.state.name !== '' || this.state.phone !== '' || this.state.email !== '' || this.state.zip !== '' || this.state.city !== '' || this.state.idprospect !== '' || this.state.immat !== '' || this.state.VIN !== '')
        this.fetchProspect()
    })
  }

  fetchProspect() {
    if (!this.state.name && !this.state.phone && !this.state.email && !this.state.zip && !this.state.city && !this.state.idprospect && !this.state.immat && !this.state.VIN)
      return;

    this.setState({ fetchInProgress: true })

    var url = '/Prospects?name='+(this.state.name ? this.state.name : '')+'&phone='+(this.state.phone ? this.state.phone : '')+'&email='+(this.state.email ? this.state.email : '')+'&CodePostal='+(this.state.zip ? this.state.zip : '')+'&Ville='+(this.state.city ? this.state.city : '')+'&idprospect='+(this.state.idprospect ? this.state.idprospect : '')+'&VIN='+(this.state.VIN ? this.state.VIN : '')+'&immat='+(this.state.immat ? this.state.immat : '')+'&type_search=Free&page='+this.state.page+'&page_size='+this.state.page_size;

    if (this.state.avecInactif) {
      url = url + '&AvecInactif=true';
    }

    if (this.state.order_column) {
      url = url + '&order_column='+this.state.order_column;
      if (this.state.order_type) {
        url = url + '&order_column_type='+this.state.order_type;
      }
    }

    var criteres = [];
    if (this.state.name && this.state.name.length) {
      criteres.push('Nom/Prénom');
    }
    if (this.state.email && this.state.email.length) {
      criteres.push('Email');
    }
    if (this.state.phone && this.state.phone.length) {
      criteres.push('Téléphone');
    }
    if (this.state.idprospect && this.state.idprospect.length) {
      criteres.push('Identifiant prospect');
    }
    if (this.state.immat && this.state.immat.length) {
      criteres.push('Immatriculation');
    }
    if (this.state.VIN && this.state.VIN.length) {
      criteres.push('VIN');
    }
    if (this.state.zip && this.state.zip.length) {
      criteres.push('Code postal');
    }
    if (this.state.city && this.state.city.length) {
      criteres.push('Ville');
    }

    ReactGA.event({category: 'Recherche prospect', action: 'Recherche par : ' + lodash.join(criteres, ', ')});

    fetch(localStorage.ClientApiURL + url , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        prospects: json,
      }, () => {
        this.setState({ fetchInProgress: false, hasSearched: true, })
        /*if ((this.state.immat || this.state.VIN) && json.length == 1) {
          browserHistory.push('/lead/create?type=Atelier&prospect_id='+json[0].IDProspect+'&immat='+(this.state.immat ? this.state.immat : '')+'&VIN='+(this.state.VIN ? this.state.VIN : ''));
          return;
        }*/

        if ((this.state.immat || this.state.VIN || this.state.phone || this.state.email) && json.length == 1) {
          browserHistory.push('/prospect/' + json[0].IDProspect);
        }
      });
    })
    .catch((error) => {
      console.log(error)
      this.setState({ fetchInProgress: false })
    });
  }

  changeUrl() {
    if (window.history.replaceState) {
       window.history.replaceState({}, null, '/prospect?name=' + (this.state.name ? this.state.name : '') +'&phone='+ (this.state.phone ? this.state.phone : '') +'&email='+ (this.state.email ? this.state.email : '')+'&zip='+ (this.state.zip ? this.state.zip : '')+'&city='+ (this.state.city ? this.state.city : '')+'&idprospect='+ (this.state.idprospect ? this.state.idprospect : '')+'&avecInactif='+ (this.state.avecInactif ? this.state.avecInactif : '')+'&immat='+ (this.state.immat ? this.state.immat : '')+'&VIN='+ (this.state.VIN ? this.state.VIN : '')+'&order_column='+ (this.state.order_column ? this.state.order_column : '')+'&order_type='+ (this.state.order_type ? this.state.order_type : ''));
    }
  }

  onSubmit(ev) {
    ev.preventDefault();
    this.setState({
      page:0,
    }, () => {
      this.changeUrl();
      this.fetchProspect()
    })
  }

  handleclickAffaire(IDProspect) {
    browserHistory.push('/prospect/' + IDProspect);
  }

  disabledButton() {
    if (this.state.fetchInProgress)
      return true;


    if (this.state.idprospect)
      return false;

    if (this.state.immat)
      return false;

    if (this.state.VIN)
      return false;

    if (!this.state.phone && !this.state.email && !this.state.name)
      return true;

    if (this.state.phone && this.state.phone.length >= 1)
      return false;

    if (this.state.email && this.state.email.length >= 1)
      return false;

    if (this.state.zip && this.state.zip.length >= 1)
      return false;

    if (this.state.city && this.state.city.length >= 1)
      return false;

    if (this.state.name && this.state.name.length >= 1)
      return false;

    return true;
  }

  onClickNext() {
    this.setState({page: this.state.page+1}, () => {
      this.fetchProspect();
    });
  }

  onClickPrevious() {
    this.setState({page: this.state.page-1}, () => {
      this.fetchProspect();
    });
  }

  handleChangeName(event) {
    this.setState({name: event.target.value});
  }
  handleChangeEmail(event) {
    this.setState({email: event.target.value});
  }
  handleChangePhone(event) {
    this.setState({phone: event.target.value});
  }
  handleChangeIdprospect(event) {
    this.setState({idprospect: event.target.value});
  }
  handleChangeAvecIncatif(event) {
    this.setState({avecInactif: event.target.checked});
  }
  handleChangeImmat(event) {
    this.setState({immat: event.target.value.replace(/-/g, '')});
  }
  handleChangeVIN(event) {
    this.setState({VIN: event.target.value});
  }
  handleChangeZip(event) {
    this.setState({zip: event.target.value});
  }
  handleChangeCity(event) {
    this.setState({city: event.target.value});
  }

  onChangeOrder(column, type) {
    this.setState({
      order_column: column,
      order_type: type,
    }, () => {
      this.changeUrl();
      this.fetchProspect()
    })
  }

  render() {
    return (
      <div className="container-fluid" style={{ marginTop: '15px' }}>
        <div className="row">

          <div className="col-md-12">

            <h2 className='title' style={{ marginBottom: 16 }}>
							<img src="img/Zoomer.svg" role="presentation" /> Rechercher un prospect
						</h2>

            <form className="form-horizontal" onSubmit={this.onSubmit} >

              <div className="form-group">
                <div className="col-md-12">
                  <input type="text" name="name" autoFocus={true} className="form-control size-xs" placeholder="Nom, Prénom" value={this.state.name} onChange={this.handleChangeName} style={{ width: 239, display: 'inline-block', marginRight: 30, marginBottom: 15 }} />
                  <input type="text" name="email" className="form-control size-xs" placeholder="Mail" value={this.state.email} onChange={this.handleChangeEmail} style={{ width: 239, display: 'inline-block', marginRight: 30, marginBottom: 15 }} />
                  <input type="text" name="phone" className="form-control size-xs" placeholder="Téléphone" value={this.state.phone} onChange={this.handleChangePhone} style={{ width: 239, display: 'inline-block', marginRight: 30, marginBottom: 15 }} />
                  <input type="text" name="zip" className="form-control size-xs" placeholder="Code postal" value={this.state.zip} onChange={this.handleChangeZip} style={{ width: 239, display: 'inline-block', marginRight: 30, marginBottom: 15 }} />
                  <input type="text" name="city" className="form-control size-xs" placeholder="Ville" value={this.state.city} onChange={this.handleChangeCity} style={{ width: 239, display: 'inline-block', marginRight: 30, marginBottom: 15 }} />

                  <input type="text" name="idprospect" className="form-control size-xs" placeholder="N° prospect" value={this.state.idprospect} onChange={this.handleChangeIdprospect} style={{ width: 239, display: 'inline-block', marginRight: 30, marginBottom: 15 }} />

                  <InputMask mask="**-***-**" maskChar=" " type="text" name="immat" className="form-control size-xs" placeholder="Immatriculation" value={this.state.immat} onChange={this.handleChangeImmat} style={{ width: 239, display: 'inline-block', marginRight: 30, marginBottom: 15 }} />

                  <input type="text" name="VIN" className="form-control size-xs" placeholder="Châssis / VIN" value={this.state.VIN} onChange={this.handleChangeVIN} style={{ width: 239, display: 'inline-block', marginRight: 30, marginBottom: 15 }} minLength="8" maxLength="17" />

                  <br /><br />

                  <input type="checkbox" id="inactifs" checked={this.state.avecInactif} onChange={this.handleChangeAvecIncatif} />
                  <label className="label-box" htmlFor="inactifs">
                    Voir les inactifs
                  </label>
                </div>

                <div className="col-md-12 text-center">
                  <button className="btn btn-default" disabled={this.disabledButton()} style={{ display: 'inline-block', marginLeft: 30 }}>Rechercher</button>
                </div>
              </div>

            </form>

          </div>


          <div className='col-md-12'>
            {this.state.hasSearched ?
              <div className="pull-right" style={{ marginTop: 60, marginBottom: 15}}>
                <Link to={'/lead/create?name=' + (this.state.name ? this.state.name : '') + '&phone=' + (this.state.phone ? this.state.phone : '') + '&email=' + (this.state.email ? this.state.email : '') } className="text-center btn btn-default">
                  Créer un lead
                </Link>
              </div>
            : null}
          </div>


          {!this.state.fetchInProgress && this.state.prospects.length ?
            <div className="wrap-table"><ProspectList prospects={this.state.prospects} onChangeOrder={this.onChangeOrder.bind(this)} order_column={this.state.order_column} order_type={this.state.order_type} /></div>
          : null }

          {!this.state.fetchInProgress && this.state.hasSearched && !this.state.prospects.length ?
            <div className="text-center"><em>Aucun résultat</em></div>
          : null }

          {!this.state.fetchInProgress && this.state.prospects.length ?
              <div className="col-md-12">
                <div className="pager">
                  {this.state.page !== 0 ? <a className="pull-left" onClick={this.onClickPrevious.bind(this)}><i className="fa fa-arrow-left" aria-hidden="true"></i> Page précédente</a> : ''}
                  {this.state.prospects.length >= this.state.page_size ? <a className="pull-right" onClick={this.onClickNext.bind(this)}>Page suivante <i className="fa fa-arrow-right" aria-hidden="true"></i></a> : ''}
                </div>
              </div>
          : null }

          {this.state.fetchInProgress ?
            <div className='col-md-12'>
              <Loader style={{ marginTop: 50 }} />
            </div>
          : null }

        </div>
      </div>
    );
  }
}

export default SearchProspect;
