import React, { Component } from 'react';

import { browserHistory } from 'react-router';

import Loader from '../Components/Loader';
import Globals from '../Globals';

class IframeV5Offres extends Component {

  constructor(props) {
    super(props);

    this.state = {
      
    }
  }
  
  componentDidMount() {
    window.addEventListener(
      "message",
      (ev) => {
        console.log(ev)
        if (typeof ev.data !== "object") return;
        if (!ev.data.type) return;
        if (ev.data.type !== "client-open") return;
        if (!ev.data.message) return;
        
        console.log("Réception message iframe (type / value) : " + ev.data.type + " / " + ev.data.message)
        
        if (ev.data.type == 'client-open') {
          window.location.href = 'prospect/' + ev.data.message;
          return;
        }
        
      }
    );
  }

  render() {

    var url = 'https://' + localStorage.urlEseller + '/iframe_v5.asp?LoginWEB=' + localStorage.user_login + '&Password=' + localStorage.user_password + '&Redirect=offres';
    
    console.log(url)

    return (
        <iframe src={url} style={{ border: 'none', width: '100%', height: '700px' }} />
    );
  }
}

export default IframeV5Offres;
