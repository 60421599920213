import React, { Component } from 'react';

import Globals from '../Globals';
import { Link } from 'react-router';

import FormEdit from '../Components/Prospect/FormEdit';
//import AffaireList from '../Components/Affaire/List';
import ListAffaires from '../Components/Prospect/ListAffaires';
import ListRapports from '../Components/Prospect/ListRapports';
import FiltreRapports from '../Components/Prospect/FiltreRapports';
import ListEssais from '../Components/Prospect/ListEssais';
import ListOffres from '../Components/Prospect/ListOffres';
import ListAlertesVo from '../Components/Prospect/ListAlertesVo';

import VehiculeParcForm from '../Pages/VehiculeParcForm.js';
import AffectationVendeur from '../Components/Prospect/AffectationVendeur';

import SkyLight from 'react-skylight';

import Loader from '../Components/Loader';

import ReactGA from 'react-ga';

import moment from 'moment'
import ModalSendMessage from '../Components/ModalSendMessage';
import ListMessages from '../Components/Prospect/ListMessages';
import AffectationSiteModal from '../Components/Prospect/AffectationSiteModal';
import ModalAdherent from '../Components/Prospect/ModalAdherent';
import ModalGroupement from '../Components/Prospect/ModalGroupement';
import ButtonRGPD from '../Components/ButtonRGPD';
import Tooltip from "rc-tooltip";
import Dictionary from '../Dictionnary';

var lodash = require('lodash')

class Prospect extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      prospect: null,
      vehicules: [],
      affaires: [],
      allAffaires: [],
      rapports: [],
      essais: [],
      offres: [],
      alertes: [],
      refsdms: [],
      viewActivites: 'leads',
      maxDateRapport: null,

      allSites: [],

      filter_rapport : '0',
      selected_vehicule: null,

      nbMessages:0,
      pageVehicules:0,
      loadingVehicules: false,
    }
  }

  componentDidMount() {
    if (this.props.params.prospectId) {
      this.fetchDatas();
      this.fetchAffaires();
      this.fetchRapports();
      this.fetchAllSites();
      this.fetchOffres();
      this.fetchAteliers();
      this.fetchRefDMS();
      this.fetchEssais();
      this.fetchAlertes();
      this.historiqueProspect();
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.params.prospectId === prevProps.params.prospectId) {
      return;
    }

    if (!this.props.params.prospectId)
      return;

      this.fetchDatas();
      this.fetchAffaires();
      this.fetchRapports();
      this.fetchAllSites();
      this.fetchOffres();
      this.fetchAteliers();
      this.fetchRefDMS();
      this.fetchEssais();
      this.fetchAlertes();
  }

  fetchDatas() {
    fetch(localStorage.ClientApiURL + '/Prospects/' + this.props.params.prospectId, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      console.log(json)
      this.setState({
        prospect: json,
        SiteAffectationChange : json.SiteAffectation ? json.SiteAffectation.IDSite : '',
      }, () => {
        this.loadVehicules();
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchAffaires() {
    fetch(localStorage.ClientApiURL + '/Prospects/' + this.props.params.prospectId + '/affaires', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {

      var allAffaires = json;

      var affaires = lodash.filter(json, (affaire) => {
        return ((affaire.Statut === 'AValider') || (affaire.Statut === 'EnCours')) && affaire.NombreDeRapport >= 1;
      });

      this.setState({
        affaires: affaires,
        allAffaires: allAffaires,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchRapports() {
    fetch(localStorage.ClientApiURL + '/Prospects/' + this.props.params.prospectId + '/rapports', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        rapports: json,
      });

      var maxDateRapport = lodash.maxBy(json, (r)=> {
        return r.InfoCreation.DateH;
      });

      if (maxDateRapport) {
        this.setState({
          maxDateRapport: maxDateRapport.InfoCreation.DateH,
        });
      }
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchAllSites() {
    fetch(localStorage.ClientApiURL + '/Plaques', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var sites = [];

      json.map((plaque) => {
        return plaque.Sites.map((site) => {
          sites.push(site);
          return site;
        })
      })

      sites = lodash.uniqBy(sites, (site) => {
        return site.IDSite;
      })
      sites = lodash.sortBy(sites, (site) => {
        return site.Plaque.Nom + ' - ' + (site.Libelle ? site.Libelle : site.Nom);
      })

      this.setState({
        allSites: sites,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchAteliers() {
    fetch(localStorage.ClientApiURL + '/Prospects/' + this.props.params.prospectId + '/historiquesateliers', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      json.sort(function(a, b) {
        if (moment(a.Date) > moment(b.Date))
          return -1;
        return 1;
      });

      this.setState({
        ateliers: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchRefDMS() {
    fetch(localStorage.ClientApiURL + '/Prospects/' + this.props.params.prospectId + '/refdms', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      json.sort(function(a, b) {
        if (moment(a.Date) > moment(b.Date))
          return -1;
        return 1;
      });

      this.setState({
        refsdms: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchEssais() {
    fetch(localStorage.ClientApiURL + '/Prospects/' + this.props.params.prospectId + '/essais', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        essais: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchAlertes() {
    fetch(localStorage.ClientApiURL + '/Prospects/' + this.props.params.prospectId + '/alertevos', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        alertes: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchOffres() {
    fetch(localStorage.ClientApiURL + '/Prospects/' + this.props.params.prospectId + '/offres', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        offres: json,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  async loadVehicules() {
    if (this.state.vehicules && this.state.vehicules.length) {
      return;
    }

    if (this.state.prospect && this.state.prospect.LiensVehicules) {
      this.setState({
        loadingVehicules: true
      })
      var vehicules = [];
      for(let i = this.state.pageVehicules*10; i < (this.state.pageVehicules*10)+10; ++i){
        if(!this.state.prospect.LiensVehicules[i])
          break;
        vehicules.push(await this.loadVehicule(this.state.prospect.LiensVehicules[i].IDVehicule));
      }
      this.setState({
        loadingVehicules: false,
        vehicules: vehicules
      })
    }
  }

  async loadVehicule(IDVehicule) {
    return await fetch(localStorage.ClientApiURL + '/Vehicules/' + IDVehicule, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      return json;
    })
    .catch((error) => {
      console.log(error)
    });
  }

  onChangePageVehicules(n){
    this.setState({
      vehicules: [],
      pageVehicules: this.state.pageVehicules + n
    }, () => {
      this.loadVehicules();
    })
  }

  changeTabActivities(tab, event) {
    this.setState({viewActivites: tab})

    if (tab === 'leads') {
      ReactGA.event({category: 'Prospect', action: 'Suivi d\'activité : Leads'});
    }
    else if (tab === 'rapports') {
      ReactGA.event({category: 'Prospect', action: 'Suivi d\'activité : Tous les rapports'});
    }
    else if (tab === 'offres') {
      ReactGA.event({category: 'Prospect', action: 'Suivi d\'activité : Offres / Commandes'});
    }
    else if (tab === 'essais') {
      ReactGA.event({category: 'Prospect', action: 'Suivi d\'activité : Essais'});
    }
    else if (tab === 'alertes') {
      ReactGA.event({category: 'Prospect', action: 'Suivi d\'activité : Alerte VO'});
    }
    else if (tab === 'rapportsAtelier') {
      ReactGA.event({category: 'Prospect', action: 'Suivi d\'activité : Historique atelier'});
    }

    this.resetFilters();
  }

  changeFilterRapports(value){
    this.setState({
      filter_rapport: value
    });
  }

  resetFilters(){
    this.setState({
      filter_rapport: '0'
    });
  }

  async finishCreateVehicule(IDVehicule) {
    this.refs.modalVehiculeParcClientCreate.hide();
    var prospect = this.state.prospect;
    if(!prospect.LiensVehicules)
      prospect.LiensVehicules = [];
    
    prospect.LiensVehicules.push({
      "IDVehicule": IDVehicule,
      "TypeLien": "Propiétaire",
      "EstPossede": true
    });

    var pageDuVehicule = Math.trunc((prospect.LiensVehicules.length-1) / 10);

    if(pageDuVehicule == this.state.pageVehicules){
      var vehicules = this.state.vehicules;
      vehicules.push(await this.loadVehicule(IDVehicule));
      this.setState({
        prospect: prospect,
        vehicules: vehicules
      })
    }
    else {
      this.setState({
        prospect: prospect,
        pageVehicules: pageDuVehicule,
        vehicules:[]
      }, () => {
        this.loadVehicules()
      })
    }
  }

  historiqueProspect(){

    var _tmp = localStorage.historiqueProspect ? localStorage.historiqueProspect.length ? JSON.parse(localStorage.historiqueProspect) : "" : "";

    if(_tmp.length){
      //check si prospect déjà dans historique
      var notFound = true;
      _tmp.map((prospect, i) => {
        if(prospect === this.props.params.prospectId){
          notFound = false;
        }
        return 1;
      });

      //s'il n'est pas présent, on ajoute en tête d'array
      if(notFound){
        //si la liste de prospect dépasse 20 éléments, on retire le plus ancien prospect
        if(_tmp.length === 20){
          _tmp.pop();
        }
        _tmp.unshift(this.props.params.prospectId);
      }
    }
    else{
      //si historique vide, on remplit
      _tmp = [];
      _tmp.push(this.props.params.prospectId);
    }
    localStorage.historiqueProspect = JSON.stringify(_tmp);
  }

  callEditVehicule(id){
    var vehicule = lodash.find(this.state.vehicules, {IDVehicule: id});
    this.setState({
      selected_vehicule: vehicule,
    });
    this.refs.modalVehiculeEdit.show();
  }

  async finishEditVehicule(IDVehicule){
    this.refs.modalVehiculeEdit.hide();
    var vehicules = this.state.vehicules;
    var idx = lodash.findIndex(vehicules, (v) => {
      return v.IDVehicule == IDVehicule;
    });
    if(idx){
      vehicules[idx] = await this.loadVehicule(IDVehicule);
    }

    this.setState({
      vehicules: vehicules,
    });
  }


  onChangePointVente(event) {
    this.setState({
      SiteAffectationChange : event.target.value ? event.target.value : null
    });
  }

  saveChangePointVente(){
    var prospect = this.state.prospect;
    prospect.SiteAffectation = {
      IDSite: this.state.SiteAffectationChange
    }

    fetch(localStorage.ClientApiURL + '/prospects/'+this.state.prospect.IDProspect, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: JSON.stringify(prospect),
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }

      this.setState({
        prospect: null,
      }, () => {
        this.fetchDatas();
      })

      return;
    })
    .catch((error) => {
      console.log(error)
      this.setState({ updateInProgress: false })
    });
  }

  finishAffectationVendeur() {
    this.setState({
      prospect: null,
    }, () => {
      this.fetchDatas();
    })
  }

  async changeEstPossede(IDVehicule, clientVehicule, event) {

    await fetch(localStorage.ClientApiURL + '/prospects/'+ clientVehicule.IDClient +'/vehicules/'+IDVehicule+'/possede' , {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: JSON.stringify({EstPossede: event.target.checked ? true : false}),
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return
    })
    .catch((error) => {
      console.log(error)
    });

    var vehicules = this.state.vehicules;
    var idx = lodash.findIndex(vehicules, (v) => {
      return v.IDVehicule == IDVehicule;
    });
    if(idx){
      vehicules[idx] = await this.loadVehicule(IDVehicule);
    }

    this.setState({
      vehicules: vehicules,
    });
  }

  openModalSendMessage() {
    if(!this.state.prospect.SiteAffectation){ //Si le prospect n'est pas affecté à un point de vente il faut le faire avant d'envoyer un message
      this.refs.affectSiteModal.show();
    }
    else 
      this.refs.modalSendMessage.show();
  }

  openModalGroupement() {
    this.refs.modalGroupement.show();
  }

  openModaAdherent() {
    this.refs.modalAdherent.show();
  }

  onGroupementSaved(adherents) {
    var prospect = this.state.prospect;
    prospect.Adherents = adherents;
    prospect.IsGroupement = true;
    this.setState({
      prospect: prospect
    })
  }

  siteSaved(IDSite){
    var prospect = this.state.prospect;
    prospect.SiteAffectation = {
      IDSite: IDSite
    };
    this.setState({
      prospect:prospect,
      SiteAffectationChange: null
    });
    this.openModalSendMessage();
  }

  render() {

    if (!this.state.prospect) {
      return <div className='col-md-12'><Loader style={{ marginTop: 170 }} /></div>;
    }

    var siteFictifAffecte;
    if(this.state.prospect.SiteAffectation && this.state.prospect.SiteAffectation.IDSite) 
      siteFictifAffecte = lodash.find(this.state.allSites, {IDSite: this.state.prospect.SiteAffectation.IDSite, EstFictif: true});

    return (
      <div>
        <SkyLight hideOnOverlayClicked ref="modalVehiculeParcClientCreate"
                dialogStyles={{padding:0, width: '90%', left: '5%', marginLeft: '0%', height: '90%', top: '5%', marginTop: '0%', overflow: 'scroll', zIndex: 9999 }}
                title={"Création d'un "+ Dictionary.getUCFirst("véhicule") +" en parc"}
                titleStyle={{ padding: 10, margin: 0, color: 'white' }}
                closeButtonStyle={{ color: 'white'}}>
          <div className='inner-large-content'>
            <VehiculeParcForm IDProspect={this.state.prospect.IDProspect} finishCreateVehicule={this.finishCreateVehicule.bind(this)} />
          </div>
        </SkyLight>

        <SkyLight hideOnOverlayClicked ref="modalVehiculeEdit"
                dialogStyles={{padding:0, width: '90%', left: '5%', marginLeft: '0%', height: '90%', top: '5%', marginTop: '0%', overflow: 'scroll', zIndex: 9999 }}
                title={"Edition d'un "+ Dictionary.getUCFirst("véhicule") +" en parc"}
                titleStyle={{ padding: 10, margin: 0, color: 'white' }}
                closeButtonStyle={{ color: 'white'}}>
          <div className='inner-large-content'>
            <VehiculeParcForm IDProspect={this.state.prospect.IDProspect} vehicule={this.state.selected_vehicule} finishEditVehicule={this.finishEditVehicule.bind(this)} />
          </div>
        </SkyLight>

        <SkyLight hideOnOverlayClicked ref="modalAffectationVendeur" dialogStyles={{ width: '70%', left: '15%', marginLeft: '0%', height: '70%', top: '15%', marginTop: '0%', overflow: 'scroll', zIndex: 9999}}>
          <div className='inner-large-content'>
            <AffectationVendeur prospect={this.state.prospect} finishCreateVehicule={this.finishAffectationVendeur.bind(this)} />
          </div>
        </SkyLight>

        {localStorage.ModuleEmailSMS && localStorage.ModuleEmailSMS === 'true' ?
          <span>
          {!this.state.prospect.SiteAffectation ?
            <AffectationSiteModal ref="affectSiteModal" prospect={this.state.prospect} sites={this.state.allSites} onSiteSaved={(IDSite) => this.siteSaved(IDSite)}/>
          :null}
          <ModalSendMessage ref="modalSendMessage" prospect={this.state.prospect} affaires={this.state.affaires} onMessageSent={() => this.refs.listMessages.fetchMessages()}/>
          </span>
        : null}

        {localStorage.IsAgri === 'true' ?
          this.state.prospect.IsAdherent ?
            <ModalAdherent ref="modalAdherent" prospect={this.state.prospect}/>
          : 
            <ModalGroupement ref="modalGroupement" prospect={this.state.prospect} onGroupementSaved={(adherents) => this.onGroupementSaved(adherents)}/>
        :null}

        <div className="container-fluid">
          <div className="row">

            <div className="col-md-12 prospect-tmp" style={{ marginBottom: '5px' }}>
              <h2 className='title pull-left'>
                <img src="/img/PROSPECT.svg" role='presentation'/> Prospect
                {
                  this.state.prospect.IsGroupement ?
                    <Tooltip trigger={['hover']} overlay={<div>{this.state.prospect.Adherents.map((a) => {return (<div key={a.IDClient} className='row' style={{minWidth:'250px'}}><div className='col-xs-8 text-nowrap'>{a.Nom}</div><div className='col-xs-4 text-right text-nowrap'>{a.NbParts} %</div></div>)})}</div>}>
                      <a className="groupement" onClick={this.openModalGroupement.bind(this)}> / Groupement</a>
                    </Tooltip>
                  :this.state.prospect.IsAdherent ?
                    <Tooltip trigger={['hover']} overlay={<div>{this.state.prospect.Groupements.map((g) => {return (<div key={g.IDClient} className='row' style={{minWidth:'250px'}}><div className='col-xs-9 text-nowrap'>{g.Nom}</div><div className='col-xs-3 text-right text-nowrap'>{g.NbParts} %</div></div>)})}</div>}>
                      <a className="groupement" onClick={this.openModaAdherent.bind(this)}> / Adhérent</a>
                    </Tooltip>
                  : null
                }
              </h2>

              <div className="dropdown pull-right" style={{  }}>

              
                <button style={{ padding: '0px 20px', height: '30px', lineHeight: '30px' }} className="btn btn-xs btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                  Actions
                  <span style={{ marginLeft: '5px' }} className="caret"></span>
                </button>
                <ul className="dropdown-menu menu-actions" aria-labelledby="dropdownMenu1">
                  <li><a onClick={(e) => this.refs.modalAffectationVendeur.show()}>Affecter à un vendeur</a></li>
                  {localStorage.IsAgri === 'true' && this.state.prospect.TypeClient === "Societe" && !this.state.prospect.IsAdherent && !this.state.prospect.IsGroupement ?
                      <li><a onClick={(e) => this.openModalGroupement()}>Transformer en groupement</a></li>
                  :null}
                </ul>
              </div>
            </div>

            <div className="col-md-12 prospect-tmp">
              <span className='prospect-details'>
                <span>
                  <span className='prospect-details-ref'>Réf. : {this.state.prospect.IDProspect}</span>
                  {this.state.refsdms.length ?
                    <span className='prospect-details-refdms'> /
                      Réf. DMS :&nbsp;
                      <Tooltip trigger={['hover']} overlay={<div>{this.state.refsdms.map((ref, i) => { return <div key={i}>{ref.RefDMS} - {ref.PasserelleDMS}</div> })}</div>}>
                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                      </Tooltip>
                    </span>
                  : null}

                </span>

                {this.state.prospect.SuiviPars && this.state.prospect.SuiviPars.length ?
                  <span className='prospect-details-suivi' style={{ paddingLeft: 15 }}>
                    Suivi par : <span className='blue-c bold'>{this.state.prospect.SuiviPars[0].Nom}</span> &nbsp;
                    <Tooltip trigger={['hover']} overlay={<div>{this.state.prospect.SuiviPars.map((people, i) => { return <div key={i}>{people.Nom}</div> })}</div>}>
                      <i className="fa fa-users" aria-hidden="true"></i>
                    </Tooltip>
                  </span>
                : null}

                {this.state.maxDateRapport ?
                  <span className='' style={{ paddingLeft: 15 }}>&nbsp; Dernière action : {moment(this.state.maxDateRapport).format('ddd DD/MM/YYYY HH:mm')}</span>
                : null}

                {localStorage.DPOContactEnabled === 'true' ?
                  <ButtonRGPD IDProspect={this.state.prospect.IDProspect} style={{marginLeft:'20px', height:'auto', padding:'0.25em 0.75em'}}/>
                 : null
                }

                <span style={{ display: 'inline-block', float: 'right' }}>
                  Point de vente :
                  <select value={this.state.SiteAffectationChange ? this.state.SiteAffectationChange : this.state.prospect.SiteAffectation ? this.state.prospect.SiteAffectation.IDSite : ''} style={{ marginLeft: '10px' }} onChange={this.onChangePointVente.bind(this)}>
                    {!this.state.prospect.SiteAffectation || !this.state.prospect.SiteAffectation.IDSite ? 
                      <option></option> 
                    : siteFictifAffecte ?
                      <option value={this.state.prospect.SiteAffectation.IDSite}>{siteFictifAffecte.Plaque.Nom} - {siteFictifAffecte.Libelle}</option>
                    :null}
                    {this.state.allSites.map((s) => {
                      if(!s.EstFictif)
                        return <option key={s.IDSite} value={s.IDSite}>{s.Plaque.Nom} - {s.Libelle}</option>
                    })}
                  </select>
                </span>

                {this.state.SiteAffectationChange && (!this.state.prospect.SiteAffectation || this.state.prospect.SiteAffectation.IDSite != this.state.SiteAffectationChange) ?
                    <span style={{ position: 'absolute', top: '30px', right: '15px', lineHeight: '20px' }} className="btn btn-default size-m" onClick={this.saveChangePointVente.bind(this)}>Enregistrer</span>
                  : null}
              </span>

              <div className="row" style={{ marginTop: '30px' }}>
                <div className="col-md-12">
                  <FormEdit prospect={this.state.prospect} />
                </div>
              </div>

            </div>
          </div>
        </div>

        <div style={{ marginTop: '40px' }}>

          <ul className="nav nav-tabs" role="tablist">
            <li role="presentation" className="active">
              <a href="#activities" aria-controls="activities" role="tab" data-toggle="tab" style={{ paddingLeft: '14px', paddingRight: '14px' }} onClick={() => ReactGA.event({category: 'Prospect', action: 'Onglet : Suivi d\'activité'})}>
                Suivi d'activité
              </a>
            </li>
            <li role="presentation">
              <a href="#cars" aria-controls="cars" role="tab" data-toggle="tab" style={{ paddingLeft: '14px', paddingRight: '14px' }} onClick={() => ReactGA.event({category: 'Prospect', action: 'Onglet : Véhicule'})}>
                {Dictionary.getUCFirst("véhicule")}{this.state.prospect.LiensVehicules && this.state.prospect.LiensVehicules.length > 1 ? 's' : ''} ({this.state.prospect.LiensVehicules ?
                    this.state.prospect.LiensVehicules.length > this.state.vehicules.length ? this.state.prospect.LiensVehicules.length : this.state.vehicules.length : 0})
              </a>
            </li>
            <li role="presentation">
              <a href="#atelier" aria-controls="atelier" role="tab" data-toggle="tab" style={{ paddingLeft: '14px', paddingRight: '14px' }} onClick={() => ReactGA.event({category: 'Prospect', action: 'Onglet : Atelier'})}>
                Atelier ({this.state.ateliers ? this.state.ateliers.length : 0})
              </a>
            </li>
          </ul>

          <div className="tab-content">
            <div role="tabpanel" className="tab-pane active" id="activities">

              <div style={{ height: '36px',  marginTop: '10px', marginBottom: '10px', lineHeight: '20px', float:'none' }}>
                <div className='pull-left form-group' style={{ paddingTop: '10px', paddingBottom: '5px', fontSize: '12px', paddingLeft: '23px', marginBottom: '0' }}>
                  <span>
                    <input type="checkbox" id="leads" onChange={this.changeTabActivities.bind(this, 'leads')} checked={this.state.viewActivites === 'leads'} />
                    <label className='label-box' htmlFor='leads'>
                       Leads ({this.state.affaires ? this.state.affaires.length : 0})
                    </label>
                  </span>

                  <span style={{ marginLeft: '20px' }}>
                    <input type="checkbox" id="rapports" onChange={this.changeTabActivities.bind(this, 'rapports')} checked={this.state.viewActivites === 'rapports'} />
                    <label className='label-box' htmlFor='rapports'>
                       Tous les rapports ({this.state.rapports ? this.state.rapports.length : 0})
                    </label>
                  </span>
                  <span style={{ marginLeft: '20px' }}>
                    <input type="checkbox" id="offres" onChange={this.changeTabActivities.bind(this, 'offres')} checked={this.state.viewActivites === 'offres'} />
                    <label className='label-box' htmlFor='offres'>
                       Offres / Commandes ({this.state.offres ? this.state.offres.length : 0})
                    </label>
                  </span>
                  <span style={{ marginLeft: '20px' }}>
                    <input type="checkbox" id="essais" onChange={this.changeTabActivities.bind(this, 'essais')} checked={this.state.viewActivites === 'essais'} />
                    <label className='label-box' htmlFor='essais'>
                       Essais ({this.state.essais ? this.state.essais.length : 0})
                    </label>
                  </span>
                  <span style={{ marginLeft: '20px' }}>
                    <input type="checkbox" id="alertes" onChange={this.changeTabActivities.bind(this, 'alertes')} checked={this.state.viewActivites === 'alertes'} />
                    <label className='label-box' htmlFor='alertes'>
                       Alerte VO ({this.state.alertes ? this.state.alertes.length : 0})
                    </label>
                  </span>
                  {localStorage.ModuleEmailSMS && localStorage.ModuleEmailSMS === 'true' ?
                    <span style={{ marginLeft: '20px' }}>
                      <input type="checkbox" id="messages" onChange={this.changeTabActivities.bind(this, 'messages')} checked={this.state.viewActivites === 'messages'} />
                      <label className='label-box' htmlFor='messages'>
                        Historique des messages ({this.state.nbMessages})
                      </label>
                    </span>
                  : null}

                  {this.state.viewActivites === 'rapports' ? <FiltreRapports rapports={this.state.rapports} affaires={this.state.allAffaires} filter={this.state.filter_rapport} onChange={this.changeFilterRapports.bind(this)}/> : null}

                </div>

                <div className='pull-right' style={{marginBottom:'5px'}}>
                  {localStorage.ModuleEmailSMS && localStorage.ModuleEmailSMS === 'true' ?
                    <a className="btn btn-default" onClick={() => this.openModalSendMessage()} style={{marginRight: '10px'}}>Envoyer Email/SMS</a>
                   :null}
                  <Link to={'/lead/create?prospect_id='+this.state.prospect.IDProspect+'&EstAppelSortant=true'} className="btn btn-default" onClick={() => ReactGA.event({category: 'Prospect', action: 'Appel sortant'})}>
                    Appel sortant
                  </Link>
                  <Link to={'/lead/create?prospect_id='+this.state.prospect.IDProspect} className="btn btn-default" style={{ marginLeft: '10px' }} onClick={() => ReactGA.event({category: 'Prospect', action: 'Créer un lead'})}>
                    Créer un lead
                  </Link>
                </div>
                <div className='clearfix'></div>
              </div>

              <div  className="clear"></div>

              {this.state.viewActivites === 'leads' ? <ListAffaires affaires={this.state.affaires} rapports={this.state.rapports} /> : null}
              {this.state.viewActivites === 'rapports' ? <ListRapports rapports={this.state.rapports} affaires={this.state.allAffaires} filter={this.state.filter_rapport} /> : null}
              {this.state.viewActivites === 'essais' ? <ListEssais essais={this.state.essais} /> : null}
              {this.state.viewActivites === 'offres' ? <ListOffres offres={this.state.offres} /> : null}
              {this.state.viewActivites === 'alertes' ? <ListAlertesVo alertes={this.state.alertes} /> : null}
              {localStorage.ModuleEmailSMS && localStorage.ModuleEmailSMS === 'true' ? <ListMessages hidden={this.state.viewActivites !== 'messages'} ref="listMessages" IDProspect={this.props.params.prospectId} onLoad={(messages) => this.setState({nbMessages:messages.length})}/> : null}

            </div>

            <div role="tabpanel" className="tab-pane" id="cars" style={{marginBottom: 15}}>
              <div className="row">
                <div className="col-md-12">
                  <br />
                    <table className='table table-eseller-lead table-striped table-hover table-condensed'>
                      <thead>
                        <tr>
                          <th>{localStorage.IsAgri === 'true' ? "Matériel" : "Véhicule"}</th>
                          <th>Immat</th>
                          <th>Chassis</th>
                          <th>Type</th>
                          <th>Mise en circulation</th>
                          <th>{localStorage.IsAgri === 'true' ? "Nb Heures" : "Km"}</th>
                          <th>Date achat</th>
                          <th className='text-center'>Est possédé ?</th>
                          <th className='text-center'>
                              <Tooltip placement="left" trigger={['hover']} overlay={"Ajouter un "+ (localStorage.IsAgri === 'true' ? "matériel" : "véhicule")}>
                                  <a className="picto picto-add" onClick={(e) => this.refs.modalVehiculeParcClientCreate.show()}></a>
                              </Tooltip>
                            </th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.vehicules && this.state.vehicules.length ?
                         this.state.vehicules.map((vehicule, i) => {

                          var clientVehicule = lodash.findLast(vehicule.LiensClients, (v) => {
                            return v.IDClient == this.state.prospect.IDProspect;
                          });

                          return <tr key={i}>
                            <td>{vehicule.Marque} {vehicule.Modele} {vehicule.Version}</td>
                            <td>{vehicule.Immatriculation}</td>
                            <td>{vehicule.VIN}</td>
                            <td>{vehicule.Type}</td>
                            <td>{vehicule.DateMEC ? moment(vehicule.DateMEC).format('DD/MM/Y') : null}</td>
                            <td>{parseInt(vehicule.Kilometrage, 10).toLocaleString("fr-FR")}</td>
                            <td>{vehicule.DateFactureClient ? moment(vehicule.DateFactureClient).format('DD/MM/Y') : null}</td>
                            <td className='text-center'><input type='checkbox' name='EstPossede' checked={clientVehicule.EstPossede} onChange={this.changeEstPossede.bind(this, vehicule.IDVehicule, clientVehicule)} /></td>
                            <td className="action">
                                <Tooltip placement="left" trigger={['hover']} overlay="Modifier">
                                    <a className="picto picto-edit" onClick={(e)=> this.callEditVehicule(vehicule.IDVehicule)}></a>
                                </Tooltip>
                            </td>
                          </tr>
                        })
                      : this.state.prospect.LiensVehicules && this.state.prospect.LiensVehicules.length  ?
                        <tr>
                          <td colSpan={9} className="text-center" style={{padding:20}}> 
                            <Loader styleIcon={{ fontSize: '2em', marginTop:20 }} /> 
                          </td>
                        </tr>
                      : 
                        <tr>
                          <td colSpan={9} className="text-center" style={{padding:20}}> 
                            <em>Aucun {localStorage.IsAgri === 'true' ? 'matériel' : 'véhicule'}</em>
                          </td>
                        </tr> 
                      }
                      </tbody>
                    </table>
                    <div style={{flexDirection:'row'}}>
                      {this.state.pageVehicules > 0 ? 
                        <a className="pull-left" style={{cursor:'pointer'}} onClick={this.onChangePageVehicules.bind(this, -1)}><i className="fa fa-arrow-left" aria-hidden="true"></i> Page précédente</a>
                      :null}
                      {(this.state.pageVehicules+1)*10 < this.state.prospect.LiensVehicules.length ? 
                         <a className="pull-right" style={{cursor:'pointer'}} onClick={this.onChangePageVehicules.bind(this, 1)}>Page suivante <i className="fa fa-arrow-right" aria-hidden="true"></i></a>
                      :null}
                    </div>
                </div>
              </div>
            </div>

            <div role="tabpanel" className="tab-pane" id="atelier">
              <div className="row">
                <div className="col-md-12">
                  <br />
                  {this.state.ateliers && this.state.ateliers.length ?
                    <table className='table table-eseller-lead table-striped table-hover table-condensed'>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Véhicule</th>
                          <th>Immatriculation</th>
                          <th>Km</th>
                          <th>OR</th>
                          <th>Travaux</th>
                          <th>Type</th>
                          <th>Pièces</th>
                          <th>MO</th>
                          <th>Total</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.ateliers.map((atelier, i) => {
                          return <tr key={i}>
                            <td>{moment(atelier.Date).format('DD/MM/Y')}</td>
                            <td>{atelier.Vehicule}</td>
                            <td>{atelier.Immatriculation}</td>
                            <td>{parseInt(atelier.Km, 10).toLocaleString("fr-FR")}</td>
                            <td>{atelier.NumOR}</td>
                            <td style={{ whiteSpace: 'pre-line' }}>{atelier.Travaux.replace(/<br ?\/?>/g, '\n')}</td>
                            <td className='text-center'>
                              <Tooltip trigger={['hover']} overlay={<div>{atelier.Type}</div>}>
                                <span>{atelier.Type ? atelier.Type.charAt(0).toUpperCase() : ''}</span>
                              </Tooltip>
                            </td>
                            <td className='text-right'>{atelier.TotalPiece.toLocaleString('fr-FR')}€</td>
                            <td className='text-right'>{atelier.TotalMO.toLocaleString('fr-FR')}€</td>
                            <td className='text-right'>{Math.round(atelier.TotalPiece+atelier.TotalMO)}€</td>
                            <td>
                              <Tooltip trigger={['hover']} overlay={<div><span>N° facture : {atelier.Numfacture}</span><br />Conseiller Clientèle Service : {atelier.ConseillerClienteleService}<br/>N° série : {atelier.VIN}</div>}>
                                <i className="fa fa-info-circle" aria-hidden="true"></i>
                              </Tooltip>
                            </td>
                          </tr>
                        })}
                      </tbody>
                    </table>
                  :
                    <em>Aucun historique atelier</em>
                  }
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    );
  }
}

export default Prospect;
