import React, { Component } from 'react';
import PanelFilter from './PanelFilter';
import Dictionary from '../Dictionnary';
var lodash = require('lodash')

class FilterType extends Component {

  constructor(props) {
    super(props);

    this.state = {
      types: [],
      selectedTypes: [],
    }
  }

  componentWillMount() {
    this.initDatas();
  }

  componentDidMount() {
    if(this.props.selected){
      this.setState({
        selectedTypes: this.props.selected
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.leads !== prevProps.leads) {
      this.initDatas();
    }
  }

  initDatas() {
    var types = [];
    lodash.forEach(
      lodash.countBy(this.props.leads, (lead) => {
        if(this.props.typeAffichage == "AffairesClotures" || this.props.typeAffichage == "AffairesGagnees")
          return lead.Type;
        else {
          if(lead.Affaire)
            return lead.Affaire.Type;
          else 
            return "-";
        }
      }),
      (nb, type) => {
        types.push({name: type, nb: nb});
    });

    types = lodash.sortBy(types, ['name']);

    this.setState({
      types: types,
    });
  }

  onChangeType( event) {
    var type = event.target.name;
    var selectedTypes = this.state.selectedTypes;

    if(event.target.checked){
      selectedTypes.push(type);
    }
    else{
      selectedTypes = lodash.remove(selectedTypes, (t) => {
        return t != type;
      });
    }

    this.setState({
      selectedTypes: selectedTypes,
    }, () => {
      this.props.onChange('types', this.state.selectedTypes);
    });
  }

  render() {

    var content = null;

    if (this.state.types.length) {
      content = this.state.types.map((type) => {
        return <div className="checkbox" key={type.name}>
          <input type="checkbox" name={type.name} id={"filtreType-"+type.name} checked={(this.state.selectedTypes.indexOf(type.name) != -1) ? true : false} onChange={this.onChangeType.bind(this)} />
          <label className="label-box" htmlFor={"filtreType-"+type.name}>
            {Dictionary.getUCFirst(type.name)}
            &nbsp;<span className="count">({type.nb})</span>
          </label>

          <div className='clearfix' />
        </div>;
      });
    }

    return (
      <PanelFilter title="Par type">
        {content}
      </PanelFilter>
    );
  }
}

export default FilterType;
