import React, { Component } from 'react';
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import Loader from '../Components/Loader';
import Globals from '../Globals';
import moment from 'moment';
moment.locale('fr');
var lodash = require('lodash')

const colors = ["#9a0059", "#ec5858", "#ff7800", "#c51733", "#999999", "#909da9", "#ec5858"]; 
const colors2 = ["#8cc63f", "#f8931e", "#c1272d"];
const typesDemandes = ["Prise de RDV", "Demande de devis", "Suivi travaux", "Réclamations", "Demande d'infos"];


class DashboardAPV extends Component {

  constructor(props) {

    super(props);

    this.state = {
      dataBusiness: null,
      reportingMensuel: null,
      sites: [],
      societes: [],
      typesLeads: [],
      reloadingReportingMensuel: false,
      reloadingDataBusiness: false,

      moisReporting: (new Date()).getMonth()+1,
      anneeReporting: (new Date()).getFullYear(),
      shiftReporting: 0,
      IDSocieteReporting: 0,
      IDSiteReporting: 0,
      IDConseillerReporting: -1,
      IDFamilleReporting: 0,
      IDSousFamilleReporting: 0,
      IDOrigineReporting: 0,
      IDCatClientReporting: 0,
    }
  }

  componentDidMount() {
    var dashboardPrefs = JSON.parse(localStorage.dashboardPrefs);

    var IDSiteDefault = 0, IDSocieteDefault = 0;
    var sites = [];
    var societes = [];
    if(this.props.sites.length) {
      IDSiteDefault = this.props.sites[0].IDSite;
      IDSocieteDefault = this.props.sites[0].Plaque.IDPlaque;

      for(let i in this.props.sites){
        if(this.props.sites[i].EstFictif)
          continue;

        sites.push(this.props.sites[i]);

        if(!societes[this.props.sites[i].Plaque.IDPlaque])
          societes[this.props.sites[i].Plaque.IDPlaque] = this.props.sites[i].Plaque;
      }

      societes = lodash.sortBy(lodash.compact(societes), 'Nom');
      sites = lodash.sortBy(sites, (site) => {return site.Plaque.Nom+' '+(site.Libelle ? site.Libelle : site.Nom)});
    }

    this.setState({
      IDSiteReporting: dashboardPrefs.IDSiteReporting ? dashboardPrefs.IDSiteReporting : IDSiteDefault,
      IDSocieteReporting: dashboardPrefs.IDSocieteReporting ? dashboardPrefs.IDSocieteReporting : IDSocieteDefault,
      sites: sites,
      societes: societes
    }, () => {
      this.fetchDataBusiness();
      this.fetchReportingMensuel();
    });
  }

  fetchDataBusiness(){
    var url = localStorage.ClientApiURL + '/dashboard/statsBusinessAPV?';
    if(this.state.IDSocieteReporting > 0)url += "&IDSociete="+this.state.IDSocieteReporting;
    if(this.state.IDSiteReporting > 0)url += "&IDSite="+this.state.IDSiteReporting;
    if(this.state.IDConseillerReporting > -1)url += "&IDConseiller="+this.state.IDConseillerReporting;
    if(this.state.IDFamilleReporting > 0)url += "&IDFamilleOrigine="+this.state.IDFamilleReporting;
    if(this.state.IDSousFamilleReporting > 0)url += "&IDSousFamilleOrigine="+this.state.IDSousFamilleReporting;
    if(this.state.IDOrigineReporting > 0)url += "&IDOrigine="+this.state.IDOrigineReporting;
    if(this.state.IDCatClientReporting > 0)url += "&IDCatClient="+this.state.IDCatClientReporting;

    fetch(url , {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(json => {
        console.log(json)

        var leadsParTypeDemande = [];
        for(let i in typesDemandes){
          leadsParTypeDemande.push({
            "id": typesDemandes[i],
            "value": json.leadsParTypeDemande[typesDemandes[i]]
          });
        }
        json.leadsParTypeDemande = leadsParTypeDemande;

        var leadsParDelaiAttente = [];
        var total = lodash.reduce(json.leadsParDelaiAttente, function(sum, n) {
          return sum + n;
        }, 0);
        for(let delai in json.leadsParDelaiAttente){
          leadsParDelaiAttente.push({
            id: delai,
            label: delai,
            value: total > 0 ? Math.round(json.leadsParDelaiAttente[delai]/total*1000)/10 : 0
          })
        }
        json.leadsParDelaiAttente = leadsParDelaiAttente
        
        this.setState({
          dataBusiness: json,
          reloadingDataBusiness: false,
        }, () => console.log(this.state.dataBusiness))
      })
      .catch((error) => {
        console.log(error)
      });
  }

  fetchReportingMensuel(){
      if(this.state.shiftReporting < 0) { //Cumul année
        var dateDebut = moment().add(parseInt(this.state.shiftReporting) + 1, 'y').startOf('year').format('YYYY-MM-DD');
        var dateFin = moment().add(parseInt(this.state.shiftReporting) + 1, 'y').endOf('year').format('YYYY-MM-DD');
      }
      else {
        var date = moment(this.state.anneeReporting+'-'+this.state.moisReporting+'-01');
        var dateDebut = date.startOf('month').format('YYYY-MM-DD');
        var dateFin = date.endOf('month').format('YYYY-MM-DD');
      }

      var url = localStorage.ClientApiURL + '/dashboard/reportingMensuelAPV?dateDebut='+dateDebut+'&dateFin='+dateFin;
      if(this.state.IDSocieteReporting > 0)url += "&IDSociete="+this.state.IDSocieteReporting;
      if(this.state.IDSiteReporting > 0)url += "&IDSite="+this.state.IDSiteReporting;
      if(this.state.IDConseillerReporting > -1)url += "&IDConseiller="+this.state.IDConseillerReporting;
      if(this.state.IDFamilleReporting > 0)url += "&IDFamilleOrigine="+this.state.IDFamilleReporting;
      if(this.state.IDSousFamilleReporting > 0)url += "&IDSousFamilleOrigine="+this.state.IDSousFamilleReporting;
      if(this.state.IDOrigineReporting > 0)url += "&IDOrigine="+this.state.IDOrigineReporting;
      if(this.state.IDCatClientReporting > 0)url += "&IDCatClient="+this.state.IDCatClientReporting;

      fetch(url , {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(json => {
        console.log(json)
        
        var leadsBusiness = {"id": "Business"}
        var leadsActivite = {"id": "Suivi activité"}
        json.nbLeadsRecus = 0;
        json.nbLeadsNonTraites = 0;
        lodash.each(json.leadsParTypeDemande, (data, type) => {
          if(type === "Prise de RDV" || type === "Demande de devis"){
            leadsBusiness[type] = data["Traité"] + data["Non traité"];
            leadsBusiness["_"+type+"_NOK"] = data["Non traité"];
            leadsBusiness["_"+type+"_Label"] = type;
          }
          else {
            leadsActivite[type] = data["Traité"] + data["Non traité"];
            leadsActivite["_"+type+"_NOK"] = data["Non traité"];
            leadsActivite["_"+type+"_Label"] = type;
          }
          json.nbLeadsRecus += data["Traité"] + data["Non traité"]
          json.nbLeadsNonTraites += data["Non traité"]
        })

        json.leadsBusiness = leadsBusiness;
        json.leadsActivite = leadsActivite;

        var leadsTraites = {
          "Reportés": 0,
          "Gagnés": 0,
          "Perdus": 0,
          "Total": 0,
        };

        for(let i in json.leadsTraites){
          leadsTraites[json.leadsTraites[i].Statut] = json.leadsTraites[i].Nb
          leadsTraites["Total"] += json.leadsTraites[i].Nb
        }

        json.leadsTraites = leadsTraites;

        lodash.each(json.leadsBusinessTraites, (lb) => {
          lb.Total = lb["Gagné"] + lb["Perdu"];
          lb.Stats = [{
            "id": "Gagnés",
            "label": "Gagnés",
            "value": lb.Total > 0 ? Math.round(lb["Gagné"]/lb.Total*1000)/10 : 0
          },{
            "id": "Perdus",
            "label": "Perdus",
            "value": lb.Total > 0 ? Math.round(lb["Perdu"]/lb.Total*1000)/10 : 0
          }]
        })

        this.setState({
          reportingMensuel: json,
          reloadingReportingMensuel: false,
        }, () => console.log(this.state.reportingMensuel));
      })
      .catch((error) => {
        console.log(error)
      });
  }

  reloadData(){
    this.fetchDataBusiness();
    this.fetchReportingMensuel();
  }

  onChangeMoisReporting(event){
    let date = moment().startOf('month').subtract(event.target.value, 'month');
    this.setState({
      shiftReporting: event.target.value,
      moisReporting: date.month()+1,
      anneeReporting: date.year(),
      reloadingReportingMensuel: true,
    }, () => this.fetchReportingMensuel())
  }

  onChangeSiteReporting(event){
    var dashboardPrefs = JSON.parse(localStorage.dashboardPrefs);
    if(event.target.value > 0){
      dashboardPrefs.IDSiteReporting = event.target.value;

      var site = lodash.find(this.state.sites, (s) => {return s.IDSite == dashboardPrefs.IDSiteReporting});
      if(site)
        dashboardPrefs.IDSocieteReporting = site.Plaque.IDPlaque;
    }
    localStorage.dashboardPrefs = JSON.stringify(dashboardPrefs)

    this.setState({
      IDSiteReporting: event.target.value,
      IDSocieteReporting: site ? site.Plaque.IDPlaque : this.state.IDSocieteReporting,
      reloadingReportingMensuel: true,
      reloadingDataBusiness: true,
    }, () => this.reloadData())
  }

  onChangeConseillerReporting(event){
    this.setState({
      IDConseillerReporting: event.target.value,
      reloadingReportingMensuel: true,
      reloadingDataBusiness: true,
    }, () => this.reloadData())
  }

  onChangeSocieteReporting(event){
    this.setState({
      IDSocieteReporting: event.target.value,
      IDSiteReporting: 0,
      reloadingReportingMensuel: true,
      reloadingDataBusiness: true,
    }, () => this.reloadData())
  }

  onChangeFamilleReporting(event){
    this.setState({
      IDFamilleReporting: event.target.value,
      IDSousFamilleReporting: 0,
      IDOrigineReporting: 0,
      reloadingReportingMensuel: true,
      reloadingDataBusiness: true,
    }, () => this.reloadData())
  }

  onChangeSousFamilleReporting(event){
    this.setState({
      IDSousFamilleReporting: event.target.value,
      IDOrigineReporting: 0,
      reloadingReportingMensuel: true,
      reloadingDataBusiness: true,
    }, () => this.reloadData())
  }

  onChangeOrigineReporting(event){
    this.setState({
      IDOrigineReporting: event.target.value,
      reloadingReportingMensuel: true,
      reloadingDataBusiness: true,
    }, () => this.reloadData())
  }

  onChangeCatClientReporting(event){
    this.setState({
      IDCatClientReporting: event.target.value,
      reloadingReportingMensuel: true,
      reloadingDataBusiness: true,
    }, () => this.reloadData())
  }

  getFamilles() {
    var familles = [{value: "", label:"Toutes les familles"}];
    familles.push(...lodash.map(lodash.uniqBy(this.state.dataBusiness.origines, "IDFamille"), (o) => {return {"value": o.IDFamille, "label": o.Famille}}));
    return familles;
  }

  getSousFamilles() {
    var sousFamilles = [{value: "", label:"Toutes les sous-familles"}];

    if(this.state.IDFamilleReporting)
    {
      var origines = lodash.filter(this.state.dataBusiness.origines, (o) => o.IDFamille == this.state.IDFamilleReporting);
      sousFamilles.push(...lodash.map(lodash.uniqBy(origines, "IDSousFamille"), (o) => {return {"value": o.IDSousFamille, "label": o.SousFamille}}));
    }

    return sousFamilles;
  }

  getOrigines() {
    var origines = [{value: "", label:"Toutes les origines"}];

    if(this.state.IDSousFamilleReporting)
    {
      var origines_ = lodash.filter(this.state.dataBusiness.origines, (o) => o.IDFamille == this.state.IDFamilleReporting && o.IDSousFamille == this.state.IDSousFamilleReporting);
      origines.push(...lodash.map(origines_, (o) => {return {"value": o.IDOrigine, "label": o.Origine}}));
    }
  
    return origines;
  }

  render() {

    if(!this.state.dataBusiness || !this.state.reportingMensuel){
      return (<div className='col-md-12'>
          <Loader style={{ marginTop: 150 }} />
        </div>);
    }

    var leadsParCategorie = [this.state.reportingMensuel.leadsBusiness, this.state.reportingMensuel.leadsActivite];
    
    const familles = this.getFamilles();
    const sousFamilles = this.getSousFamilles();
    const origines = this.getOrigines();
    const sousFamillesDisabled = !this.state.IDFamilleReporting;
    const originesDisabled = !this.state.IDSousFamilleReporting;

    return (

      <React.Fragment>
        {/* Portefeuille business */}
        <div>
          <h2>Portefeuille business</h2>
          <select style={{marginLeft:'1rem'}} value={this.state.IDSocieteReporting} onChange={this.onChangeSocieteReporting.bind(this)}>
            <option value={0}>Toutes les sociétés</option>
            {this.state.societes.map((soc, i) => {
              return <option key={i} value={soc.IDPlaque}>{soc.Nom}</option>
            })}
          </select>
          <select style={{marginLeft:'1rem'}} value={this.state.IDSiteReporting} onChange={this.onChangeSiteReporting.bind(this)}>
            <option value={0}>Tous les sites</option>
            {this.state.sites.map((site, i) => {
              if(this.state.IDSocieteReporting > 0 && site.Plaque.IDPlaque != this.state.IDSocieteReporting)
                return null;

              return <option key={i} value={site.IDSite}>{site.Plaque.Nom} {site.Libelle ? site.Libelle : site.Nom}</option>
            })}
          </select>
          <select style={{marginLeft:'1rem'}} value={this.state.IDConseillerReporting} onChange={this.onChangeConseillerReporting.bind(this)}>
            {this.state.IDConseillerReporting > -1 && !lodash.find(this.state.reportingMensuel.conseillers, (c) => {return c.IDPeople == this.state.IDConseillerReporting}) ? 
              <option value=''></option>
            :null}
            <option value={-1}>Tous les conseillers</option>
            {this.state.reportingMensuel.conseillers.map((conseiller) => {
              return <option key={conseiller.IDPeople} value={conseiller.IDPeople}>{conseiller.Name}</option>
            })}
          </select>
          <br/>
          <select style={{marginLeft:'1rem'}} value={this.state.IDFamilleReporting} onChange={this.onChangeFamilleReporting.bind(this)}>
            {familles.map((famille, i) => {
              return <option key={i} value={famille.value}>{famille.label}</option>
            })}
          </select>
          <select style={{marginLeft:'1rem', opacity: sousFamillesDisabled ? 0.5 : 1}} value={this.state.IDSousFamilleReporting} onChange={this.onChangeSousFamilleReporting.bind(this)} disabled={!this.state.IDFamilleReporting}>
            {sousFamilles.map((sousFamille, i) => {
              return <option key={i} value={sousFamille.value}>{sousFamille.label}</option>
            })}
          </select>
          <select style={{marginLeft:'1rem', opacity: originesDisabled ? 0.5 : 1}} value={this.state.IDOrigineReporting} onChange={this.onChangeOrigineReporting.bind(this)} disabled={!this.state.IDSousFamilleReporting}>
            {origines.map((origine, i) => {
              return <option key={i} value={origine.value}>{origine.label}</option>
            })}
          </select>
          <select style={{marginLeft:'1rem'}} value={this.state.IDCatClientReporting} onChange={this.onChangeCatClientReporting.bind(this)}>
            <option value="">Toutes les catégories client</option>
            {this.state.dataBusiness.categoriesClient.map((categorie, i) => {
              return <option key={i} value={categorie.IDCatClient}>{categorie.Descr}</option>
            })}
          </select>

          <div style={{display:'flex', flex:1, flexDirection:'column', position:'relative'}}>
            {this.state.reloadingDataBusiness ? 
              <div style={{position:'absolute', display:'flex', justifyContent:'center', alignItems:'center', zIndex:10, left:0, top:0, backgroundColor:'#fff', width:'100%', height:'100%', opacity:0.9}}>
                <Loader style={{  }} />
              </div>
            :null}
            <div style={{display:'flex'}}>
              {/* Répartition des leads non traités */}
              <div className="cadre" style={{display:'flex', flex:0.6, flexDirection:'column', justifyContent:'space-between', alignItems:'start'}}>
                <div className="title">Répartition des leads non traités</div>
                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', flex:1, width:'100%'}}>
                  <div style={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly', flex:0.2}}>
                    <div className="big-label">Total</div>
                    <div className="big-value">{lodash.sumBy(this.state.dataBusiness.leadsParTypeDemande, 'value')}</div>
                  </div>
                  <div style={{display:'flex', height:'250px', flex:0.8}}>
                    <ResponsiveBar
                      data={this.state.dataBusiness.leadsParTypeDemande}
                      colors={ colors }
                      borderRadius={4}
                      padding={0.3}
                      colorBy="index"
                      margin={{ top: 50, right: 0, bottom: 50, left: 0 }}
                      tooltip={({ id, value, index, indexValue, color, data }) => (
                          <span>
                            <svg width={10} height={10}>
                              <rect width={10} height={10} style={{fill:color}}/>
                            </svg>
                            &nbsp;&nbsp;{indexValue} : {value}
                          </span>
                      )}
                      axisTop={null}
                      axisRight={null}
                      axisBottom={{
                          tickSize: 0,
                          tickPadding: 12,
                          //tickRotation: -30,
                      }}
                      axisLeft={null}
                      enableGridY={false}
                      labelTextColor="#ec5858"
                      labelFormat={d => <tspan y={-10} fontSize={12}>{ d }</tspan>}
                      legends={[]}
                      animate={true}
                      motionStiffness={90}
                      motionDamping={15}
                    />
                  </div>
                </div>
              </div>
              {/* Leads en attente de traitement depuis */}
              <div className="cadre" style={{display:'flex', flex:0.4, flexDirection:'column', justifyContent:'space-between', alignItems:'start'}}>
                <div className="title">Leads en attente de traitement depuis</div>
                <div style={{height:'250px', width:'100%'}}>
                  <ResponsivePie
                    data={this.state.dataBusiness.leadsParDelaiAttente}
                    colors={ colors2 }
                    enableRadialLabels={false}
                    sliceLabel={function(e){return <tspan fontSize={12}>{e.value} %</tspan>}}
                    slicesLabelsSkipAngle={0}
                    slicesLabelsTextColor="#ffffff"
                    slicesLabelsSkipAngle={0.1}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    margin={{left:60, top:30, right:-60, bottom:30}}
                    tooltip={({ id, value, index, indexValue, color, data }) => (
                      <span>
                        <svg width={10} height={10}>
                          <rect width={10} height={10} style={{fill:color}}/>
                        </svg>
                        &nbsp;&nbsp;{id} : {value}%
                      </span>
                    )}
                    legends={[
                      {
                          anchor: 'left',
                          direction: 'column',
                          itemWidth: 100,
                          itemHeight: 18,
                          itemTextColor: '#333',
                          itemsSpacing: 2,
                          symbolSize: 18,
                          symbolShape: 'circle',
                          effects: [
                              {
                                  on: 'hover',
                                  style: {
                                      itemTextColor: '#000'
                                  }
                              }
                          ]
                      }
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Fin portefeuille business */}

        {/* Reporting mensuel */}
        <div style={{marginTop:'2rem'}}>
        <div>
            <h2>Reporting mensuel</h2>
            <select style={{marginLeft:'1rem'}} value={this.state.shiftReporting} onChange={this.onChangeMoisReporting.bind(this)}>
              <option value={-1}>Cumul {(new Date()).getFullYear()}</option>
              {lodash.range(0, 6).map((dec, i) => {
                let month = moment().startOf('month').subtract(dec, 'month');
                return <option key={i} value={dec}>{month.format('MMMM YYYY').toUpperCase()}</option>
              })}
              <option value={-2}>Cumul {(new Date()).getFullYear() - 1}</option>
            </select>
            <select style={{marginLeft:'1rem'}} value={this.state.IDSocieteReporting} onChange={this.onChangeSocieteReporting.bind(this)}>
              <option value={0}>Toutes les sociétés</option>
              {this.state.societes.map((soc, i) => {
                return <option key={i} value={soc.IDPlaque}>{soc.Nom}</option>
              })}
            </select>
            <select style={{marginLeft:'1rem'}} value={this.state.IDSiteReporting} onChange={this.onChangeSiteReporting.bind(this)}>
              <option value={0}>Tous les sites</option>
              {this.state.sites.map((site, i) => {
                if(this.state.IDSocieteReporting > 0 && site.Plaque.IDPlaque != this.state.IDSocieteReporting)
                  return null;

                return <option key={i} value={site.IDSite}>{site.Plaque.Nom} {site.Libelle ? site.Libelle : site.Nom}</option>
              })}
            </select>
            <select style={{marginLeft:'1rem'}} value={this.state.IDConseillerReporting} onChange={this.onChangeConseillerReporting.bind(this)}>
              {this.state.IDConseillerReporting > -1 && !lodash.find(this.state.reportingMensuel.conseillers, (c) => {return c.IDPeople == this.state.IDConseillerReporting}) ? 
                <option value=''></option>
              :null}
              <option value={-1}>Tous les conseillers</option>
              {this.state.reportingMensuel.conseillers.map((conseiller) => {
                return <option key={conseiller.IDPeople} value={conseiller.IDPeople}>{conseiller.Name}</option>
              })}
            </select>
            <br/>
            <select style={{marginLeft:'1rem'}} value={this.state.IDFamilleReporting} onChange={this.onChangeFamilleReporting.bind(this)}>
              {familles.map((famille, i) => {
                return <option key={i} value={famille.value}>{famille.label}</option>
              })}
            </select>
            <select style={{marginLeft:'1rem', opacity: sousFamillesDisabled ? 0.5 : 1}} value={this.state.IDSousFamilleReporting} onChange={this.onChangeSousFamilleReporting.bind(this)} disabled={!this.state.IDFamilleReporting}>
              {sousFamilles.map((sousFamille, i) => {
                return <option key={i} value={sousFamille.value}>{sousFamille.label}</option>
              })}
            </select>
            <select style={{marginLeft:'1rem', opacity: originesDisabled ? 0.5 : 1}} value={this.state.IDOrigineReporting} onChange={this.onChangeOrigineReporting.bind(this)} disabled={!this.state.IDSousFamilleReporting}>
              {origines.map((origine, i) => {
                return <option key={i} value={origine.value}>{origine.label}</option>
              })}
            </select>
            <select style={{marginLeft:'1rem'}} value={this.state.IDCatClientReporting} onChange={this.onChangeCatClientReporting.bind(this)}>
              <option value="">Toutes les catégories client</option>
              {this.state.dataBusiness.categoriesClient.map((categorie, i) => {
                return <option key={i} value={categorie.IDCatClient}>{categorie.Descr}</option>
              })}
            </select>
          </div>
          <div style={{display:'flex', flex:1, flexDirection:'column', position:'relative'}}>
          {this.state.reloadingReportingMensuel ? 
            <div style={{position:'absolute', display:'flex', justifyContent:'center', alignItems:'center', zIndex:10, left:0, top:0, backgroundColor:'#fff', width:'100%', height:'100%', opacity:0.9}}>
              <Loader style={{  }} />
            </div>
          :null}
            <div style={{display:'flex'}}>
              {/* Leads reçus */}
              <div className="cadre" style={{width:'160px', height:'160px', display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'center'}}>
                <div className="title">Leads reçus</div>
                <div className="big-value" style={{display: 'flex', flex:1, flexDirection: 'column', justifyContent: 'center'}}>{this.state.reportingMensuel.nbLeadsRecus}</div>
              </div>
              {/* Nombre de leads par catégorie */}
              <div className="cadre" style={{width:'auto', height:'160px', display:'flex', flex:1, flexDirection:'column', justifyContent:'space-between', alignItems:'start'}}>
                <div className="title">Nombre de leads par catégories <i>(non traités / reçus)</i> (sur la période sélectionnée)</div>
                <div style={{display:'flex', flex:1, flexDirection:'row', width:'100%', justifyContent:'space-evenly', paddingTop:'1rem'}}>
                  <div style={{flex:0.1, display:'flex', flexDirection:'column', justifyContent:'space-evenly'}}>
                      <div className="text-center">Leads<br/>non traités</div>
                      <div className="big-value text-center">{this.state.reportingMensuel.nbLeadsNonTraites}</div>
                  </div>
                  <div style={{display:'flex', flexDirection:'column', flex:0.9, justifyContent:'space-evenly', paddingRight:'1rem'}}>
                    {leadsParCategorie.map((categorie, i) => {
                      return (<div key={i} style={{height: '35px', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        <ResponsiveBar
                          data={[categorie]}
                          indexBy="id"
                          keys={lodash.filter(Object.keys(categorie), function(key) {
                            return key !== 'id' && key.charAt(0) !== "_";
                          })}
                          label={function(d){return <tspan>{categorie['_'+d.id+'_Label']} {categorie['_'+d.id+'_NOK']}/{d.value}</tspan>}}
                          tooltip={({ id, value, index, indexValue, color, data }) => (
                              <span>
                                <svg width={10} height={10}>
                                  <rect width={10} height={10} style={{fill:color}}/>
                                </svg>
                                &nbsp;&nbsp;{categorie['_'+id+'_Label']} : {categorie['_'+id+'_NOK']} non traités/{value} reçus
                              </span>
                          )}
                          layout="horizontal"
                          colors={colors}
                          axisTop={null}
                          axisRight={null}
                          enableGridY={false}
                          labelTextColor="#fff"
                          animate={true}
                          motionStiffness={90}
                          motionDamping={15}
                          margin={{left:100}}
                          labelSkipWidth={50}
                          axisLeft={{
                            tickSize: 0,
                            tickPadding: 15,
                            tickRotation: 0,
                          }}
                          />
                      </div>)
                    })}
                  </div>
                </div>
                {/* Fin nombre de leads par catégorie  */}
              </div>
            </div>

            {/* 2ème ligne reporting mensuel */}
            <div style={{display:'flex'}}>
              {/* Leads traités */}
              <div className="cadre" style={{display:'flex', flex:0.4, flexDirection:'column', justifyContent:'space-between', alignItems:'start'}}>
                <div className="title">Leads traités (sur la période sélectionnée)</div>
                <div style={{display:'flex', flex:1, width:'100%', justifyContent:'space-between', alignItems:'center'}}>
                  <div style={{flex:0.3, textAlign:'center'}} className="big-value">{this.state.reportingMensuel.leadsTraites["Total"]}</div>
                  <div style={{flex:0.7, display:'flex', justifyContent:'space-evenly', alignItems:'center'}}>
                      <div style={{color:"#ec5858"}}>&#9654;</div>
                      <div style={{display: 'flex', flexDirection:'column', width:'100%', paddingLeft:'3rem'}}>
                        <div style={{display:'flex', flexGrow:1, flexShrink:1, justifyContent:'space-between'}}>
                          <div style={{flex:0.4}}>Reportés</div>
                          <div style={{flex:0.3}}><img src='img/dotline.png' style={{width:'80%'}} alt='O-----'/></div>
                          <div style={{flex:0.3, color:"#ec5858", fontSize:'1.6rem'}}>{this.state.reportingMensuel.leadsTraites["Reportés"]}</div>
                        </div>
                        <div style={{display:'flex', flexGrow:1, flexShrink:1, justifyContent:'space-between'}}>
                          <div style={{flex:0.4}}>Gagnés</div>
                          <div style={{flex:0.3}}><img src='img/dotline.png' style={{width:'80%'}} alt='O-----'/></div>
                          <div style={{flex:0.3, color:"#ec5858", fontSize:'1.6rem'}}>{this.state.reportingMensuel.leadsTraites["Gagnés"]}</div>
                        </div>
                        <div style={{display:'flex', flexGrow:1, flexShrink:1, justifyContent:'space-between'}}>
                          <div style={{flex:0.4}}>Perdus</div>
                          <div style={{flex:0.3}}><img src='img/dotline.png' style={{width:'80%'}} alt='O-----'/></div>
                          <div style={{flex:0.3, color:"#ec5858", fontSize:'1.6rem'}}>{this.state.reportingMensuel.leadsTraites["Perdus"]}</div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              {/* Répartition des leads business */}
              <div className="cadre" style={{width:'auto', display:'flex', flex:1, flexDirection:'column', justifyContent:'space-between', alignItems:'start'}}>
                <div className="title">Répartition des leads business (sur la période sélectionnée)</div>

                <div style={{display:'flex', justifyContent:'space-evenly', marginTop:'7rem', paddingBottom:'1rem', width:'100%'}}>
                  <div style={{flex:0.7}}>
                    <div style={{height:'140px', marginLeft:'3rem', position:'relative'}}>
                      <ResponsivePie
                        data={this.state.reportingMensuel.leadsBusinessTraites["Demande de devis"].Stats}
                        colors={ colors2 }
                        enableRadialLabels={false}
                        sliceLabel={function(e){return <tspan fontSize={12}>{e.value} %</tspan>}}
                        slicesLabelsSkipAngle={0}
                        slicesLabelsTextColor="#ffffff"
                        slicesLabelsSkipAngle={0.1}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        tooltip={({ id, value, index, indexValue, color, data }) => (
                          <span>
                            <svg width={10} height={10}>
                              <rect width={10} height={10} style={{fill:color}}/>
                            </svg>
                            &nbsp;&nbsp;{id} : {value}%
                          </span>
                        )}
                        legends={[
                          {
                              anchor: 'left',
                              direction: 'column',
                              itemWidth: 100,
                              itemHeight: 18,
                              itemTextColor: '#333',
                              itemsSpacing: 2,
                              symbolSize: 18,
                              symbolShape: 'circle',
                              effects: [
                                  {
                                      on: 'hover',
                                      style: {
                                          itemTextColor: '#000'
                                      }
                                  }
                              ]
                          }
                        ]}
                      />
                      <div className="pie-overlay" style={{justifyContent:'start', top:'-4em', bottom:'auto'}}>
                        <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                          <div>Demande de devis</div>
                          <div className="big-value">{this.state.reportingMensuel.leadsBusinessTraites["Demande de devis"].Total}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{flex:0.3}}>
                    <div style={{height:'140px', marginRight:'3em', position:'relative'}}>
                      <ResponsivePie
                        data={this.state.reportingMensuel.leadsBusinessTraites["Prise de RDV"].Stats}
                        colors={ colors2 }
                        enableRadialLabels={false}
                        sliceLabel={function(e){return <tspan fontSize={12}>{e.value} %</tspan>}}
                        slicesLabelsSkipAngle={0}
                        slicesLabelsTextColor="#ffffff"
                        slicesLabelsSkipAngle={0.1}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                        tooltip={({ id, value, index, indexValue, color, data }) => (
                          <span>
                            <svg width={10} height={10}>
                              <rect width={10} height={10} style={{fill:color}}/>
                            </svg>
                            &nbsp;&nbsp;{id} : {value}%
                          </span>
                        )}
                        legends={[]}
                      />
                      <div className="pie-overlay" style={{justifyContent:'start', top:'-4em', bottom:'auto'}}>
                        <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                          <div>Prise de RDV</div>
                          <div className="big-value">{this.state.reportingMensuel.leadsBusinessTraites["Prise de RDV"].Total}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Fin Répartition des leads business  */}
              </div>
            </div>
          </div>
        </div>

        
        <div className='text-center'>
          {localStorage.IsNissan == "true" ?
            <a href="http://crm-agile.selectup.com/pdf/SMIT Lead_Nouveau Tableau de Bord_VFINALE_11062020.pdf" target="_blank">Télécharger la documentation</a>
          :
            <a href="http://crm-agile.selectup.com/pdf/Pr%C3%A9sentation%20du%20Dashboard%20eSeller%20lead%20Juin20.pdf" target="_blank">Télécharger la documentation</a>
          }
        </div>
        {/* Fin reporting mensuel */}
      </React.Fragment> 
    );
  }
}

export default DashboardAPV;
