import React, { Component } from 'react';
import { browserHistory } from 'react-router'

import PastilleVendeur from '../Components/PastilleVendeur';
import TableRowCampagneOrigine from '../Components/TableRowCampagneOrigine';
import TableRowSite from '../Components/TableRowSite';

import moment from 'moment'
import Dictionary from '../Dictionnary';

var lodash = require('lodash')

class AffairesGagnees extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');
  }

  handleclickAffaire(IDProspect) {
    browserHistory.push('/prospect/' + IDProspect);
  }

  getOffreCommandeFromAffaire(affaire) {
    var offres = lodash.filter(affaire.Offres, (offre) => {
      return (offre.Statut === 'Commande' || offre.Statut === 'CommandeValidee' || offre.Statut === 'Facture');
    });
    return lodash.head(offres);
  }

  render() {
    return (
      <div className="">
        <table className="table table-eseller-lead table-striped table-hover table-condensed">
          <thead>
            <tr>
              <th>Com.</th>
              <th>Site</th>
              <th>Numéro d'offre</th>
              <th>Date de l'offre</th>
              <th style={{ width: '200px', }}>Campagne / Origine</th>
              <th>Client</th>
              {localStorage.IsAgri === 'true' ? <th>Type</th> : null}
              <th>{Dictionary.getUCFirst("véhicule")}</th>
              <th>Reprise</th>
            </tr>
          </thead>
          <tbody>
            {this.props.leads.map((lead, i) =>
              <tr key={i} onClick={this.handleclickAffaire.bind(this, lead.Prospect.IDProspect)} className="tr-link">
                <td>{this.getOffreCommandeFromAffaire(lead) ? <PastilleVendeur utilisateur={this.getOffreCommandeFromAffaire(lead).SuiviPar} /> : null}</td>
                <td>
                  <TableRowSite Site={lead.Site} />
                </td>
                <td>{this.getOffreCommandeFromAffaire(lead) ? this.getOffreCommandeFromAffaire(lead).NumOffre : null}</td>
                <td>{this.getOffreCommandeFromAffaire(lead) ? moment(this.getOffreCommandeFromAffaire(lead).Date).format('L') : null}</td>
                <td>
                  <TableRowCampagneOrigine campagne={lead.Campagne} origine={lead.OrigineAction ? lead.OrigineAction : null} />
                </td>
                <td>
                  {lead.Prospect.Nom} {lead.Prospect.Prenom}
                </td>
                {localStorage.IsAgri === 'true' ?
                  <td>
                    {Dictionary.getUCFirst(lead.Type)}
                  </td>
                :null}
                <td>
                  {this.getOffreCommandeFromAffaire(lead) ?
                    this.getOffreCommandeFromAffaire(lead).Vehicules.map((vehicule, i) => {
                      return <div key={i}>{vehicule.Designation}</div>;
                    })
                  : null}
                </td>
                <td>
                  {this.getOffreCommandeFromAffaire(lead) ? (this.getOffreCommandeFromAffaire(lead).Reprises.length ? 'Oui' : 'Non' ): null}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default AffairesGagnees;
