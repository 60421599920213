import React, {Component} from 'react';

import moment from 'moment'

import { isMobile } from 'react-device-detect';
var Datetime = require('react-datetime');

class InputDate extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      
    }
  }

  render() {
    if(isMobile){
      return (
        <span>
          <input type="date" style={{minWidth:'150px'}} min={moment().format("YYYY-MM-DD")} onChange={(e) => this.props.onChange(moment(e.target.value))} value={this.props.value ? this.props.value.format("YYYY-MM-DD") : ''}/>
          <i className="icon-calendar icons" style={{ marginLeft:'5px', verticalAlign:'middle' }}></i>
        </span>
      )
    }
    else {
      return (
        <span>
          <Datetime className="datetime" locale="fr" closeOnSelect={true} timeFormat={false} isValidDate={this.props.isValidDate.bind(this)} open={false} onChange={(e) => this.props.onChange(e)} value={this.props.value}/>
          <i className="icon-calendar icons" style={{ marginLeft:'5px' }}></i>
        </span>
      )
    }
  }
}

export default InputDate;
