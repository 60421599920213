import React, { Component } from 'react';
import { browserHistory } from 'react-router'

import moment from 'moment'
import BadgeDiffDay from '../Components/BadgeDiffDay';
import PastilleInteret from '../Components/PastilleInteret';
import PastilleVendeur from '../Components/PastilleVendeur';
import TableRowCampagneOrigine from '../Components/TableRowCampagneOrigine';
import TableRowComment from '../Components/TableRowComment';
import TableRowSite from '../Components/TableRowSite';
import TableRowDateRelance from '../Components/TableRowDateRelance';
import Dictionary from '../Dictionnary';
import $ from 'jquery';

class AffairesEncours extends Component {

  constructor(props) {
    super(props);

    this.state = {
      displayMax: 500, //Nombre de leads max à afficher
    }

    moment.locale('fr');

    this.trackScrolling = this.trackScrolling.bind(this);
  }

  componentDidMount() {
    document.addEventListener('scroll', this.trackScrolling);
  }
  
  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }  

  componentDidUpdate(prevProps, prevState) {
    if (this.props.typePeriode !== prevProps.typePeriode) {
      this.setState({
        displayMax: 500,
      })
      document.removeEventListener('scroll', this.trackScrolling);
      document.addEventListener('scroll', this.trackScrolling);
    }
  }

  trackScrolling() {
    const wrappedElement = document.getElementById('renderAffairesEnCours');
    if (wrappedElement.getBoundingClientRect().bottom <= window.innerHeight) {
      console.log('bottom reached');
      this.setState({
        displayMax: this.state.displayMax + 500
      })
      $("#renderingMore").css("display", "flex");
    }
  };

  handleclickAffaire(IDProspect) {
    browserHistory.push('/prospect/' + IDProspect);
  }
  
  getLeads(){
    var leads = [];
    for(var i in this.props.leads)
    {
      let lead = this.props.leads[i];
      if (this.props.filterName) {
        if (lead.Prospect.Nom.concat(' ' + lead.Prospect.Prenom).toLowerCase().search(this.props.filterName.toLowerCase()) === -1) {
          continue;
        }
      }

      leads.push(lead);
      if(leads.length == this.state.displayMax)
        break;
    }

    if(leads.length < this.state.displayMax){
      document.removeEventListener('scroll', this.trackScrolling);
    }

    console.log("render aec display max "+this.state.displayMax)
    return leads;
  }

  render() {
    const leads = this.getLeads();

    return (
      <div className="" id="renderAffairesEnCours">
        <table className="table table-eseller-lead table-striped table-hover table-condensed">
          <thead>
            <tr>
              <th className='text-center'>{this.props.type === 'AffairesEncoursBDC' ? 'Cellule' : 'Com.'}</th>
              <th>Date relance</th>
              <th style={{ width: '150px', }}>Site</th>
              <th style={{ width: '200px', }}>Campagne / Origine</th>
              <th>Contact</th>
              {localStorage.IsAgri === 'true' ? 
                [
                  <th key="type">Type</th>,
                  <th key="materiel">Matériel</th>
                ]
              : null}
              <th>Intérêt - Prochaine étape</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {leads.map((lead, i) => {

              return (<tr key={i} onClick={this.handleclickAffaire.bind(this, lead.Prospect.IDProspect)} className="tr-link">
                <td className='text-center' style={{backgroundColor: lead.Affaire.IDAffaire == 5320 ? 'red' : ''}}>
                  <PastilleVendeur utilisateur={lead.SuiviPar} />
                </td>
                <td>
                  {lead.ActionAFaire_TODO.DateHProchaineEtape ?
                    <TableRowDateRelance date={lead.ActionAFaire_TODO.DateHProchaineEtape} />
                  :
                    <TableRowDateRelance date={lead.InfoCreation.DateH} />
                  }
                </td>
                <td style={{ overflow: 'hidden' }}>
                  <TableRowSite Site={lead.Site} />
                </td>
                <td>
                  <TableRowCampagneOrigine campagne={lead.Campagne} origine={lead.ActionFaite_DO.OrigineAction} />
                </td>
                <td>
                  {lead.Prospect.Nom} {lead.Prospect.Prenom}
                </td>
                {localStorage.IsAgri === 'true' ? 
                [
                  <td key="type">
                    {Dictionary.getUCFirst(lead.Affaire.Type)}
                  </td>,
                  <td key="materiel">
                    {lead.Affaire.Designation}
                  </td>
                ]
                :null}
                <td>
                  <PastilleInteret niveau={lead.ActionAFaire_TODO.NiveauInteret.Niveau} /> &nbsp;&nbsp;
                  {lead.ActionAFaire_TODO.Etape ? lead.ActionAFaire_TODO.Etape.Description : null}
                </td>
                <td>
                  {lead.ActionAFaire_TODO.DateHProchaineEtape ?
                    <BadgeDiffDay date={moment(lead.ActionAFaire_TODO.DateHProchaineEtape).format('HHmm') === '0000' ? moment(lead.ActionAFaire_TODO.DateHProchaineEtape).format('YYYY-MM-DDT08:00:00') : lead.ActionAFaire_TODO.DateHProchaineEtape} />
                  :
                    <BadgeDiffDay date={lead.InfoCreation.DateH} />
                  }
                </td>
                <td className='text-center'>
                  <TableRowComment comment={lead.ActionFaite_DO.Commentaire} />
                </td>
              </tr>);
            })}
          </tbody>
        </table>
        {leads.length == this.state.displayMax ?
          <div id="renderingMore" style={{flex:1, justifyContent:'center', alignItems:'center', display:'flex'}}> 
            <i className="fa fa-refresh fa-spin"/>
          </div>
        :null}
      </div>
    );
  }
}

export default AffairesEncours;
