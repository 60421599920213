import React, { Component } from 'react';

import { Link } from 'react-router';

import { browserHistory } from 'react-router';

import Globals from '../Globals';

import FormOrigine from './FormOrigine.js';

import TableRowSite from '../Components/TableRowSite.js';

import FormAppelSortant from './FormAppelSortant.js';

import FormVO from './FormVO.js';
import FormVN from './FormVN.js';
import FormAtelier from './FormAtelier.js';
import FormAutres from './FormAutres.js';

import FormSocieteSiteVN from './FormSocieteSiteVN.js';
import FormSocieteSiteAppelSortant from './FormSocieteSiteAppelSortant.js';
import FormSocieteSiteVO from './FormSocieteSiteVO.js';
import FormSocieteSiteAtelier from './FormSocieteSiteAtelier.js';

import FormToDoCreate from './FormToDoCreate.js';
import FormToDoCreateAtelierAutres from './FormToDoCreateAtelierAutres.js';
import FormRelance from './FormRelance.js';
import Scheduler from '../Pages/Scheduler.js';

import VehiculeHistoriqueAtelier from '../Components/VehiculeHistoriqueAtelier';

import VehiculeParcForm from '../Pages/VehiculeParcForm.js';

import Loader from '../Components/Loader';

import moment from 'moment';

import ProspectFormEdit from '../Components/Prospect/FormEdit';
import ProspectFormCreate from '../Components/Prospect/Form';

import SkyLight from 'react-skylight';

import SweetAlert from 'react-bootstrap-sweetalert'; // http://djorg83.github.io/react-bootstrap-sweetalert/
import Tooltip from "rc-tooltip";
import Dictionary from '../Dictionnary';
var lodash = require('lodash')
var Auth = require('../Auth').default;

class AppelSortantIframe extends Component {

    constructor(props) {

        super(props);

        moment.locale('fr');

    this.state = {

      rapport: null,

      type: '',
      canal: 'Téléphone',
      marque: '',
      societe: 0,
      site: 0,
      campagne: 0,
      origine: 0,
      origine_famille: 0,
      origine_sousfamille: 0,
      choiceOrigine: 0,

      commentVN: '',
      commentVO: '',
      commentAtelier: '',
      commentAutres: '',

      todoEtape: 0,
      todoEtapeText: '',
      todoDate: moment(),
      todoHeure: null,
      todoToVendeur: false,
      todoVendeur: 0,
      todoComment: '',

      relance: 0,
      relanceAction: '',
      relanceCommentaire: '',
      relanceDate: moment(),
      todoDateHeureFin: null,
      relanceHeure: null,

      prospectId: null,
      prospect: null,
      vehicules: [],
      selectedVehicule: null,
      VehiculeHistoriqueAtelier: null,

      errors: {},
      errorsMessages: [],

      fetchCreateInProgress: false,
      successCreate: false,
      errorCreate: false,
      errorCreateMessage: '',
      successCreateMessage: 'Création du lead avec succès !',

      sJwt: null,

      utilisateurs: null,
      historiquesAteliers: [],

      origines: [],
      EstAppelSortant: true,
      appelSortantCreateLead: false,
      appelSortantClotureForm: false,
      typeAppelSortant: '',
      disabledEditOrigine: false,

      codeRaisonClotureAppelSortant: null,
      appelSortantComment: '',

      UrlMecaplanning : '',
      lockProspectMecaplanning : true,

    };

    /**** Si on passe par l'iframe ****/
    if (this.props.location.query.idclient) {
      if(localStorage.bypass_iFrame || (!localStorage.user_IDClient || !localStorage.user_login || !localStorage.user_password || !localStorage.user_profil)){
        localStorage.bypass_iFrame = true;
        localStorage.user_IDClient = this.props.location.query.idclient;
        localStorage.user_login = this.props.location.query.login;
        localStorage.user_password = this.props.location.query.password;
        localStorage.access_affectationUtilisateur = true;
        localStorage.access_creationRelance = false;
        localStorage.user_IDUtilisateur = this.props.location.query.idutilisateur ? this.props.location.query.idutilisateur : -1;
        localStorage.token = 'iframe'
        localStorage.version = Globals.VERSION

        localStorage.ClientApiURL = Globals.API_URL;
        var parts = window.location.hostname.split('.');
        if (parts[0] === 'dev' || parts[1] === 'dev' || parts[0] === 'preprod') {
          localStorage.ClientApiURL = Globals.API_URL_DEV;
        }
        if (parts[0] === 'devagri' || parts[1] === 'devagri') {
          localStorage.ClientApiURL = Globals.API_URL_DEV_AGRI;
        }
        if (parts[0] === 'devv5' || parts[1] === 'devv5') {
          localStorage.ClientApiURL = Globals.API_URL_DEV_V5;
        }
      }
    }
  }

  componentDidMount() {
    this.fetchRapport(this.props.params.IDRapport);
  }

  fetchRapport(lead_id) {
    fetch(localStorage.ClientApiURL + '/leads/' + lead_id, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        rapport: json,
        societe: json.Site.Plaque.IDPlaque,
        site: json.Site.IDSite,
        type: json.Campagne.OrigineAction.Type,
      }, () =>{
        this.fetchProspect(this.state.rapport.Prospect.IDProspect);
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchMecaplanningToken() {

    if(!this.state.prospect){
      return;
    }

    if(!this.state.prospect){
      var pr = this.state.prospect.Prive.replace(/\./g,'').replace(/\ /g, '');
      if(this.state.prospect.Nom && this.state.prospect.Prenom && pr.length === 10){
        this.setState({ lockProspectMecaplanning : false });
      }
      else{
        this.setState({ lockProspectMecaplanning : true });
        return;
      }
    }

    if (!this.state.site) {
      return;
    }

    let body = JSON.stringify({
      "IDSite": this.state.site
    });

    fetch(localStorage.ClientApiURL + '/WidgetTokens', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: body
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        sJwt: json.sJWT,
      }, () =>{
        this.getUrlMecaplanning();
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

fetchProspect(prospectId) {
    fetch(localStorage.ClientApiURL + '/Prospects/' + prospectId, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
        },
    })
    .then((response) => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.json();
    })
    .then(json => {
      this.setState({
        prospect: json,
        prospectId: json.IDProspect,
        lockProspectMecaplanning : false,
      }, () => {
        if (this.state.prospect.LiensVehicules) {
          if (this.state.prospect.LiensVehicules.length === 1) {
            this.setState({
              selectedVehicule: this.state.prospect.LiensVehicules[0].IDVehicule
            })
          }
          this.state.prospect.LiensVehicules.map((v) => {
            this.fetchVehicule(v.IDVehicule);
          })
        }
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }


fetchHistoriqueAteliers() {
    fetch(localStorage.ClientApiURL + '/Prospects/' + this.state.prospectId + '/historiquesateliers', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
        },
    })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {

            var historiquesAteliers = json.sort(function(a, b) {
                if (moment(a.Date) > moment(b.Date))
                    return -1;
                return 1;
            });

            this.setState({
                historiquesAteliers: historiquesAteliers,
            }, () => {
                //console.log(this.state)
            });
        })
        .catch((error) => {
            console.log(error)
        });
}

fetchVehicule(IDVehicule) {
    fetch(localStorage.ClientApiURL + '/Vehicules/' + IDVehicule, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
        },
    })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            var vehicules = this.state.vehicules;
            vehicules.push(json);

            if (json.Immatriculation === this.props.location.query.immat || json.VIN === this.props.location.query.VIN) {
                this.setState({
                    selectedVehicule: json.IDVehicule
                })
            }

            this.setState({
                vehicules: vehicules,
            });
        })
        .catch((error) => {
            console.log(error)
        });
}

fetchAffaires() {
    fetch(localStorage.ClientApiURL + '/Prospects/' + this.state.prospectId + '/affaires', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
        },
    })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {

            var affaires = lodash.filter(json, (affaire) => {
                affaire.fetchLeadDone = false;
                return ((affaire.Statut === 'AValider') || (affaire.Statut === 'EnCours')) && affaire.NombreDeRapport >= 1;
            });
            this.setState({
                affairesProspect: affaires,
            });
            this.checkAvailableTypes();
            this.fetchLeadsIds();
        })
        .catch((error) => {
            console.log(error)
        });
}

  fetchLeadsIds(){
    var tmp_ = this.state.typesCreate

    if (!this.state.affairesProspect) {
        return;
    }

    this.state.affairesProspect.map((affaire, i) =>{
      return fetch(localStorage.ClientApiURL + '/affaires/' + affaire.IDAffaire + '/leads', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(json => {
          if(json.length){
            if(tmp_["leads"][i]){
              tmp_["leads"][i].IDLead = json[0].IDLead;
              tmp_["leads"][i].fetchLeadDone = true;
              this.setState({
                typesCreate :  tmp_,
              })
            }
          }
      })
      .catch((error) => {
        console.log(error)
      });
    })

}

// TODO : A RECODER
// Afficher une information Ã  l'utilisateur que le prospect dispose dÃ©jÃ  d'une affaire de ce type
checkAvailableTypes(){
    if (!this.state.affairesProspect)
        return;

    var tmp_ = this.state.typesCreate;
    this.state.affairesProspect.map((affaire, i) => {
      if(affaire && affaire.OrigineAction && affaire.Type){
        //affaire.IDLead = this.fetchLeadId(affaire.IDAffaire);
        tmp_["leads"].push(affaire);
        //tmp_["leads"][affaire.Type][tmp_["leads"][affaire.Type].length-1].IDLead= this.fetchLeadId(affaire.IDAffaire);
        tmp_[affaire.Type] = false;
        tmp_["warning"] = true;
      }
      return null;
    });
    var mess="";
    if(!tmp_["VN"])
        mess+="VN";
    if(!tmp_["VO"])
    {
        if(!tmp_["VN"])
            mess+=",";
        mess+="VO";
    }
    if(!tmp_["Atelier"])
    {
        if(!tmp_["VN"] || !tmp_["VO"])
            mess+=",";
        mess+="Atelier";
    }
    tmp_["message"] = mess;

    this.setState({
        typesCreate :  tmp_,
    })
}

isValidForm() {
    let fields = [];

    if (!this.state.canal) {
        fields.push('Canal');
    }

    if (!parseInt(this.state.societe, 10)) {
        fields.push('SociÃ©tÃ©');
    }

    if (!parseInt(this.state.site, 10)) {
        fields.push('Site');
    }

    if (!this.state.prospect) {
        fields.push('Informations prospect');
    }

    if (localStorage.bypass_iFrame === 'true') {
        if (!this.state.prospect.Civilite) {
            fields.push('Civilite du prospect');
        }
        if (!this.state.prospect.Nom) {
            fields.push('Nom du prospect');
        }
        if (this.state.prospect.TypeClient === 'Particulier' && !this.state.prospect.Prenom) {
            fields.push('Prénom du prospect');
        }
        if (this.state.prospectId) {
            if (!this.state.prospect.Mail && !this.state.prospect.Telephones) {
                fields.push('Mail ou Téléphone du prospect');
            }
        }
        else {
            if (!this.state.prospect.Mail && !this.state.prospect.Mobile  && !this.state.prospect.Prive) {
                fields.push('Mail ou Téléphone du prospect');
            }
        }
    }

    if ((this.type === 'VO' || this.type === 'VN') && this.state.todoToVendeur && !parseInt(this.state.todoVendeur, 10)) {
        fields.push('Vendeur');
    }

    if ((this.type === 'VO' || this.type === 'VN') && this.state.todoToVendeur && !parseInt(this.state.todoEtape, 10)) {
        fields.push('Etape');
    }

    let fields_string = '';
    fields.map((field, i) => {
        fields_string += "\n- " + field;
        return field;
    });

    if (fields.length > 0)
        alert('Des champs obligatoires ne sont pas renseignés :' + fields_string);

    return (fields.length > 0) ? false : true;
}

handleSubmit(event) {
    event.preventDefault();
    //divergences pour l'appel sortant
    if(this.state.EstAppelSortant && !this.state.appelSortantCreateLead){
      //cloture
       this.appelSortantCloture();
      return;
    }

    if (!this.isValidForm()) {
        return false;
    }

    this.setState({
        fetchCreateInProgress: true,
    });

    if (this.state.prospectId) {
        this.refs.ProspectFormEdit.save();
        this.createLead();
        return;
    }

    let body = this.state.prospect;
    body.InfoCreation = {};
    body.InfoCreation.Site = {};
    body.InfoCreation.Site.IDSite = this.state.site;
    body.Origine = 'eSeller Lead';
    body.Telephones = [];
    if (this.state.prospect.Mobile) {
        body.Telephones.push({
            "Type": 3,
            "Numero": this.state.prospect.Mobile
        })
    }
    if (this.state.prospect.Prive) {
        body.Telephones.push({
            "Type": 1,
            "Numero": this.state.prospect.Prive
        })
    }
    if (this.state.prospect.Fax) {
        body.Telephones.push({
            "Type": 2,
            "Numero": this.state.prospect.Fax
        })
    }
    if (this.state.prospect.Professionnel) {
        body.Telephones.push({
            "Type": 0,
            "Numero": this.state.prospect.Professionnel
        })
    }

    fetch(localStorage.ClientApiURL + '/Prospects', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
        },
        body: JSON.stringify(body),
    })
        .then((response) => {
            if (!response.ok) {
                response.json().then((value) => {
                    this.setState({
                        fetchCreateInProgress: false,
                        errorCreate: true,
                        errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                    });
                });

                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            this.setState({
                prospectId: json,
            }, () => {
                this.createLead();
            });
        })
        .catch((error) => {
            console.log(error)
            this.setState({ fetchInProgress: false })
        });

}

createLead() {
    if ((!this.state.EstAppelSortant || (this.state.EstAppelSortant && this.state.appelSortantCreateLead)) && (this.state.type === 'Atelier' || this.state.type === 'Autres') && localStorage.eSellerLeadAtelierMailRapport === 'true') {
      this.createRapportAtelierSendEmail();
      return;
    }

    if(this.state.EstAppelSortant && this.state.appelSortantCreateLead){
      this.validAppelSortantLead();
    }

    let affaireComment = '';

    if (this.state.type === 'VN') {
        affaireComment = this.state.commentVN;
    }
    else if (this.state.type === 'VO') {
        affaireComment = this.state.commentVO;
    }
    else if (this.state.type === 'Atelier') {
        affaireComment = this.state.commentAtelier;
    }
    else if (this.state.type === 'Autres') {
        affaireComment = this.state.commentAutres;
    }

    let body = {
        "Prospect": {
            "IDProspect": this.state.prospectId,
        },
        "Campagne" : (this.state.rapport.Campagne ? { "IDCampagne":  this.state.rapport.Campagne.IDCampagne, } : null),
        "OrigineAction": {
            "IDOrigineAction": this.state.rapport.Campagne.OrigineAction ? this.state.rapport.Campagne.OrigineAction.IDOrigineAction : null,
            "EstLead": true
        },
        "Statut": 3,
        Description: affaireComment,
        "CanalOrigine": 'Téléphone',
    };

    if (this.state.type === 'Atelier' && this.state.selectedVehicule) {
        body.Vehicule = {
            IDVehicule: this.state.selectedVehicule
        };
    }
    body.Type = this.state.type;
    body = JSON.stringify(body);
    console.log(body)

    fetch(localStorage.ClientApiURL + '/Affaires/', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
        },
        body: body,
    })
        .then((response) => {
            if (!response.ok) {
                response.json().then((value) => {
                    this.setState({
                        fetchCreateInProgress: false,
                        errorCreate: true,
                        errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                    });
                });

                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            let id_affaire = json;
            let date_relance = moment(this.state.todoDate).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
            if (this.state.todoToVendeur && this.state.todoHeure) {
                date_relance.set('hour', this.state.todoHeure.get('hour')).set('minute', this.state.todoHeure.get('minute'));
            }

            var todoToVendeur = this.state.todoToVendeur;

            if (this.props.location.query.origin === 'CallWin' && this.state.CallcenterTypeCreateLead === '' && this.state.ToVendeurCallwinOK) {
              date_relance = moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
              todoToVendeur = true;
            }

            var todoVendeur = this.state.todoVendeur;
            if (todoVendeur instanceof Array) {
              todoVendeur = this.state.todoVendeur[0];
            }

            let body = {
                "IDLead": null,
                "Affaire": {
                    "IDAffaire": id_affaire,
                },
                "Campagne" : (this.state.rapport.Campagne ? { "IDCampagne":  this.state.rapport.Campagne.IDCampagne, } : null),

                "Site": {
                    "IDSite": this.state.site,
                },
                "Prospect": {
                    "IDProspect": this.state.prospectId,
                },
                "SuiviPar": {
                    "IDUtilisateur": todoVendeur <= 0 ? 0 : todoVendeur, // Soit il est affecté au vendeur, soit il est dans le pot commun pour être redistribuÃ©
                },
                "ARedistribuer": !todoToVendeur,
                "PremiereActionEntrante": true,
                "ActionFaite_DO": {
                    "OrigineAction": {
                        "IDOrigineAction": this.state.rapport.Campagne.OrigineAction ? this.state.rapport.Campagne.OrigineAction.IDOrigineAction : null,
                        "EstLead": true
                    },
                    "CanalOrigine": 'Téléphone',
                    "TypeContact": {
                        "Description": "eSeller Lead"
                    },
                    Commentaire: this.state.todoComment,
                },
                "ActionAFaire_TODO": {
                    "Etape": {
                        "IDEtape": todoToVendeur && this.state.todoEtape ? this.state.todoEtape : 1, // Si pas de vendeur, on met "A qualifier"
                    },
                    "ActionAFaire": {
                        "Description": this.state.todoEtapeText ? this.state.todoEtapeText : "Téléphoner",
                    },
                    "NatureActionAFaire": "Téléphone",
                    "NiveauInteret": {
                        "IDNiveauInteret": 6,
                    },
                    "EstDateLimite": todoToVendeur && this.state.todoHeure ? true : false,
                    "DateHRelance": date_relance.format(),
                    "DateHFinRelance": todoToVendeur && this.state.todoDateHeureFin ? this.state.todoDateHeureFin.format() : null,
                    "DateHProchaineEtape": date_relance.format(),
                    "Etat": "EnCours",
                    "Cloturer": null
                }
            };

            if (this.state.EstAppelSortant && this.props.location.query.IDLead) {
                body['IDLeadsATerminer'] = [this.props.location.query.IDLead];
            }

            body = JSON.stringify(body);
            console.log(body)

            fetch(localStorage.ClientApiURL + '/Leads/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'APIKey': Globals.API_KEY,
                    'IDClient': localStorage.user_IDClient,
                    'Login': localStorage.user_login,
                    'Password': localStorage.user_password
                },
                body: body,
            })
                .then((response) => {

                    if (!response.ok) {
                        response.json().then((value) => {
                            this.setState({
                                fetchCreateInProgress: false,
                                errorCreate: true,
                                errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                            });
                        });

                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then(json => {

                  if (this.state.todoVendeur instanceof Array) {
                    this.state.todoVendeur.map((u) => {
                      fetch(localStorage.ClientApiURL + '/leads/atelier/sendMail?id_prospect='+this.state.prospectId+'&id_destinataire='+u+'&id_rapport='+json, {
                        method: 'POST',
                        headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                          'APIKey': Globals.API_KEY,
                          'IDClient': localStorage.user_IDClient,
                          'Login': localStorage.user_login,
                          'Password': localStorage.user_password
                        },
                        body: JSON.stringify({mailContent: affaireComment, IDVehicule: this.state.selectedVehicule}),
                      })
                      .then((response) => {
                        if (!response.ok) {
                          response.json().then((value) => {
                            this.setState({
                              fetchCreateInProgress: false,
                              errorCreate: true,
                              errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                            });
                          });

                          throw Error(response.statusText);
                        }
                        this.setState({
                          fetchCreateInProgress: false,
                          successCreate: true,
                          errorCreate: false,
                          errorCreateMessage: '',
                        });
                      })
                      .catch((error) => {
                        console.warn(error);
                      });
                    });
                  }
                  else {
                    this.setState({
                        fetchCreateInProgress: false,
                        successCreate: true,
                        errorCreate: false,
                        errorCreateMessage: '',
                    });
                  }

                })
                .catch((error) => {
                    console.warn(error);
                    this.setState({
                        fetchCreateInProgress: false,
                        errorCreate: true,
                        errorCreateMessage: error,
                    });
                });

        })
        .catch((error) => {
            console.warn(error);
            this.setState({
                fetchCreateInProgress: false,
                errorCreate: true,
                errorCreateMessage: error,
            });
        });
}


  createRapportAtelierSendEmail() {

    let affaireComment = '';

    if (this.state.type === 'Atelier') {
      if(this.refs.FormAtelierRef.state.type === "MECAPLANNING"){
        affaireComment += "Type de demande : MECAPLANNING"+ '\n';
        if(this.refs.FormAtelierRef.state.mecaplanningrdv){
          affaireComment += "Rendez-vous pris : Oui"+ '\n';
          }
          else{
          affaireComment += "Rendez-vous pris : Non"+ '\n';
          }
      }
      else{
        affaireComment = this.state.commentAtelier;
      }
    }
    else if (this.state.type === 'Autres') {
      affaireComment = this.state.commentAutres;
    }

    // Créer rapport prospect
    let body = {
      IDLead: null,
      "Campagne" : (this.state.campagne ? { "IDCampagne":  this.state.campagne, } : null),
      Site: {
        IDSite: this.state.site,
      },
      Prospect: {
        IDProspect: this.state.prospectId,
      },
      "SuiviPar": {
        "IDUtilisateur": localStorage.user_IDUtilisateur,
      },
      ActionFaite_DO: {
        "OrigineAction": {
          "IDOrigineAction": this.state.choiceOrigine ? this.state.choiceOrigine : null,
        },
        "DateHLead": moment().format(),
        "TypeContact": {
          "Description": "Atelier",
        },
        "CanalOrigine": this.state.canal,
        Commentaire: affaireComment,
      },
      "ActionAFaire_TODO": {
        "Etape": null,
        "ActionAFaire": null,
        "NatureActionAFaire": "Téléphone",
        "NiveauInteret": {
          "IDNiveauInteret": 1,
        },
        "EstDateLimite": false,
        "DateHRelance": null,
        "DateHFinRelance": null,
        "DateHProchaineEtape": null,
        "Etat": "Termine",
      },
      "EstAtelier": true,
    };

    body = JSON.stringify(body);

    console.log(body)

    fetch(localStorage.ClientApiURL + '/Leads/creer', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: body,
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          this.setState({
            fetchCreateInProgress: false,
            errorCreate: true,
            errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
          });
        });

        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      // Envoyer email aux différents conseillers
      this.state.todoVendeur.map( (vendeur, i) => {
            fetch(localStorage.ClientApiURL + '/leads/atelier/sendMail?id_prospect='+this.state.prospectId+'&id_destinataire='+vendeur+'&id_rapport='+json, {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
              },
              body: JSON.stringify({mailContent: affaireComment, IDVehicule: this.state.selectedVehicule}),
            })
            .then((response) => {
              if (!response.ok) {
                response.json().then((value) => {
                  this.setState({
                    fetchCreateInProgress: false,
                    errorCreate: true,
                    errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                  });
                });

                throw Error(response.statusText);
              }
              this.setState({
                fetchCreateInProgress: false,
                successCreate: true,
                errorCreate: false,
                errorCreateMessage: '',
              });
            })
            .catch((error) => {
              console.warn(error);
            });
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  }


handleFieldChanged(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.validateField(name, value);
}

changeDateRelance(event) {
    this.setState({
        todoDate: event.target.value,
    })
}

getUrlMecaplanning() {
    var url = "/widget_mecaplanning.html?";

    if (this.state.prospect) {
        if (this.state.prospect.Nom)
            url += "sNom=" + this.state.prospect.Nom + "&";

        if (this.state.prospect.Prenom)
            url += "sPrenom=" + this.state.prospect.Prenom + "&";

      if (this.state.prospect.Telephones && this.state.prospect.Telephones.length){
        if(this.state.prospect.Telephones[0].Numero){
          var pr = this.state.prospect.Telephones[0].Numero.replace(/\./g,'').replace(/\ /g, '');
          url += "sTel=" + this.state.prospect.Telephones[0].Numero + "&";
        }
      }

      if(!this.state.prospectId){
        if(this.state.prospect.Prive){
          var pr = this.state.prospect.Prive.replace(/\./g,'').replace(/\ /g, '');
          if (pr && pr.length){
            url += "sTel=" + pr + "&";
          }
        }
      }
    }
    url += "sJwt=" + this.state.sJwt + "&";
    this.setState({
      UrlMecaplanning : url,
    });
  }

fetchUtilisateurs() {
    fetch(localStorage.ClientApiURL + '/utilisateurs?EstActif='+true+'&LoadDetailDroitProfil='+false+'LoadPlaqueMarques='+false, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
        },
    })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
          console.log(json)
            var users = lodash.filter(json, (u) => {

              if (!u.TypeProfilLead || u.TypeProfilLead !== 'Vendeur') {
                return false;
              }

              var exist = false;
              exist = lodash.filter(u.Sites, (s) => {
                  return parseInt(s.IDSite, 10) === parseInt(this.state.site, 10);
              })
              return exist.length > 0;
            });

            this.setState({
                utilisateurs: users,
                todoVendeur: 0,
            });
        })
        .catch((error) => {
            console.log(error)
        });
}

changeType(value) {
  this.setState({
    type: value,
  }, () => {

  })
}

// Est appelÃ© lors du changement de valeur dans les composants enfants
changeValue(attr, value) {
    this.setState({
        [attr]: value
    }, () => {
      if (attr === 'site') {
        this.fetchMecaplanningToken();
      }
      if(attr === 'lockProspectMecaplanning'){
        this.fetchMecaplanningToken();
      }
    });

    //si on change le type du formulaire, on reset le site sélectionné
    if(attr === "type" && localStorage.bypass_iFrame !== "true" && !this.state.EstAppelSortant){
        if(!this.props.location.query.called){
          this.setState({
              societe : 0,
              site : 0,
          });
        }
        this.refs.formsocietesite.generateAll();
    }

    if (attr === 'type' && this.state.prospectId){
      if(value === 'Atelier') {
          this.fetchHistoriqueAteliers();
      }
    }
}

propageSuccess() {
    if (localStorage.bypass_iFrame === "true") {
        window.parent.window.postMessage(this.state.prospectId, this.props.location.query.origin)
    }
    else {
        browserHistory.push('/prospect/' + this.state.prospectId);
    }
}

hideAlert() {
    this.setState({
        errorCreate: false,
        errorCreateMessage: '',
    });
}

changeProspect(prospect) {
    this.setState({
      prospect: prospect,
    }, () => {
      this.fetchMecaplanningToken()
    });
  }

openModelWeekCalendar() {
    if (this.refs.modalWeekCalendar) {
        this.refs.modalWeekCalendar.show();
    }
}

onSelectCalendar(start, end, resource) {
    this.refs.modalWeekCalendar.hide();
    this.setState({
        todoDate: moment(start).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0),
        todoHeure: start,
        todoDateHeureFin: end,
        todoVendeur: resource ? resource : 0,
    })
}

disabledButtonValid(event) {


    if (this.state.fetchCreateInProgress) {
        return true;
    }

    /*
    if (!this.state.canal) {
      return true;
    }

    if (!parseInt(this.state.societe, 10)) {
      return true;
    }

    if (!parseInt(this.state.site, 10)) {
      return true;
    }

    if (!parseInt(this.state.origine, 10)) {
      return true;
    }

    if (!parseInt(this.state.choiceOrigine, 10)) {
      return true;
    }

    if (!this.state.prospect) {
      return true;
    }

    if (this.state.todoToVendeur && (!parseInt(this.state.todoEtape, 10) || !parseInt(this.state.todoVendeur, 10))) {
      return true;
    }
    */

    if(this.state.EstAppelSortant && !this.state.appelSortantCreateLead && this.state.appelSortantClotureForm){
      return true;
    }

    if(this.state.EstAppelSortant && this.state.appelSortantCreateLead && !this.state.typeAppelSortant){
      return true;
    }

    if( (this.state.type === 'Autres' || this.state.type === 'Atelier') && this.state.todoVendeur.length === 0){
      return true;
    }

    // CallWin
    if (this.props.location.query.origin === 'CallWin') {
      if (!this.state.ToVendeurCallwinOK && !this.state.ToVendeurCallwinNOK) {
        return true;
      }
      if (this.state.ToVendeurCallwinOK && !this.state.ToVendeurCallwinNOK && (!this.state.todoVendeur || this.state.todoVendeur <= 0) && !this.state.ToVendeurCallwinOKPotcommun) {
        return true;
      }
    }

    return false;
  }

  getAllUsers() {
    var utilisateurs = lodash.filter(this.state.utilisateurs, (utilisateur) => {

        if (utilisateur.EstPasserelle)
            return false;

        if(this.state.type === "VN")
            return utilisateur.EstTypeVN;
        else if(this.state.type === "VO")
            return utilisateur.EstTypeVO;
    });
    return utilisateurs;
}

changeVendeurCallWin(event) {
    this.setState({
        todoVendeur: event.target.value,
        todoEtape: 1, // étape "Ã  qualifier"
    });
}

changeVehicule(IDVehicule) {
    if (this.state.selectedVehicule === IDVehicule) {
        this.setState({
            selectedVehicule: null,
        })
    }
    else {
        this.setState({
            selectedVehicule: IDVehicule
        })
    }
}

isCheckedVehicule(IDVehicule) {
    return (this.state.selectedVehicule && (IDVehicule === this.state.selectedVehicule));
}

getDefaultPhoneProspect() {
    if ((this.props.location.query.phone !== undefined && this.props.location.query.phone !== '') || (this.props.location.query.calling !== '')) {
        if (this.props.location.query.phone !== undefined && this.props.location.query.phone !== '')
            return this.props.location.query.phone
        if (this.props.location.query.calling !== '')
            return this.props.location.query.calling
    }

    return '';
}

// créer un rapport fermé sur la fiche client (relance)
  appelSortantCloture() {
    if (!this.isValidForm()) {
      return false;
    }

    this.setState({
      fetchCreateInProgress: true,
    });

    if(!this.state.appelSortantCloture){
      if (this.state.prospectId) {
        this.refs.ProspectFormEdit.save();
      }
    }

    var raisons = {
      61: 'Numéro occupé',
      62: 'Faux numéro',
      63: 'Toujours injoignibale',
      15: 'Achat récent',
      50: 'Contactera la concession',
      30: 'Mécontent',
      29: 'A quitté la région',
      71: 'Refus de répondre',
      72: 'Décédé',
      70: 'Stop prospection',
    };

    var body = this.state.rapport;
    body.ActionAFaire_TODO.Etat = 'Termine';
    if (this.state.codeRaisonClotureAppelSortant) {
      body.ActionFaite_DO.Commentaire = "Raison clôture : " + raisons[this.state.codeRaisonClotureAppelSortant] + "\n" + this.state.appelSortantComment;
    }
    //AppelSortantCodeRaison: this.state.codeRaisonClotureAppelSortant, //Ne faisait rien ? Maintenant provoque une erreur lors du build
    body.Historiques = null;

    body = JSON.stringify(body);

    console.log(body)

    fetch(localStorage.ClientApiURL + '/Leads/' + this.state.rapport.IDLead, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: body,
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          this.setState({
            fetchCreateInProgress: false,
            errorCreate: true,
            errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
          });
        });

        throw Error(response.statusText);
      }

      if(this.state.EstAppelSortant && !this.state.appelSortantCreateLead){
        this.setState({
          fetchCreateInProgress: false,
          successCreate: true,
          errorCreate: false,
          errorCreateMessage: '',
          successCreateMessage: 'Clôture du rapport avec succès !',
        });
      }

      return response;
    })
    .catch((error) => {
      console.warn(error);
    });
  }

  // créer un rapport ouvert sur la fiche client (relance action à faire)
  appelSortantRelance() {

    this.setState({
        appelSortantRelanceForm: true,
    })

}

  cancelAppelSortantRelance(){
    this.setState({
      appelSortantRelanceForm : false,
    });
  }

  //créer un rapport fermé - appel sortant créer lead
  validAppelSortantLead(){
    this.appelSortantCloture();
  }


appelSortantCreateLead() {
    // affiche le formualaire de crÃ©ation d'un lead
    this.setState({
      appelSortantCreateLead: true,
      disabledEditOrigine: true,
    });

    if(this.state.type && this.state.typeAppelSortant === '')
    {
      this.setState({  'typeAppelSortant' : this.state.type });
    }
  }

finishCreateVehicule(IDVehicule) {
    this.refs.modalVehiculeParcClientCreate.hide();
    this.fetchVehicule(IDVehicule);
    this.setState({
        selectedVehicule: IDVehicule
    })
}

finishEditVehicule(IDVehic){
    this.refs.modalVehiculeEdit.hide();
    this.setState({ vehicules: [] });
    this.state.prospect.LiensVehicules.map((v) => {
        this.fetchVehicule(v.IDVehicule);
    });
}

callEditVehicule(id){
  var vehicule = lodash.find(this.state.vehicules, {IDVehicule: id});
  this.setState({
      selected_vehicule: vehicule,
  });
  this.refs.modalVehiculeEdit.show();
}

handleClickType(event){
    this.setState({
      'typeAppelSortant' : event.target.value
    });
  }

  renderOrigine() {
    return (<div className="panel panel-default">
      <ul className="nav-tabs nav alt-style" style={{ marginRight: '-15px' }}>
        <li className="active">
          <a data-toggle="tab" href="#form-origine">
            {this.state.EstAppelSortant && !this.state.appelSortantCreateLead?
              <h2><i className="fa fa-phone" aria-hidden="true" style={{ verticalAlign: 'top', marginRight: '5px' }}></i> Appel sortant</h2>
            :
              <h2><img src="/img/VENDEUR.svg" role='presentation'/> Nouveau lead </h2>
            }
          </a>
        </li>
      </ul>

        <div className='row'>
          {this.state.EstAppelSortant && this.state.appelSortantCreateLead?
          <div className="col-md-4 col-xs-12 col-affaire">
            <div className="form-group">
              <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Type d'affaire</label>
              <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
                <div className="btn-group flex-box">
                  <button className={this.state.type === 'VN'
                    ? 'btn btn-default active btn-group-xs'
                    : 'btn btn-default btn-group-xs'} type="button" value="VN" onClick={this.changeType.bind(this, 'VN')}>{Dictionary.getUCFirst("VN")}</button>
                  <button className={this.state.type === 'VO'
                    ? 'btn btn-default active btn-group-xs'
                    : 'btn btn-default btn-group-xs'} type="button" value="VO" onClick={this.changeType.bind(this, 'VO')}>{Dictionary.getUCFirst("VO")}</button>
                  <button key="typeAelier" className={this.state.type === 'Atelier'
                    ? 'btn btn-default active btn-group-xs'
                    : 'btn btn-default btn-group-xs'} disabled={false} type="button" value="Atelier" onClick={this.changeType.bind(this, 'Atelier')}>Atelier</button>
                  <button key="typeAutres" className={this.state.type === 'Autres'
                    ? 'btn btn-default active btn-group-xs'
                    : 'btn btn-default btn-group-xs'} disabled={false} type="button" value="Autres" onClick={this.changeType.bind(this, 'Autres')}>Autres</button>
                </div>
              </div>
            </div>
          </div>
          : null}

        </div>

        <div className='row'>

          <div className="col-md-5 col-xs-12">
            <div className="form-group">
              <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Campagne :</label>
              <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12" style={{ paddingTop: '3px' }}>
                {this.state.rapport.Campagne.Nom}
              </div>
            </div>
          </div>

          <div className="col-md-7 col-xs-12">
            <div className="form-group">
              <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Origine :</label>
              <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12" style={{ paddingTop: '3px' }}>
                {this.state.rapport.Campagne.OrigineAction.Famille} / {this.state.rapport.Campagne.OrigineAction.SousFamille} / {this.state.rapport.Campagne.OrigineAction.Origine}
              </div>
            </div>
          </div>
        </div>

    </div>);
  }

  render() {
    if (!this.state.rapport || !this.state.prospect) {
      return null;
    }

    return (
      <div className={'page-sidebar-right' + (this.props.location.query.origin && (this.props.location.query.origin.toString().substr(0, 4) === 'http' || this.props.location.query.origin === 'DBS' || (this.props.location.query.origin === 'CallWin' && this.state.CallcenterTypeCreateLead !== '')) ? ' hide-sidebar' : '')}>

        <SkyLight hideOnOverlayClicked ref="modalWeekCalendar" dialogStyles={{ width: '90%', left: '5%', marginLeft: '0%', height: '90%', top: '5%', marginTop: '0%', overflow: 'scroll', zIndex: 9999}}>
          <div className='inner-large-content'>
            <Scheduler IDPlaque={this.state.societe} IDSite={this.state.site} onSelectCalendar={this.onSelectCalendar.bind(this)} typeLead={this.state.type} />
          </div>
        </SkyLight>

        <SkyLight hideOnOverlayClicked ref="modalVehiculeHistoriqueAtelier" dialogStyles={{ width: '90%', left: '5%', marginLeft: '0%', height: '90%', top: '5%', marginTop: '0%', overflow: 'scroll', zIndex: 9999}}>
          <div className='inner-large-content'>
            <VehiculeHistoriqueAtelier IDProspect={this.state.prospectId} IDVehicule={this.state.VehiculeHistoriqueAtelier} />
          </div>
        </SkyLight>

        <SkyLight hideOnOverlayClicked ref="modalVehiculeParcClientCreate"
                dialogStyles={{padding:0, width: '90%', left: '5%', marginLeft: '0%', height: '90%', top: '5%', marginTop: '0%', overflow: 'scroll', zIndex: 9999 }}
                title={"Création d'un "+ Dictionary.getUCFirst("véhicule") +" en parc"}
                titleStyle={{ padding: 10, margin: 0, color: 'white' }}
                closeButtonStyle={{ color: 'white'}}>
          <div className='inner-large-content'>
            <VehiculeParcForm IDProspect={this.state.prospectId} finishCreateVehicule={this.finishCreateVehicule.bind(this)} />
          </div>
        </SkyLight>

        <SkyLight hideOnOverlayClicked ref="modalVehiculeEdit"
                dialogStyles={{padding:0, width: '90%', left: '5%', marginLeft: '0%', height: '90%', top: '5%', marginTop: '0%', overflow: 'scroll', zIndex: 9999 }}
                title={"Edition d'un "+ Dictionary.getUCFirst("véhicule") +" en parc"}
                titleStyle={{ padding: 10, margin: 0, color: 'white' }}
                closeButtonStyle={{ color: 'white'}}>
          <div className='inner-large-content'>
            <VehiculeParcForm IDProspect={this.state.prospectId} vehicule={this.state.selected_vehicule} finishEditVehicule={this.finishEditVehicule.bind(this)} />
          </div>
        </SkyLight>

        {this.state.successCreate ?
          <SweetAlert success title='' onConfirm={() => this.propageSuccess()}>
            <span>{this.state.successCreateMessage}</span>
          </SweetAlert>
        : null}

        {this.state.errorCreate ?
          <SweetAlert error title="Une erreur s'est produite" onConfirm={() => this.hideAlert()}>
            {this.state.errorCreateMessage}
          </SweetAlert>
        : null}

        <div className="page-sidebar-right-content" ref='pageSidebarRightContent'>
          <div className="col-md-12">


            <form className="form-horizontal create-lead" onSubmit={this.handleSubmit.bind(this)}>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                  {this.renderOrigine()}

                  <br />

                  <div className="row">
                    <div className="col-md-12">
                    <ul className="nav-tabs nav alt-style" style={{ marginRight: '-15px' }}>
                      <li className="active">
                        <a data-toggle="tab" href="#form-prospect">
                          <h2>
                            <img src="/img/PROSPECT.svg" role='presentation' /> Prospect
                            {this.state.type === 'Atelier' && this.state.historiquesAteliers.length ?
                            <span>
                              <i className="fa fa-info-circle" aria-hidden="true" style={{ marginLeft: '10px', fontSize: '14px' }}></i>
                              <TableRowSite Site={this.state.prospect ? this.state.prospect.SiteAffectation : null} style={{ color: 'white', fontSize: '12px', backgroundColor: '#3E4955', padding: '5px', paddingLeft: '10px', paddingRight: '10px', marginLeft: '10px',  }} />
                            </span>
                            : null}
                            {this.props.location.query.origin === 'CallWin' && this.state.prospects && this.state.prospect && this.state.prospectId && this.state.prospect.SuiviPars && this.state.prospect.SuiviPars.length ?
                              <span className='prospect-details-suivi' style={{ paddingLeft: 15, fontSize: '11px' }}>
                                Suivi par : <span className='blue-c bold'>{this.state.prospect.SuiviPars[0].Nom}</span> &nbsp;
                                <Tooltip trigger={['hover']} overlay={<div>{this.state.prospect.SuiviPars.map((people, i) => { return <div key={i}>{people.Nom}</div> })}</div>}>
                                  <i className="fa fa-users" aria-hidden="true"></i>
                                </Tooltip>
                              </span>
                            : null}
                          </h2>
                        </a>
                      </li>
                    </ul>
                      {this.props.location.query.origin === 'CallWin' && this.state.prospects && !this.state.prospectId ?
                        <table className='table table-condensed' style={{ marginTop: '10px' }}>
                          <thead>
                            <tr>
                              <th>Nom</th>
                              <th>Prénom</th>
                              <th>Email</th>
                              <th>Téléphones</th>
                              <th>Adresse</th>
                              <th>Code postal</th>
                              <th>Ville</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.prospects.map((p) => {
                              return (<tr key={p.IDProspect}>
                                <td>{p.Nom}</td>
                                <td>{p.Prenom}</td>
                                <td>{p.EMail}</td>
                                <td>
                                  {p.Telephones.map(x => x.Numero).join(', ')}
                                </td>
                                <td>{p.Adresse}</td>
                                <td>{p.CodePostal}</td>
                                <td>{p.Ville}</td>
                                <td><a onClick={this.selectProspect.bind(this, p.IDProspect)}>Choisir</a></td>
                              </tr>)
                            })}
                          </tbody>
                        </table>
                      :null}

                      {this.state.prospect ?
                        <ProspectFormEdit prospect={this.state.prospect} ref="ProspectFormEdit" noDisplayButtonSave={true} typesCreate={localStorage.bypass_iFrame && (this.props.location.query.origin !== 'CallWin') ? {"leads": []} : this.state.typesCreate} typeActive={this.state.type} withoutEditingType={true}/>
                      : null}
                    </div>
                  </div>

                  {(this.state.EstAppelSortant || this.state.type === 'Atelier') && this.state.prospect ?
                    <div className="row">
                      <div className="col-md-12">
                        <ul className="nav-tabs nav alt-style" style={{ marginRight: '-15px' }}>
                          <li className="active">
                            <a data-toggle="tab" href="#form-prospect">
                              {localStorage.IsAgri === 'true' ? 
                                <h2>
                                  <img src="/img/TRACTEUR.svg" role='presentation' /> Matériels en parc
                                </h2>
                              :
                                <h2>
                                  <img src="/img/AFFAIRE VO.svg" role='presentation' /> Véhicules en parc
                                </h2>
                              }
                            </a>
                          </li>
                        </ul>
                      </div>

                      {this.state.vehicules && this.state.vehicules.length ?
                        <div className="col-md-12">
                          <br />
                          <table className='table table-condensed'>
                            <thead>
                              <tr>
                                <th></th>
                                <th>Marque</th>
                                <th>Modèle</th>
                                <th>Immat</th>
                                <th>Chassis</th>
                                <th>KMs</th>
                                <th className='text-center'>Historique atelier</th>
                                <th className='text-center'>Éditer</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.vehicules.map((v) => {
                                return (<tr key={v.IDVehicule}>
                                    <td>
                                      <input type="checkbox" name={"vehicule_"+v.IDVehicule} id={"vehicule_"+v.IDVehicule} onChange={this.changeVehicule.bind(this, v.IDVehicule)} checked={this.isCheckedVehicule(v.IDVehicule)} />
                                    </td>
                                    <td>{v.Marque}</td>
                                    <td>{v.Modele}</td>
                                    <td>{v.Immatriculation}</td>
                                    <td>{v.VIN}</td>
                                    <td>{v.Kilometrage}</td>
                                    <td className='text-center'>
                                      {v.NombreHistoriqueAteliers ?
                                        <a onClick={(e) => {
                                          this.setState({
                                            VehiculeHistoriqueAtelier: v.IDVehicule,
                                          }, () => {
                                            this.refs.modalVehiculeHistoriqueAtelier.show()
                                          })
                                        }}>Voir</a>
                                      : null}
                                    </td>
                                    <td className="text-center" ><a className="glyphicon glyphicon-pencil" onClick={(e)=> this.callEditVehicule(v.IDVehicule)}></a></td>
                                  </tr>)
                              })}
                            </tbody>
                          </table>
                        </div>
                      : <div className="col-md-12 text-center"><em style={{ margin: '20px', display: 'inline-block'}}>Aucun</em></div> }

                      <div className="col-md-12 text-center" style={{ marginBottom: '15px'}}>
                        <a className='btn btn-default btn-xs' onClick={(e) => this.refs.modalVehiculeParcClientCreate.show()}>Ajouter un véhicule</a>
                      </div>
                    </div>
                  : null}

                  { (this.state.appelSortantCreateLead && this.state.type === 'VN') ? <FormVN changeValue={this.changeValue.bind(this)} defaultComment={this.state.appelSortantComment} /> : null}

                  {(this.state.appelSortantCreateLead && this.state.type === 'VO')? <FormVO changeValue={this.changeValue.bind(this)} defaultComment={this.state.appelSortantComment} /> : null}

                  {(this.state.appelSortantCreateLead && this.state.type === 'Atelier') ? <FormAtelier ref="FormAtelierRef" lockProspectMecaplanning={this.state.lockProspectMecaplanning} UrlMecaplanning={this.state.UrlMecaplanning} site={this.state.site} changeValue={this.changeValue.bind(this)} defaultComment={this.state.appelSortantComment} /> : null}

                  {(this.state.appelSortantCreateLead && this.state.type === 'Autres')  ? <FormAutres changeValue={this.changeValue.bind(this)} defaultComment={this.state.appelSortantComment} /> : null}

                  {this.state.EstAppelSortant && !this.state.appelSortantCreateLead && !this.state.appelSortantRelanceForm ?
                    <div className="col-md-12" style={{ marginBottom: '15px', marginTop: '15px', }}>
                      <div className="text-center">
                        <button type="submit" className="text-center btn btn-default submit-vo" onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            appelSortantClotureForm: true
                          }, () => {
                            this.refs['pageSidebarRightContent'].scrollIntoView({ behavior: "smooth" });
                          })
                        }} disabled={this.disabledButtonValid()} style={{ marginRight: '15px'}}>
                        {this.state.fetchCreateInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null} Clôturer
                        </button>

                        <button type="submit" className="text-center btn btn-default submit-vo"  disabled={this.disabledButtonValid()} style={{ marginRight: '15px'}} onClick={this.appelSortantCreateLead.bind(this)}>
                          {this.state.fetchCreateInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null} Créer un lead
                        </button>
                      </div>
                    </div>
                  : null}

                  {this.state.appelSortantClotureForm ?
                    <div>
                      <FormAppelSortant
                        changeValue={this.changeValue.bind(this)}
                        appelSortantRelanceForm={false}
                        displayRaisonCloture={true}
                        />

                        <div className="col-md-12 text-center">
                          <button type="submit" className="text-center btn btn-default submit-vo"  disabled={(!this.state.codeRaisonClotureAppelSortant && !this.state.appelSortantComment)} style={{ marginRight: '15px'}}>
                            {this.state.fetchCreateInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null} Valider
                          </button>

                          <button type="submit" className="text-center btn btn-default submit-vo"  disabled={this.state.fetchCreateInProgress} style={{ marginRight: '15px'}} onClick={(e) => { this.setState({ appelSortantClotureForm: false }) } }>
                            Annuler
                          </button>
                        </div>
                      </div>
                  : null}

                  {!this.state.EstAppelSortant || (this.state.EstAppelSortant && this.state.appelSortantCreateLead) ?
                    <div className="col-md-12" style={{ marginBottom: '15px', marginTop: '15px', }}>
                      <div className="text-center">
                        <button type="submit" className="text-center btn btn-default submit-vo" disabled={this.disabledButtonValid()} style={{ marginRight: '15px'}} >
                          {this.state.fetchCreateInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null} Valider {this.state.type !== 'Atelier' && this.state.type !== 'Autres' ? 'le lead' : ''}
                        </button>

                        <button type="submit" className="text-center btn btn-default submit-vo"  disabled={this.state.fetchCreateInProgress} style={{ marginRight: '15px'}} onClick={(e) => { this.setState({ appelSortantCreateLead: false, disabledEditOrigine: false }) } }>
                          Annuler
                        </button>
                      </div>
                    </div>
                  : null}

                  {this.state.EstAppelSortant && this.state.appelSortantRelanceForm ?
                    <div className="col-md-12" style={{ marginBottom: '15px', marginTop: '15px', }}>
                      <div className="text-center">
                        <button type="submit" className="text-center btn btn-default submit-vo" style={{ marginRight: '15px'}} onClick={this.cancelAppelSortantRelance.bind(this)}>
                          {this.state.fetchCreateInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null} Annuler
                        </button>
                      </div>
                    </div>
                  : null}
                </div>

              </div>
            </form>

          </div>
        </div>

        <div className="page-sidebar-right-sidebar dark-skin" style={!Auth.loggedIn() ? {paddingTop: '0'} : null}>

          <div className="page-sidebar-right-sidebar-container">

            {this.state.type === 'VN'  && !this.state.EstAppelSortant?
              <FormSocieteSiteVN
                onChangeValue={this.changeValue.bind(this)}
                marque={this.state.marque}
                societe={this.state.societe}
                site={this.state.site}
                typeActive={this.state.type}
                prospect={this.state.prospect}
                callwin={this.props.location.query.called ? true : false}
                ref="formsocietesite"
                origin={this.props.location.query.origin} />
            : null}

            {this.state.type &&  this.state.type !== 'Atelier' && this.state.type !== "Autres" && this.state.EstAppelSortant?
              <FormSocieteSiteAppelSortant
                onChangeValue={this.changeValue.bind(this)}
                marque={this.state.marque}
                prospect={this.state.prospect}
                societe={this.state.societe}
                site={this.state.site}
                typeActive={this.state.type}
                ref="formsocietesite"
                origin={this.props.location.query.origin} />
            : null}

            {this.state.type === 'VO'  & !this.state.EstAppelSortant ?
              <FormSocieteSiteVO
                onChangeValue={this.changeValue.bind(this)}
                marque={this.state.marque}
                societe={this.state.societe}
                site={this.state.site}
                typeActive={this.state.type}
                callwin={this.props.location.query.called ? true : false}
                ref="formsocietesite"
                origin={this.props.location.query.origin} />
            : null}

            {this.state.type === 'Atelier' || this.state.type === 'Autres'?
              <FormSocieteSiteAtelier
                onChangeValue={this.changeValue.bind(this)}
                marque={this.state.marque}
                societe={this.state.societe}
                site={this.state.site}
                typeActive={this.state.type}
                ref="formsocietesite"
                origin={this.props.location.query.origin} />
            : null}

            {((this.state.EstAppelSortant && this.state.appelSortantCreateLead)) &&
            (this.state.type === 'Atelier' || this.state.type === 'Autres') && this.state.site ?
              <FormToDoCreateAtelierAutres
                onChangeValue={this.changeValue.bind(this)}
                todoToVendeur={true}
                todoVendeur={this.state.todoVendeur ? this.state.todoVendeur : []}
                site={this.state.site}
                typeActive={this.state.type} />
            : null}

            {localStorage.access_affectationUtilisateur === 'true' && ((this.state.EstAppelSortant && this.state.appelSortantCreateLead)) && (this.state.type === 'VN' || this.state.type === 'VO') && this.state.site ?
              <FormToDoCreate
                openWeekCalendar={this.openModelWeekCalendar.bind(this)}
                onChangeValue={this.changeValue.bind(this)}
                todoDate={this.state.todoDate}
                todoHeure={this.state.todoHeure}
                todoToVendeur={this.state.todoToVendeur}
                todoVendeur={this.state.todoVendeur}
                site={this.state.site}
                todoEtape={this.state.todoEtape}
                todoComment={this.state.todoComment}
                typeActive={this.state.type} />
            : null}

            {this.state.site && localStorage.access_creationRelance === 'true' && this.state.todoToVendeur && this.state.type !== 'Atelier' && this.state.type !== 'Autres' ?
              <FormRelance
                onChangeValue={this.changeValue.bind(this)}
                relance={this.state.relance}
                relanceDate={this.state.relanceDate}
                relanceHeure={this.state.relanceHeure}
                relanceAction={this.state.relanceAction}
                relanceCommentaire={this.state.relanceCommentaire}
                />
            : null}

            <div className='clearfix'></div>

          </div>

        </div>
      </div>
    );

}
}

export default AppelSortantIframe;
