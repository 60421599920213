import React, { Component } from 'react';
import Globals from '../Globals';
import { browserHistory } from 'react-router'

import moment from 'moment'

import IconCanal from '../Components/IconCanal';
import TableRowCampagneOrigine from '../Components/TableRowCampagneOrigine';
import TableRowSite from '../Components/TableRowSite';
import TableRowComment from '../Components/TableRowComment';
import Delai from '../Components/Delai'
import WelcomeProMail from '../Components/WelcomeProMail';

import ReactGA from 'react-ga';
import Dictionary from '../Dictionnary';

import Tooltip from "rc-tooltip";
var lodash = require('lodash')

class LeadsARedistribuer extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      items_selected: [],
      displayListeVendeur: false,
      utilisateurs: [],
      _utilisateurs: [],
      trasnfertVendeur: null,
      progressTransfert: false,
    }
  }

  componentWillMount() {
    this.fetchutilisateurs();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.leads !== prevProps.leads) {
      this.setState({
        items_selected: [],
      });
    }
  }

  fetchutilisateurs() {

    var lead = lodash.filter(this.props.leads, (lead) => {
      return lead.IDLead === lodash.first(this.state.items_selected);
    }).pop();

    if(lead){
      fetch(localStorage.ClientApiURL + '/utilisateurs/' + localStorage.user_IDUtilisateur + "/manage?id_site=" + parseInt(lead.Site.IDSite, 10), {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(json => {
        var utilisateurs = lodash.orderBy(json, 'Nom');
        this.setState({
          utilisateurs: utilisateurs,
          selected: null
        });
      })
      .catch((error) => {
        console.log(error)
      });
    }
  }

  handleCheck(IDLead, event) {
    this.itemToggle(IDLead, event.target.checked)
  }

  itemToggle(IDLead, checked) {

    if (this.state.progressTransfert) {
      return;
    }

    var items = this.state.items_selected;

    if (checked) {
      items.push(IDLead)
    }
    else {
      items.splice(items.indexOf(IDLead), 1);
    }
    
    this.setState({
      items_selected: items,
      displayListeVendeur: false,
    }, () => {
      if (this.state.items_selected.length === 1) {
        this.fetchutilisateurs();
      }
    })
  }

  isChecked(IDLead) {
    if (this.state.items_selected.indexOf(IDLead) >= 0) {
      return true;
    }
    return false;
  }

  displayTransferCommercial() {
    if (!this.state.items_selected.length || !this.state.utilisateurs.length) {
      return;
    }

    this.setState({
      displayListeVendeur: true,
    })
  }

  transferCommercial(event) {
    this.setState({
      trasnfertVendeur: event.target.value,
    })
  }

  onClickTransfert() {

    this.setState({
      progressTransfert: true,
    })

    ReactGA.event({category: 'Leads à redistribuer', action: 'Redistribuer'});

    let body = lodash.filter(this.props.leads, (lead) => {
      if (this.state.items_selected.indexOf(lead.IDLead) >= 0) {
        return true;
      }
      return false;
    });

    body = JSON.stringify(body);

    fetch(localStorage.ClientApiURL + '/leads/transferer_many?id_destinataire=' + this.state.trasnfertVendeur, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: body,
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    })
    .then(json => {
      localStorage.refreshHeaderCounters = "1";
      delete localStorage.leads; //On force le rechargement de la liste des leads
      this.setState({
        displayListeVendeur: false,
        trasnfertVendeur: null,
        items_selected: [],
      }, () => {
        this.props.reloadLeads();
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  handleclickAffaire(IDProspect) {
    browserHistory.push('/prospect/' + IDProspect);
  }

  goToRapport() {
    if (this.state.items_selected[0])
      browserHistory.push('/rapport/' + this.state.items_selected[0]);
  }

  canBeCheck(lead, firstLeadSelected) {
    if (this.state.items_selected.length <= 0) {
      return true;
    }

    if (this.state.items_selected.indexOf(lead.IDLead) >= 0) {
      return true;
    }

    if (lead.Site.IDSite == firstLeadSelected.Site.IDSite) {
      return true;
    }

    return false;
  }

  renderTransfertActions() {
    return <div>{!this.state.displayListeVendeur?
      <a className='pull-right btn btn-default' onClick={this.displayTransferCommercial.bind(this)} disabled={!this.state.items_selected.length  || !this.state.utilisateurs.length }>
        {this.state.items_selected.length && !this.state.utilisateurs.length ? "Aucun vendeur de paramétré" : "Transférer à un vendeur"}
      </a>
    : null}
    {this.state.items_selected.length && this.state.displayListeVendeur ?
      <div className='pull-right form-group'>
        <label style={{ display: 'inline-block', width: 'auto' }}>Transférer à &nbsp;</label>
        <select className="form-control size-xs" id="vendeur" name="vendeur" onChange={this.transferCommercial.bind(this)} style={{ display: 'inline-block', width: 'auto' }}>
          <option></option>
          <option value={localStorage.user_IDUtilisateur}>Moi même</option>
          {this.state.utilisateurs.map((utilisateur, i) => {
            var lead = lodash.find(this.props.leads, (lead) => {
              return lead.IDLead === this.state.items_selected[0];
            });

            if ((lead.Affaire.Type === 'VO' && !utilisateur.EstTypeVO) || (lead.Affaire.Type === 'VN' && !utilisateur.EstTypeVN)) {
              return null;
            }

            return <option key={i} value={utilisateur.IDUtilisateur}>{utilisateur.Nom}</option>
          })}
        </select>
        &nbsp;
        <button onClick={this.onClickTransfert.bind(this)} disabled={!this.state.trasnfertVendeur || this.state.progressTransfert} className='btn btn-default'>Transférer</button>
      </div>
    : null}
    </div>;
  }

  render() {

    var firstLeadSelected = null;

    if (this.state.items_selected.length > 0) {
      firstLeadSelected = lodash.find(this.props.leads, (lead) => {
        return lead.IDLead == this.state.items_selected[0];
      })
    }

    return (
      <div>

        {localStorage.access_redistribuer === 'true' ?
          <div style={{ height: '40px', overflow: 'hidden' }}>

            {!(localStorage.BDCTraitementLeadAmontChefVente && localStorage.BDCTraitementLeadAmontChefVente === 'true' && localStorage.user_profil === 'BDC') ?
              this.renderTransfertActions()
            : null}

            <a className='pull-right btn btn-default' style={{ marginRight: '15px' }} onClick={this.goToRapport.bind(this)} disabled={!this.state.items_selected.length  || !this.state.utilisateurs.length }>
              Traiter
            </a>
          </div>
        : null}

        <div className="">
          <div className="wrap-table">
          <table className="table table-eseller-lead table-striped table-hover table-condensed">
            <thead>
              <tr>
                <th className='text-right' style={{ width: '130px', }}>Date</th>
                <th className='text-center'>Type</th>
                <th className='text-center'>Canal</th>
                <th style={{ width: '200px', }}>Campagne / Origine</th>
                <th style={{ width: '150px', }}>Site</th>
                <th>Contact</th>
                <th></th>
                <th>Délai</th>
                <th></th>
                <th style={{ width: '40px', }} className='text-center'></th>
              </tr>
            </thead>
            <tbody>
              {this.props.leads.map((lead, i) =>
                <tr key={i} className={this.isChecked(lead.IDLead) ? 'active tr-link' : ' tr-link'}>
                  <td className='text-right' onClick={this.handleclickAffaire.bind(this, lead.Prospect.IDProspect)}>
                    {moment(lead.InfoCreation.DateH).format('DD/MM/YYYY HH:mm')}
                  </td>
                  <td className='text-center' onClick={this.handleclickAffaire.bind(this, lead.Prospect.IDProspect)} >
                    {Dictionary.getUCFirst(lead.Affaire.Type)}
                  </td>
                  <td className='text-center'>
                    <IconCanal canal={lead.ActionFaite_DO.CanalOrigine} />
                  </td>
                  <td onClick={this.handleclickAffaire.bind(this, lead.Prospect.IDProspect)} >
                    <TableRowCampagneOrigine campagne={lead.Campagne} origine={lead.ActionFaite_DO.OrigineAction} />
                  </td>
                  <td style={{ overflow: 'hidden' }} onClick={this.handleclickAffaire.bind(this, lead.Prospect.IDProspect)} >
                    <TableRowSite Site={lead.Site} />
                  </td>
                  <td onClick={this.handleclickAffaire.bind(this, lead.Prospect.IDProspect)} >
                    {lead.Prospect.Nom} {lead.Prospect.Prenom}
                  </td>
                  <td onClick={this.handleclickAffaire.bind(this, lead.Prospect.IDProspect)} className='text-left'>
                    <Tooltip trigger={['hover']} overlay={<div>{lead.Prospect.Type}</div>}>
                      <span>{lead.Prospect.Type[0]}</span>
                    </Tooltip>
                  </td>
                  <td onClick={this.handleclickAffaire.bind(this, lead.Prospect.IDProspect)} >
                    <Delai date={lead.InfoCreation.DateH} />
                  </td>
                  <td>
                    {lead.Affaire.Description ? <TableRowComment comment={lead.Affaire.Description} /> : <TableRowComment comment={lead.ActionFaite_DO.Commentaire} /> }
                    <WelcomeProMail rapport={lead} />
                  </td>
                  <td className='text-center'>
                    {localStorage.access_redistribuer === 'true' && this.canBeCheck(lead, firstLeadSelected) ?
                      <div className="checkbox-form" onClick={this.itemToggle.bind(this, lead.IDLead, !this.isChecked(lead.IDLead))}>
                        <input type="checkbox" name={"lead_"+lead.IDLead} id={"lead_"+lead.IDLead} onChange={this.handleCheck.bind(this, lead.IDLead)} checked={this.isChecked(lead.IDLead)} />
      									<label className="label-box" htmlFor={"lead_"+lead.IDLead}></label>
                      </div>
                    : null}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          </div>
        </div>

      </div>
    );
  }
}

export default LeadsARedistribuer;
