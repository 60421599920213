import React, {Component} from 'react';
import Globals from "../Globals";
import moment from 'moment';
import Loader from '../Components/Loader';
let lodash = require('lodash');


class GestionVendeurs extends Component {
    constructor(state) {
        super(state);

        this.state = {
            fetchInProgress : false,

            /* Select mois et vendeurs*/
            months: [],
            vendeurs: [],
            coefficients:[],
            sites: [],

            /* Gestion des objectifs vendeurs*/
            currentIDUtilisateur:null,
            currentMonth:null,
            currentIDSite:"allSites",
            objectifs:[],
            allVendeursObjectifs:[],
            oldAllVendeursObjectifsOld:[],
            message:"",
            matType:[1,2],
            /** Result PUT et POST via l'API */
            successCreate: false,
            errorCreate:false,
            errorCreateMessage:"",
        };
    }

    /***
     * Au chargement du composant
     */
    componentWillMount() {
        this.fetchMonths();
        this.fetchVendeurs();
    }

    /**
     * Mois en cours + 12
     */
    fetchMonths(){
        var now = new Date();
        var month = now.getMonth();
        var year = now.getFullYear();
        this.setState({
            currentMonth: `${year}${ ('0' + (month+1)).slice(-2)}`,
        });
        var names = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    
        var finalMonth = [];
        for (var i = 0; i < 13; ++i) {
            finalMonth.push({
                libelle:names[month] + ' ' + year,
                IDMonth: `${year}${ ('0' + (month+1)).slice(-2)}`,
            });
            if (++month === 12) {
                month = 0;
                ++year;
            }
        }
        this.setState({
            months: finalMonth,
        });
    }

    

    /***
     * Récupère tous les vendeurs manage par l'utilisateur chef des ventes connecté
     */
    fetchVendeurs() {
        fetch(localStorage.ClientApiURL + '/utilisateurs/' + localStorage.user_IDUtilisateur + "/manage?with_code_nissan=true&LoadSites=true", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            var sites = []
            var vendeurs = lodash.remove(json, function(val) {
                if(val.TypeProfilLead.toLowerCase() == "vendeur"){
                    val.Sites.forEach(site => {
                        if(!lodash.find(sites, function(obj) { return obj.IDSite == site.IDSite; }))
                            sites.push(site)
                    });
                    return val;
                }
            });
            
            vendeurs = lodash.orderBy(vendeurs, 'Nom');
            
            this.setState({
                sites:sites,
                vendeurs: vendeurs,
            },
            () => {
                if(localStorage.IsNissan === "true" || lodash.countBy(this.state.vendeurs, function(val) { return val.CodeVendeurNissan != null; }).true) {
                    this.fetchCoefficients();
                }
            })
        })
        .catch((error) => {
            console.log(error)
        });
    }
    
    /***
     * Récupère les objectifs pour le vendeur sélectionné
     */
    fetchObjectifs() {
        if(this.state.currentIDUtilisateur != null){
            if(this.state.currentIDUtilisateur == "allVendeurs"){
                fetch(localStorage.ClientApiURL + '/objectifsVendeurs?mois=' + this.state.currentMonth, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'APIKey': Globals.API_KEY,
                        'IDClient': localStorage.user_IDClient,
                        'Login': localStorage.user_login,
                        'Password': localStorage.user_password
                    },
                })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then(json => {
                    lodash.forEach(this.state.vendeurs, function(vendeur, key) {
                        var objectifVendeur = lodash.find(json, function(obj) {return obj.IDPeople == vendeur.IDUtilisateur;});
                        if(objectifVendeur == undefined){
                            json.push(
                                {
                                    "Nom" : vendeur.Nom,
                                    "Sites" : vendeur.Sites,
                                    "IDPeople": vendeur.IDUtilisateur,
                                    "Objectifs": [
                                        {
                                            "IDPeople": vendeur.IDUtilisateur,
                                            "IDMatType": 1,
                                            "NbContacts": 0,
                                            "NbEssais": 0,
                                            "NbOffres": 0,
                                            "NbCommandes": 0,
                                            "Mois": this.state.currentMonth
                                        },
                                        {
                                            "IDPeople": vendeur.IDUtilisateur,
                                            "IDMatType": 2,
                                            "NbContacts": 0,
                                            "NbEssais": 0,
                                            "NbOffres": 0,
                                            "NbCommandes": 0,
                                            "Mois": this.state.currentMonth
                                        }
                                    ]
                                }
                            )
                        }else{
                            var index = lodash.findIndex(json, function(obj) { return obj.IDPeople == vendeur.IDUtilisateur; });
                            json[index].Nom = vendeur.Nom
                            json[index].Sites = vendeur.Sites
                        }
                        json = lodash.orderBy(json, 'Nom');
                    }.bind(this));

                    this.setState({
                        allVendeursObjectifs: json ,
                        fetchInProgress:false
                    })
                })
                .catch((error) => {
                    console.log(error)
                });

                fetch(localStorage.ClientApiURL + '/objectifsVendeurs?mois=' + (this.state.currentMonth-1), {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'APIKey': Globals.API_KEY,
                        'IDClient': localStorage.user_IDClient,
                        'Login': localStorage.user_login,
                        'Password': localStorage.user_password
                    },
                })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then(json => {
                    this.setState({
                        oldAllVendeursObjectifsOld: json ,
                        fetchInProgress:false
                    })
                })
                .catch((error) => {
                    console.log(error)
                });

            }else{
                fetch(localStorage.ClientApiURL + '/objectifsVendeurs/'+this.state.currentIDUtilisateur+'?mois=' + this.state.currentMonth, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'APIKey': Globals.API_KEY,
                        'IDClient': localStorage.user_IDClient,
                        'Login': localStorage.user_login,
                        'Password': localStorage.user_password
                    },
                })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then(json => {
                    if(json.length != 0){
                        this.setState({
                            objectifs: json ,
                            fetchInProgress:false
                        });
                    }else{
                        this.setState({
                            objectifs: [
                                {
                                    "IDPeople": this.state.currentIDUtilisateur,
                                    "IDMatType": 1,
                                    "NbContacts": 0,
                                    "NbEssais": 0,
                                    "NbOffres": 0,
                                    "NbCommandes": 0,
                                    "Mois": this.state.currentMonth
                                },
                                {
                                    "IDPeople": this.state.currentIDUtilisateur,
                                    "IDMatType": 2,
                                    "NbContacts": 0,
                                    "NbEssais": 0,
                                    "NbOffres": 0,
                                    "NbCommandes": 0,
                                    "Mois": this.state.currentMonth
                                }
                            ] ,
                            fetchInProgress:false
                        });
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
            }
        }else{
            console.log("aucun vendeur sélectionné")
        }
        
    }

    /***
     * Récupère les coefficients a appliquer sur les objectfs (NISSAN UNIQUEMENT)
     */
    fetchCoefficients() {
        fetch(localStorage.ClientApiURL + '/objectifsVendeursConfig', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            this.setState({
                coefficients: json ,
            });
        })
        .catch((error) => {
            console.log(error)
        });        
    }

    /***
     * construction de l'objet pour l'appel de l'api et sauvegarde des objectifs de tout les vendeurs
     * @param event
     */
    saveAllObjectif(event) {
        var objectifs = this.state.objectifs
        var allVendeursObjectifs = this.state.allVendeursObjectifs
        lodash.forEach(allVendeursObjectifs, function(objectifVendeur) {
            if(lodash.sumBy(objectifVendeur.Objectifs, function(val) { return val.NbCommandes; }) != 0){
                lodash.forEach(objectifVendeur.Objectifs, (objectif) =>{
                    objectifs.push(
                        {
                            "IDPeople": objectifVendeur.IDPeople,
                            "IDMatType": objectif.IDMatType,
                            "NbContacts": objectif.NbContacts,
                            "NbEssais": objectif.NbEssais,
                            "NbOffres": objectif.NbOffres,
                            "NbCommandes": objectif.NbCommandes,
                            "Mois": this.state.currentMonth
                        }
                    )
                })
            }
        }.bind(this));

        this.setState({
            objectifs: objectifs,
        },
        () => {
            this.saveObjectif(event);
        })
    }

    /***
     * Appel de l'API pour insérer en base de données les nouveaux objectifs
     */
    saveObjectif() {
        if(this.state.objectifs.length <=0 || this.state.currentIDUtilisateur === null || this.state.currentMonth ===null){
            console.log("Une variable importante est null");
            return;
        }
        let body = this.state.objectifs;
        body = JSON.stringify(body);

        fetch(localStorage.ClientApiURL + '/objectifsVendeurs', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
            },
            body: body,
        })
        .then((response) => {
            if (!response.ok) {
                response.json().then((value) => {
                    this.setState({
                        errorCreate: true,
                        errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                    });
                });

                throw Error(response.statusText);
            }
            else {
                this.setState({
                    message:"Objectifs enregistrés avec succès",
                })
            }
        })

        .catch((error) => {
            console.warn(error);
        });
    }

    /***
     * Changement de société sélectionnée
     * @param event
     * @param typeObjectif
     */
    onChangeObjectif(event,typeObjectif) {
        let val = 0
        if(event.target.value != ""){
            val = event.target.value
        }
        let IDMatType = event.target.name
        let objectifs = this.state.objectifs
        let coefficients = this.state.coefficients

        if(lodash.find(this.state.objectifs, function(obj) { return obj.IDMatType == IDMatType; }) == undefined){
            objectifs.push(
                {
                    "IDPeople": this.state.currentIDUtilisateur,
                    "IDMatType": IDMatType,
                    "NbContacts": 0,
                    "NbEssais": 0,
                    "NbOffres": 0,
                    "NbCommandes": 0,
                    "Mois": this.state.currentMonth
                }
            )
        }

        if(this.isNissan()){
            lodash.forEach(objectifs, (objectif)=>{
                if(objectif.IDMatType == IDMatType){
                    objectif["NbCommandes"] = parseInt(val)
                    objectif["NbContacts"] = Math.round(coefficients["CoefficientContact"] * parseInt(val))
                    objectif["NbEssais"] = Math.round(coefficients["CoefficientEssai"]  * parseInt(val))
                    objectif["NbOffres"] = Math.round(coefficients["CoefficientOffre"] * parseInt(val))
                }
            })
        }else{
            lodash.forEach(objectifs, (objectif)=>{
                if(objectif.IDMatType == IDMatType){
                    return objectif[typeObjectif] = parseInt(val)
                }
            })
            
        }

        this.setState({
            objectifs: objectifs,
        })
    }

    /***
     * Changement de société sélectionnée
     * @param event
     * @param IDPeople
     */
    onChangeAllObjectif(event,IDPeople) {
        let val = 0
        if(event.target.value != ""){
            val = event.target.value
        }
        let IDMatType = event.target.name
        var allVendeursObjectifs = this.state.allVendeursObjectifs
        let coefficients = this.state.coefficients

        lodash.forEach(allVendeursObjectifs, (objectifVendeur)=>{
            if(objectifVendeur.IDPeople == IDPeople){
                lodash.forEach(objectifVendeur.Objectifs, (objectif) =>{
                    if(objectif.IDMatType == IDMatType){
                        objectif["NbCommandes"] = parseInt(val)
                        objectif["NbContacts"] = Math.round(coefficients["CoefficientContact"] * parseInt(val))
                        objectif["NbEssais"] = Math.round(coefficients["CoefficientEssai"]  * parseInt(val))
                        objectif["NbOffres"] = Math.round(coefficients["CoefficientOffre"] * parseInt(val))
                    }
                })
            }
        })

        this.setState({
            allVendeursObjectifs: allVendeursObjectifs,
        })
    }

    /***
     * Changement de société sélectionnée
     * @param event
     */
    handleChangeMonth(event) {
        this.setState({
            currentMonth: event.target.value,
            fetchInProgress:true
        },
        () => {
            this.fetchObjectifs();
        })
    }

    /***
     * Changement du site sélectionné
     * @param event
     */
    handleChangeVendeur(event) {
        this.setState({
            currentIDUtilisateur: event.target.value,
            fetchInProgress:true
        },
        () => {
            this.fetchObjectifs();
        })
    }
    
    /***
     * Changement du site sélectionné
     * @param event
     */
    handleChangeSite(event) {
        this.setState({
            currentIDSite: event.target.value,
            currentIDUtilisateur: null,
            fetchInProgress:true
        },
        () => {
            this.fetchObjectifs();
        })
    }

    /***
     * Renvoi si nissan ou pas en fonction du param IsNissan local storage, et si pas nissan vérifie si il existe un code frd pour le vendeur actuel (permet de gérer les coefficient multiplicateurs)
     */
    isNissan(IDPeople = null){
        var isNissan = false;
        var currentIDUtilisateur = this.state.currentIDUtilisateur

        if(IDPeople != null){
            if(lodash.find(this.state.vendeurs, function(obj) { return obj.IDUtilisateur == IDPeople; }).CodeVendeurNissan != null){
                return true
            }
        }else{
            if(localStorage.IsNissan == "true" || lodash.find(this.state.vendeurs, function(obj) { return obj.IDUtilisateur == currentIDUtilisateur; }).CodeVendeurNissan != null){
                return true
            }
        }

        return isNissan
    }

    render(){
        const currentIDSite = this.state.currentIDSite
        return(

            <div className="container">
                <h2>
                    <img src="/img/VENDEUR.svg" role='presentation'/> Gestion des objectifs vendeurs
                </h2>
                <hr/>
                <div className="row">

                    {/*Select de la societe et du site*/}
                    <div className="col-md-3" >

                        <div className="form-group row" >
                            <label className="col-lg-9 control-label text-left" style={{ fontWeight: 'bold', fontSize: '13px'  }}>Mois :</label>
                            <div className="col-lg-9">
                                <select className="form-control" onChange={this.handleChangeMonth.bind(this)} id="month" name="month">
                                    {this.state.months.map((month, id) => {
                                        return <option selected={this.state.currentMonth == month.IDMonth} key={id} value={month.IDMonth}>{month.libelle}</option>;
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3" >
                        <div className="form-group row">
                            <label className="col-lg-9 control-label text-left" style={{ fontWeight: 'bold', fontSize: '13px'  }}>Site :</label>
                            <div className="col-lg-9">
                                <select className="form-control" onChange={this.handleChangeSite.bind(this)} id="people" name="people">
                                    <option value="allSites">Tous les sites</option>
                                    {this.state.sites.map((site, id) => {
                                        return <option selected={this.state.currentIDSite == site.IDSite} key={id} value={site.IDSite}>{site.Libelle ? site.Libelle : site.Nom}</option>;
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3" >
                        <div className="form-group row">
                            <label className="col-lg-9 control-label text-left" style={{ fontWeight: 'bold', fontSize: '13px'  }}>Vendeur :</label>
                            <div className="col-lg-9">
                                <select className="form-control" onChange={this.handleChangeVendeur.bind(this)} id="people" name="people">
                                    <option></option>
                                    {localStorage.IsNissan === "true" || lodash.countBy(this.state.vendeurs, function(val) { 
                                        if(currentIDSite == "allSites"){
                                            return val.CodeVendeurNissan != null; 
                                        }else{
                                            if(lodash.find(val.Sites, function(site) { return currentIDSite == site.IDSite}))
                                                return val.CodeVendeurNissan != null; 
                                        }
                                    }).true ?
                                        <option selected={this.state.currentIDUtilisateur == "allVendeurs"} value="allVendeurs">Tous les vendeurs {localStorage.IsNissan === "false" ?  "Nissan" : null}</option>
                                    : null}
                                    {this.state.vendeurs.map((vendeur, id) => {
                                        if(lodash.find(vendeur.Sites, function(site) { return currentIDSite == site.IDSite})  || currentIDSite == "allSites"){
                                            return <option key={id} value={vendeur.IDUtilisateur}>
                                                    {vendeur.Nom}
                                                    {localStorage.IsNissan === "false" ? 
                                                        this.isNissan(vendeur.IDUtilisateur) ? " (Nissan)" : null
                                                    :null}
                                                </option>
                                        }
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                {this.state.currentIDUtilisateur?
                    this.state.fetchInProgress === true ?
                        <Loader style={{ marginTop: 150 }} />
                    :
                        <>
                        {this.state.message ?
                            <div>{this.state.message.includes('succès')?
                                        <p className={"alert alert-success"}>{this.state.message}</p>
                                    :   <p className={"alert alert-info"}>{this.state.message}</p>}
                            </div>
                        :null}
                        {this.state.errorCreateMessage ?
                            <div>
                                    <p className={"alert alert-danger"}>{this.state.errorCreateMessage}</p>
                            </div>
                        :null}
                        {this.state.currentIDUtilisateur != "allVendeurs"?
                            <div className="col-md-10">
                                <form onSubmit={this.saveObjectif.bind(this)}>
                                    <div className='row' style={this.props.style}>
                                        <table className='table table-eseller-lead table-striped table-hover table-condensed'>
                                            <thead>
                                                <tr style={{ backgroundColor: '#9A0059' }}>
                                                    <th></th>
                                                    <th style={{textAlign: "center",verticalAlign: "middle"}}>VN</th>
                                                    <th style={{textAlign: "center",verticalAlign: "middle"}}>VO</th>
                                                    <th style={{textAlign: "center",verticalAlign: "middle"}}>Total</th>
                                                </tr>
                                            </thead> 
                                            <tbody>
                                                <tr>
                                                    <td>Contacts</td>
                                                    {this.state.matType.map((IDMatType) => {
                                                        var objectif = lodash.find(this.state.objectifs, function(obj) { return obj.IDMatType == IDMatType; });
                                                        return (
                                                            <td key={IDMatType}><input type="number" className="form-control" name={IDMatType} disabled={this.isNissan()} value={objectif ? objectif.NbContacts : 0} onChange={(e) => this.onChangeObjectif(e,"NbContacts")}  required={true} /></td>
                                                        )
                                                    })}
                                                    
                                                    <td style={{textAlign: "center",verticalAlign: "middle"}}>{lodash.sumBy(this.state.objectifs, function(val) { return val.NbContacts; })}</td>
                                                </tr>
                                                <tr>
                                                    <td>Essais</td>
                                                    {this.state.matType.map((IDMatType) => {
                                                        var objectif = lodash.find(this.state.objectifs, function(obj) { return obj.IDMatType == IDMatType; });
                                                        return (
                                                            <td key={IDMatType}><input type="number" className="form-control" name={IDMatType} disabled={this.isNissan()} value={objectif ? objectif.NbEssais : 0} onChange={(e) => this.onChangeObjectif(e,"NbEssais")}  required={true} /></td>
                                                        )
                                                    })}
                                                    <td style={{textAlign: "center",verticalAlign: "middle"}}>{lodash.sumBy(this.state.objectifs, function(val) { return val.NbEssais; })}</td>
                                                </tr>
                                                <tr>
                                                    <td>Offres</td>
                                                    {this.state.matType.map((IDMatType) => {
                                                        var objectif = lodash.find(this.state.objectifs, function(obj) { return obj.IDMatType == IDMatType; });
                                                        return (
                                                            <td key={IDMatType}><input type="number" className="form-control" name={IDMatType} disabled={this.isNissan()} value={objectif ? objectif.NbOffres : 0} onChange={(e) => this.onChangeObjectif(e,"NbOffres")}  required={true} /></td>
                                                        )
                                                    })}
                                                    <td style={{textAlign: "center",verticalAlign: "middle"}}>{lodash.sumBy(this.state.objectifs, function(val) { return val.NbOffres; })}</td>
                                                </tr>
                                                <tr>
                                                    <td>Commandes</td>
                                                    {this.state.matType.map((IDMatType) => {
                                                        var objectif = lodash.find(this.state.objectifs, function(obj) { return obj.IDMatType == IDMatType; });
                                                        return (
                                                            <td key={IDMatType}><input type="number" className="form-control" name={IDMatType} value={objectif ? objectif.NbCommandes : 0} onChange={(e) => this.onChangeObjectif(e,"NbCommandes")}  required={true} /></td>
                                                        )
                                                    })}
                                                    <td style={{textAlign: "center",verticalAlign: "middle"}}>{lodash.sumBy(this.state.objectifs, function(val) { return val.NbCommandes; })}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                                <div className="col-md-3" >
                                    <div className="text-center">
                                        <button type="submit" className="text-center btn btn-default submit-vo" style={{marginTop:'10px'}} onClick={(e)=>this.saveObjectif(e)}>Valider les objectifs</button>
                                    </div>
                                </div>
                            </div>
                        : 
                            <div className="col-md-10">
                                <form onSubmit={this.saveAllObjectif.bind(this)}>
                                    <div className='row' style={this.props.style}>
                                        <table className='table table-eseller-lead table-striped table-hover table-condensed'>
                                            <thead>
                                                <tr style={{ backgroundColor: '#9A0059' }}>
                                                    <th>Vendeur</th>
                                                    <th style={{textAlign: "center",verticalAlign: "middle"}}>Objectif Commandes VN</th>
                                                    <th style={{textAlign: "center",verticalAlign: "middle"}}>Objectif Commandes VO</th>
                                                    <th style={{textAlign: "center",verticalAlign: "middle"}}>Total</th>
                                                </tr>
                                            </thead> 
                                            <tbody>
                                                {this.state.allVendeursObjectifs.map((objectifVendeur, indexVendeur) => {
                                                    if((lodash.find(objectifVendeur.Sites, function(site) { return currentIDSite == site.IDSite})  || currentIDSite == "allSites") && (localStorage.IsNissan === "true" || (this.isNissan(objectifVendeur.IDPeople)))){
                                                        var oldObjectifVendeur = lodash.find(this.state.oldAllVendeursObjectifsOld, function(obj) { return obj.IDPeople == objectifVendeur.IDPeople; });
                                                        return(
                                                        <tr key={indexVendeur}>
                                                            <td>{objectifVendeur.Nom}</td>
                                                            {this.state.matType.map((IDMatType) => {
                                                                if(oldObjectifVendeur != undefined){
                                                                    var oldObjectif = lodash.find(oldObjectifVendeur.Objectifs, function(obj) { return obj.IDMatType == IDMatType;  });
                                                                }
                                                                var objectif = lodash.find(objectifVendeur.Objectifs, function(obj) { return obj.IDMatType == IDMatType; });
                                                                return (
                                                                    <>
                                                                        <td key={IDMatType}>
                                                                            <input style={{marginTop: "5px"}} type="number" className="form-control" name={IDMatType} value={objectif ? objectif.NbCommandes : 0} onChange={(e) => this.onChangeAllObjectif(e,objectifVendeur.IDPeople)}  required={true} />
                                                                            <p style={{marginTop: "3px"}}>Mois précédent : {oldObjectif ? oldObjectif.NbCommandes : 0}</p>
                                                                        </td>
                                                                    </>
                                                                )
                                                            })}
                                                            <td>
                                                                <p style={{textAlign: "center",verticalAlign: "middle"}}>{lodash.sumBy(objectifVendeur.Objectifs, function(val) { return val.NbCommandes; })}</p>
                                                                <p style={{marginTop: "3px"}}>Mois précédent : {oldObjectifVendeur ? lodash.sumBy(oldObjectifVendeur.Objectifs, function(val) { return val.NbCommandes; }) : 0}</p>
                                                            </td>
                                                        </tr>
                                                        );
                                                    }
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                                <div className="col-md-3" >
                                    <div className="text-center">
                                        <button type="submit" className="text-center btn btn-default submit-vo" style={{marginTop:'10px'}} onClick={(e)=>this.saveAllObjectif(e)}>Valider les objectifs</button>
                                    </div>
                                </div>
                            </div>
                        }
                        </>
                    : null}
                </div>
            </div>
        )
    }
}
export default GestionVendeurs;
