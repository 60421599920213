import React, { Component } from 'react';
import Sidebar from './Sidebar';
import Globals from '../Globals';
import TypeATraiter from './TypeATraiter';
import TypeAtelier from './TypeAtelier';
import TypeEnCours from './TypeEnCours';
import AffairesClotures from './AffairesClotures';
import AffairesGagnees from './AffairesGagnees';
import RapportAtelier from './RapportAtelier';

import {CSVLink} from 'react-csv';

import Loader from '../Components/Loader';
import FilterDate from '../Components/FilterDate';
import FilterPeriode from '../Components/FilterPeriode';
import {MobileOnlyView, CustomView, isMobileOnly} from "react-device-detect";

import './Leads.css';

import moment from 'moment'

var lodash = require('lodash')

class LeadsAtelier extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      leads: [],
      typeLead: '',

      filters: [],
      filterName: '',
      displaySidebar: true,
      cacheLead: [],
      dateStart: moment().startOf('week'),
      dateEnd: moment().endOf('week'),

      distinctTypes: [],
      distinctTypesDemandes: [],
      distinctPriorites: [],
      distinctEtapes: [],

      typePeriode: 'late',

      nbLeadsEnCours:[],

      showLead: false,
      IDLead: null,

      setIntervalID: null,
      datasToDownload: null,
    }

    this.typesDemandesSidebar = [
      {
        name:'Prise de RDV',
        class:'demandederdv',
      },
      {
        name:'Demande de devis',
        class:'demandededevis',
      },
      {
        name:'Suivi travaux',
        class:'suivitravaux',
      },
      {
        name:'Réclamations',
        class:'reclamations',
      },
      {
        name:"Demande d'infos",
        class:'demandesdinfos'
      }];

    this.onBack = this.handleBack.bind(this);
    this.onIntervalReload = this.intervalReload.bind(this);
  }

  componentWillMount() {
    this.fetchDatas();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.type !== this.props.params.type) {
      this.setState({
        leads: [],
        cacheLead: [],
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.params.type === prevProps.params.type) {
      if(this.props.params.type === "AffairesEncours" && this.props.params.typeEnCours !== prevProps.params.typeEnCours){
        this.generateCacheLeads();
        this.generateDistinctPriorites();
        this.generateDistinctTypes();
        this.generateDistinctEtapes();
        this.prepareDatasToDownload();
      }
      return;
    }

    this.setState({
      leads: [],
      filters: [],
      filterName: '',
      displaySidebar: true,
      cacheLead: [],
      dateStart: moment().startOf('days'),
      dateEnd: moment().endOf('days'),
    }, () => {
      this.fetchDatas();
    })
  }

  componentDidMount() {
    window.addEventListener('popstate', this.onBack);
    var setIntervalID = window.setInterval(this.onIntervalReload, 300000);
    this.setState({
      setIntervalID: setIntervalID
    })
  }

  componentWillUnmount(){
    window.removeEventListener('popstate', this.onBack);
    window.clearInterval(this.state.setIntervalID);
  }

  handleBack() {
    if(this.state.showLead){
      this.showList();
    }
  }

  intervalReload(){
    if(!this.state.showLead)
    {
      this.fetchDatas();
    }
  }

  async fetchDatasLead() {

    var date_start = moment(this.state.dateStart);
    if (date_start <= moment('2017-12-20')) {
      date_start = moment('2017-12-20');
    }

    fetch(localStorage.ClientApiURL + '/leads/atelier?date_debut='+moment(date_start).format('Y-MM-DD')+'&date_fin='+moment(this.state.dateEnd).format('Y-MM-DD'), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        leads: json,
      }, () => {
        this.generateCacheLeads();
        this.setState({
          fetchInProgress:false
        });
        this.generateDistinctTypes();
        //this.generateDistinctTypesDemande();
        this.generateDistinctPriorites();
      });
    })
    .catch((error) => {
      console.log(error)
    });
      
  }

  fetchDatas() {
    this.setState({
      fetchInProgress: true,
    });

    if (localStorage.eSellerLeadAtelierMailRapport === 'true') {
      this.fetchDatasLead();
      return;
    }
    
    var type = this.props.params.type;

    var url = '';
    if (type == 'AffairesEncours') {
      url = '/leadsAtelier/NonTermines'
    }
    else if (type == 'AffairesGagnees') {
      url = '/leadsAtelier/Gagnee?date_debut='+moment(this.state.dateStart).format('Y-MM-DD')+'&date_fin='+moment(this.state.dateEnd).format('Y-MM-DD')
    }
    else if (type == 'AffairesTraitees') {
      url = '/leadsAtelier/Traitee'
    }
    else if (type == 'AffairesClotures') {
      url = '/leadsAtelier/Perdue?date_debut='+moment(this.state.dateStart).format('Y-MM-DD')+'&date_fin='+moment(this.state.dateEnd).format('Y-MM-DD')
    }
    else {
      this.setState({
        leads: [],
      }, () => {
        this.generateCacheLeads();
        this.generateDistinctPriorites();
        this.generateDistinctTypes();
        this.generateDistinctTypesDemande();
        this.generateDistinctEtapes();
        this.generateDistinctVendeurs();
      });
      return;
    }


    fetch(localStorage.ClientApiURL + url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {

      var leads = [];

      for(var l in json){
        let lead = json[l];
        lead.TypeDemande = this.getTypeDemande(lead);
        lead.IsATraiter = this.leadIsATraiter(lead);
        leads.push(lead);
      }
      this.setState({
        leads:leads
      }, () => {
        if (type == 'AffairesGagnees') {
          this.prepareDatasAffaireToDownload();
        }
        else if (type == 'AffairesClotures') {
          this.prepareDatasAffairesClotureesToDownload();
        }
        else {
          this.prepareDatasToDownload();
        }
        this.generateCacheLeads();
        this.setState({
          fetchInProgress:false
        });
        this.generateNbLeadsEnCours();
        this.generateDistinctPriorites();
        this.generateDistinctTypes();
        this.generateDistinctTypesDemande();
        this.generateDistinctEtapes();
        this.generateDistinctVendeurs();
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

  prepareDatasToDownload() {
    var datas = [];
    datas.push(['Type Lead', 'Commercial', 'Date de création', 'Date de relance', 'Site', 'Campagne', 'Famille', 'Sous-Famille', 'Origine', 'Contact', 'Identifiant Client', 'Tél Privé', 'Tél Pro', 'Tél Mobile', 'Email', 'Email Pro', 'Dernier commentaire'])
    var leads = this.getLeadsCurrentType();

    leads.map((l) => {
      let telPrive = lodash.find(l.Prospect.Telephones, {Type:'Prive'});
      let telPro = lodash.find(l.Prospect.Telephones, {Type:'Professionnel'});
      let telMobile = lodash.find(l.Prospect.Telephones, {Type:'Mobile'});
      datas.push([
        l.TypeDemande,
        l.SuiviPar.Nom,
        moment(l.Affaire.DateHDebut).format('DD/MM/Y HH:mm:ss'),
        moment(l.ActionAFaire_TODO.DateHProchaineEtape).format('DD/MM/Y HH:mm:ss'),
        l.Site.Code,
        l.Campagne ? l.Campagne.Nom : '',
        l.Affaire.OrigineAction ? l.Affaire.OrigineAction.Famille : '',
        l.Affaire.OrigineAction ? l.Affaire.OrigineAction.SousFamille : '',
        l.Affaire.OrigineAction ? l.Affaire.OrigineAction.Origine : '',
        l.Prospect.Nom + ' ' + l.Prospect.Prenom,
        l.Prospect.IDProspect,
        telPrive ? '=""'+telPrive.Numero.toString()+'""' : '',
        telPro ? '=""'+telPro.Numero.toString()+'""' : '',
        telMobile ? '=""'+telMobile.Numero.toString()+'""' : '',
        l.Prospect.Mail ? l.Prospect.Mail : '',
        l.Prospect.MailPro ? l.Prospect.MailPro : '',
        l.Affaire.CommentaireDernierRapport ? l.Affaire.CommentaireDernierRapport.replace(/(\r\n|\r|\n|\\r\\n|\\n|\\r)/g, " ") : '',
      ]);
    })

    this.setState({
      datasToDownload: datas,
    });
  }

  prepareDatasAffaireToDownload() {
    var datas = [];
    datas.push(['Type Lead', 'Commercial', 'Date de création', 'Site', 'Campagne', 'Famille', 'Sous-Famille', 'Origine', 'Contact', 'Identifiant Client', 'Tél Privé', 'Tél Pro', 'Tél Mobile', 'Email', 'Email Pro'])
    var type = this.props.params.type;

    this.state.leads.map((l) => {
      let telPrive = lodash.find(l.Prospect.Telephones, {Type:'Prive'});
      let telPro = lodash.find(l.Prospect.Telephones, {Type:'Professionnel'});
      let telMobile = lodash.find(l.Prospect.Telephones, {Type:'Mobile'});
      datas.push([
        l.Type,
        (type === 'AffairesGagnees' || type === 'AffairesClotures') ? l.DernierRapportUtilisateur.Nom : l.SuiviPar.Nom,
        moment(l.DateHDebut).format('DD/MM/Y HH:mm:ss'),
        l.Site.Code,
        l.Campagne ? l.Campagne.Nom : '',
        l.OrigineAction ? l.OrigineAction.Famille : '',
        l.OrigineAction ? l.OrigineAction.SousFamille : '',
        l.OrigineAction ? l.OrigineAction.Origine : '',
        l.Prospect.Nom + ' ' + l.Prospect.Prenom,
        l.Prospect.IDProspect,
        telPrive ? '=""'+telPrive.Numero.toString()+'""' : '',
        telPro ? '=""'+telPro.Numero.toString()+'""' : '',
        telMobile ? '=""'+telMobile.Numero.toString()+'""' : '',
        l.Prospect.Mail ? l.Prospect.Mail : '',
        l.Prospect.MailPro ? l.Prospect.MailPro : ''
      ]);
    })

    this.setState({
      datasToDownload: datas,
    });
  }

  prepareDatasAffairesClotureesToDownload() {
    var datas = [];
    datas.push(['Type Lead', 'Commercial', 'Date de création', 'Site', 'Campagne', 'Famille', 'Sous-Famille', 'Origine', 'Étape à date de clôture', 
    'Contact', 'Identifiant Client', 'Tél Privé', 'Tél Pro', 'Tél Mobile', 'Email', 'Email Pro', 'Dernier commentaire'])
    var type = this.props.params.type;

    this.state.leads.map((l) => {
      let telPrive = lodash.find(l.Prospect.Telephones, {Type:'Prive'});
      let telPro = lodash.find(l.Prospect.Telephones, {Type:'Professionnel'});
      let telMobile = lodash.find(l.Prospect.Telephones, {Type:'Mobile'});
      datas.push([
        l.Type,
        (type === 'AffairesGagnees' || type === 'AffairesClotures') ? l.DernierRapportUtilisateur.Nom : l.SuiviPar.Nom,
        moment(l.DateHDebut).format('DD/MM/Y HH:mm:ss'),
        l.Site.Code,
        l.Campagne ? l.Campagne.Nom : '',
        l.OrigineAction ? l.OrigineAction.Famille : '',
        l.OrigineAction ? l.OrigineAction.SousFamille : '',
        l.OrigineAction ? l.OrigineAction.Origine : '',
        l.EtapeDernierRapport ? l.EtapeDernierRapport.Description : '',
        l.Prospect.Nom + ' ' + l.Prospect.Prenom,
        l.Prospect.IDProspect,
        telPrive ? '=""'+telPrive.Numero.toString()+'""' : '',
        telPro ? '=""'+telPro.Numero.toString()+'""' : '',
        telMobile ? '=""'+telMobile.Numero.toString()+'""' : '',
        l.Prospect.Mail ? l.Prospect.Mail : '',
        l.Prospect.MailPro ? l.Prospect.MailPro : '',
        l.CommentaireDernierRapport ? l.CommentaireDernierRapport.replace(/(\r\n|\r|\n|\\r\\n|\\n|\\r)/g, " ") : '',
      ]);
    })

    this.setState({
      datasToDownload: datas,
    });
  }

  getFileName() {
    var name = 'eSeller_Lead_'+this.props.params.type+'_';
    name += moment().format('DD-MM-YYYY-hh-mm-ss');
    return name+'.csv';
  }

  getTypeDemande(lead) {
    if (!lead.Affaire || !lead.Affaire.Description)
      return 'Autres';

    var matchs = lead.Affaire.Description.match(/Type de demande : (.*)(\n|\r)/);

    if (!matchs)
      return 'Autres';

    return matchs[1];
  }

  leadIsATraiter(lead){
    //return this.leadIsBusiness(lead) && !lead.InfoCreation.Utilisateur.IDUtilisateur;
    //return this.leadIsBusiness(lead) && !lead.SuiviPar.IDUtilisateur;
    return localStorage.BDCTraitementLeadsAPV === 'true' && this.leadIsBusiness(lead) && lead.ARedistribuer;
  }

  leadIsBusiness(lead){
    return lead.TypeDemande === 'Prise de RDV' || lead.TypeDemande === 'Demande de devis';
  }

  generateNbLeadsEnCours(){
    if(this.props.params.type === "AffairesEncours")
    {
      var nbLeadsEnCours = [];
      nbLeadsEnCours = lodash.countBy(this.state.leads, function(lead){
        if(lead.IsATraiter){
          return "A Traiter";
        }
        else {
          if(lead.ActionAFaire_TODO && moment(lead.ActionAFaire_TODO.DateHRelance).isAfter())
            return lead.TypeDemande+"_AVenir";
          else 
            return lead.TypeDemande+"_EnRetard";
        }
      });
      localStorage.nbLeadsEnCours = JSON.stringify(nbLeadsEnCours);
    }
    else 
    {
      nbLeadsEnCours = localStorage.nbLeadsEnCours ? JSON.parse(localStorage.nbLeadsEnCours) : [];
    }
    this.setState({
      nbLeadsEnCours: nbLeadsEnCours
    });

    //console.log(nbLeadsEnCours);
  }

  generateDistinctEtapes() {

    if (this.props.params.type === 'AffairesClotures' || this.props.params.type === 'AffairesGagnees' || this.props.params.type === 'AffairesTraitees') {
      return;
    } 

    var type = this.props.params.type;
    var typeEnCours = this.props.params.typeEnCours
    var leads = this.state.leads;
    if(type === 'AffairesEncours' && typeEnCours){
      if(typeEnCours === 'ATraiter'){
        leads = lodash.filter(leads, function(lead){
          return lead.IsATraiter;
        });
      }
      else {
        leads = lodash.filter(leads, function(lead){
          return lead.TypeDemande === typeEnCours && !lead.IsATraiter;
        });
      }
    }

    var etapes = lodash.countBy(this.state.leads, function(lead) {
      return lead.ActionAFaire_TODO.Etape ? lead.ActionAFaire_TODO.Etape.Description : "";
    });
    this.setState({distinctEtapes: etapes});
  }

  generateDistinctTypes() {
    var typePage = this.props.params.type;
    var types = lodash.countBy(this.state.leads, function(lead) {
      return "Atelier";
      /*if (typePage === 'AffairesClotures') {
        return lead.OrigineAction ? lead.OrigineAction.Type : "";
      }

      return lead.Affaire.OrigineAction ? lead.Affaire.Type : "";*/
    });
    this.setState({distinctTypes: types});
  }

  generateDistinctPriorites() {
    var type = this.props.params.type;
    var typeEnCours = this.props.params.typeEnCours
    var leads = this.state.leads;
    
    if(type === 'AffairesEncours' && typeEnCours){
      if(typeEnCours === 'ATraiter'){
        leads = lodash.filter(leads, function(lead){
          return lead.IsATraiter;
        });
      }
      else {
        leads = lodash.filter(leads, function(lead){
          return lead.TypeDemande === typeEnCours && !lead.IsATraiter;
        });
      }
    }
    var priorites = lodash.countBy(leads, function(lead) {
      var descriptionAffaire = lead.Affaire ? lead.Affaire.Description : '';
      //var descriptionAffaire = lead.ActionFaite_DO.Commentaire;

      if (!descriptionAffaire) {
        return '';
      }

      var matchs = descriptionAffaire.match(/Demande urgente : (.*)(\n|\r)/)

      if (!matchs) {
        return '';
      }

      return matchs[1] === 'Oui' ? 'Demande urgente' : '';
    });
    this.setState({distinctPriorites: priorites});
  }

  getLeadsCurrentType(){
    var leads = this.state.leads;
    var type = this.props.params.type;
    var typeEnCours = this.props.params.typeEnCours
    //if(!typeEnCours)typeEnCours = "Prise de RDV";

    if(type === 'AffairesEncours' && typeEnCours){
      if(typeEnCours === 'ATraiter'){
        leads = lodash.filter(leads, function(lead){
          return lead.IsATraiter;
        });
      }
      else {
        leads = lodash.filter(leads, function(lead){
          return lead.TypeDemande === typeEnCours && !lead.IsATraiter;
        });
      }
    }
    return leads;
  }

  generateDistinctVendeurs(){
    if(this.props.params.type === 'AffairesGagnees' || this.props.params.type === 'AffairesClotures'){
      var vendeurs = lodash.countBy(this.state.leads, function(lead) {
        return lead.DernierRapportUtilisateur ? lead.DernierRapportUtilisateur.Nom : "";
      });
    }
    else {
      var vendeurs = lodash.countBy(this.state.leads, function(lead) {
        return lead.SuiviPar ? lead.SuiviPar.Nom : "";
      });
    }
    this.setState({distinctVendeurs: vendeurs});
  }

  getTypeDemandeByAffaire(affaire) {
    if (!affaire.Description)
      return 'Autres';

    var matchs = affaire.Description.match(/Type de demande : (.*)(\n|\r)/);

    if (!matchs)
      return 'Autres';

    return matchs[1];
  }

  generateDistinctTypesDemande() {
    if (this.props.params.type !== 'AffairesClotures' && this.props.params.type !== 'AffairesGagnees') {
      return;
    } 

    var this2 = this;
    var types = lodash.countBy(this.state.leads, function(lead) {
      return this2.getTypeDemandeByAffaire(lead);
    });
    console.log(types)
    this.setState({distinctTypesDemandes: types});
  }

  generateCacheLeads() {
    var leads = this.getLeadsCurrentType();
    var type = this.props.params.type;

    if (this.state.filters) {
      if (this.state.filters['origines'] && this.state.filters['origines'].length) {
        var filter_origines = this.state.filters['origines'];
        leads = lodash.filter(leads, function(lead) {
          if (lead.Affaire) {
            var check = lead.ActionFaite_DO.OrigineAction ? lead.ActionFaite_DO.OrigineAction.Famille+"-"+lead.ActionFaite_DO.OrigineAction.SousFamille+"-"+lead.ActionFaite_DO.OrigineAction.Origine : '';
          }
          else {
            var check = lead.OrigineAction ? lead.OrigineAction.Famille+"-"+lead.OrigineAction.SousFamille+"-"+lead.OrigineAction.Origine : '';
          }
          
          return filter_origines.indexOf(check) > -1;
        });
      }

      if (this.state.filters['types'] && this.state.filters['types'].length) {
        var filter_types = this.state.filters['types'];
        leads = lodash.filter(leads, function(lead) {
          var check = lead.Affaire.OrigineAction ? lead.Affaire.Type : "";
          return filter_types.indexOf(check) > -1;
        });
      }

      if (this.state.filters['typesDemandes'] && this.state.filters['typesDemandes'].length) {
        var filter_typesDemandes = this.state.filters['typesDemandes'];
        leads = lodash.filter(leads, function(lead) {
          var check = "Autres";

          if (lead.Affaire) {
            var descriptionAffaire = lead.Affaire.CommentaireDernierRapport ? lead.Affaire.CommentaireDernierRapport : lead.Affaire.Description;
          }
          else {
            var descriptionAffaire = lead.Description;
          }
          

          if (descriptionAffaire) {
            var matchs = descriptionAffaire.match(/Type de demande : (.*)(\n|\r)/)

            if (matchs) {
              check = matchs[1];
            }
          }

          return filter_typesDemandes.indexOf(check) > -1;
        });
      }

      if (this.state.filters['prioritesDemandes'] && this.state.filters['prioritesDemandes'].length) {
        var filter_priorites = this.state.filters['prioritesDemandes'];
        leads = lodash.filter(leads, function(lead) {
          var check = "";
          var descriptionAffaire = lead.Affaire.Description;
          //var descriptionAffaire = lead.ActionFaite_DO.Commentaire;

          if (descriptionAffaire) {
            var matchs = descriptionAffaire.match(/Demande urgente : (.*)(\n|\r)/)

            if (matchs) {
              check = (matchs[1] === 'Oui') ? 'Demande urgente' : '';
            }
          }
          return filter_priorites.indexOf(check) > -1;
        });
      }

      if (this.state.filters['etapes'] && this.state.filters['etapes'].length) {
        var filter_etapes = this.state.filters['etapes'];
        leads = lodash.filter(leads, function(lead) {
          var check = lead.ActionAFaire_TODO.Etape ? lead.ActionAFaire_TODO.Etape.Description : "";
          return filter_etapes.indexOf(check) > -1;
        });
      }

      if (this.state.filters['interets'] && this.state.filters['interets'].length) {
        var filter_interets = this.state.filters['interets'];
        leads = lodash.filter(leads, function(lead) {
          return filter_interets.indexOf(lead.ActionAFaire_TODO.NiveauInteret.Description) > -1;
        });
      }

      if (this.state.filters['sites'] && this.state.filters['sites'].length) {
        var filter_sites = this.state.filters['sites'];
        leads = lodash.filter(leads, function(lead) {
          var check;
          if (type === 'AffairesGagnees') {
            check = lead.Site ? lead.Site.IDSite : "";
          }
          else {
            check = lead.Site ? lead.Site.IDSite : "";
          }
          return filter_sites.indexOf(check) > -1;
        });
      }

      if (this.state.filters['vendeurs'] && this.state.filters['vendeurs'].length) {
        var filter_vendeurs = this.state.filters['vendeurs'];
        var type = this.props.params.type;
        leads = lodash.filter(leads, function(lead) {
          if(type === 'AffairesGagnees' || type === 'AffairesClotures')
            var check = lead.DernierRapportUtilisateur ? lead.DernierRapportUtilisateur.Nom : "";
          else
            var check = lead.SuiviPar ? lead.SuiviPar.Nom : "";
          return filter_vendeurs.indexOf(check) > -1;
        });
      }
    }

    this.sortLeads(leads, type);

    this.setState({cacheLead: leads})
  }

  sortLeads(leads, type){
    leads.sort(function(a, b) {
      var date_a, date_b;

      if (type === 'AffairesClotures' || type === 'AffairesGagnees' || type === 'AffairesTraitees') {
        date_a = moment(a.DateDebut);
        date_b = moment(b.DateDebut);
      }
      else if (type === 'TypeAtelier') {
        date_a = moment(a.InfoCreation.DateH);
        date_b = moment(b.InfoCreation.DateH);
      }
      else {
        date_a = moment(a.ActionAFaire_TODO.DateHProchaineEtape);
        date_b = moment(b.ActionAFaire_TODO.DateHProchaineEtape);
      }

      if (!date_a || !date_b) {
        return -1;
      }

      if (date_a < date_b)
        return -1;

      if ((date_a.format('DMY HH:mm') === date_b.format('DMY HH:mm')) && (parseInt(a.IDLead, 10) && parseInt(b.IDLead, 10))) {
        if (parseInt(a.IDLead, 10) < parseInt(b.IDLead, 10)) {
          return -1;
        }
      }

      return 1;
    });
  }

  onChangeTypeLead(typeLead) {
    this.setState({
      typeLead:typeLead
    })
  }

  onChangeFilters(type, datas) {
    var currentFilters;
    if(type === "reset")
      currentFilters = [];
    else {
      currentFilters = this.state.filters;
      currentFilters[type] = datas;
    }
    this.setState({filters: currentFilters}, () => {
      this.generateCacheLeads();
    });
  }

  onChangeDate(start, end) {
    this.setState({
      dateStart: start,
      dateEnd: end,
    }, () => {
      this.fetchDatas();
    })
  }

  onChangePeriode(type) {
    this.setState({
      typePeriode: type
    }, () => {
      this.generateCacheLeads();
    })
  }

  reloadLeads() {
    this.showList();
    this.fetchDatas();
  }

  onChangeFilterName(event) {
    this.setState({
      filterName: event.target.value,
    })
  }

  showLead(IDLead){
    this.setState({
      showLead:true,
      IDLead:IDLead
    }, () => {
      window.history.pushState(null, null, window.location.pathname);
    });
  }

  showList(){
    this.setState({
      showLead:false,
      IDLead:null
    });
  }

  render() {

    if(isMobileOnly) //Mobile
    {
      if(this.state.showLead){
        content = <RapportAtelier IDLead={this.state.IDLead} reloadLeads={this.reloadLeads.bind(this)}/>
      }
      else {
        var leadsATraiter = lodash.filter(this.state.leads, function(lead){
          return lead.IsATraiter;
        });
        this.sortLeads(leadsATraiter, "ATraiter");
      }
    }
    else //Tablette/Desktop
    {
      var leads = this.state.cacheLead;
      var leadsBeforeFiltre = this.state.leads;

      var content = null;

      var typeEnCours = this.props.params.typeEnCours
      if(typeEnCours){
        if(typeEnCours === 'ATraiter'){
          leadsBeforeFiltre = lodash.filter(leadsBeforeFiltre, function(lead){
            return lead.IsATraiter;
          });
        }
        else {
          leadsBeforeFiltre = lodash.filter(leadsBeforeFiltre, function(lead){
            return lead.TypeDemande === typeEnCours && !lead.IsATraiter;
          });
        }
      }

      if(this.state.showLead){
        content = <RapportAtelier IDLead={this.state.IDLead} reloadLeads={this.reloadLeads.bind(this)}/>
      }
      else {
        switch (this.props.params.type) {
          case 'TypeAtelier':
            content = <TypeAtelier leads={leads} reloadLeads={this.reloadLeads.bind(this)} />
            //content = <TypeAtelierTemp leads={leads} reloadLeads={this.reloadLeads.bind(this)} />
            break;
          case 'TypeATraiter':
            content = <TypeATraiter leads={leads} reloadLeads={this.reloadLeads.bind(this)} />
            break;
          case 'AffairesEncours':
            content = <TypeEnCours leads={leads} typeEnCours={this.props.params.typeEnCours} reloadLeads={this.reloadLeads.bind(this)} onClick={(IDLead) => this.showLead(IDLead)} />
            break;
          case 'RDVPris':
            content = <TypeEnCours leads={leads} reloadLeads={this.reloadLeads.bind(this)} />
            break;
          case 'DevisRealise':
            content = <TypeEnCours leads={leads} reloadLeads={this.reloadLeads.bind(this)} />
            break;
          case 'AffairesGagnees':
            content = <AffairesGagnees leads={leads} reloadLeads={this.reloadLeads.bind(this)} />
            break;
          case 'AffairesTraitees':
          content = <AffairesGagnees leads={leads} reloadLeads={this.reloadLeads.bind(this)}  />
          break;
          case 'AffairesClotures':
            content = <AffairesClotures leads={leads} reloadLeads={this.reloadLeads.bind(this)} />
            break;
          default:
            content = <h1></h1>
        }
      }
    }

    return (
      <div className="suivi-apv">

{/************************Desktop/Tablette View *************************/}
        <CustomView condition={!isMobileOnly}>
          <div className="page-sidebar-left">

            <div id="sidebar" className='page-sidebar-left-sidebar'>
              <Sidebar
                type={this.props.params.type}
                leads={leadsBeforeFiltre}
                types={this.state.distinctTypes}
                typesDemandes={this.state.distinctTypesDemandes}
                priorites={this.state.distinctPriorites}
                origines={this.state.distinctOrigines}
                etapes={this.state.distinctEtapes}
                vendeurs={this.state.distinctVendeurs}
                onChangeFilters={this.onChangeFilters.bind(this)} 

                typesDemandesSidebar={this.typesDemandesSidebar}
                nbLeadsEnCours={this.state.nbLeadsEnCours}
                onChangeTypeLead={(typeLead) => this.onChangeTypeLead(typeLead)}
                showList={() => this.showList()}
              />
            </div>

            <div className='page-sidebar-left-content'>
                {this.state.showLead ? 
                  <div className="action-bar">
                    <a className="btn btn-default" onClick={() => window.history.back()}>Retour à la liste</a>
                  </div>
                :null}
                

              {/*this.props.params.type === 'TypeEnCours' ?
                <div>
                  <FilterPeriode onChangePeriode={this.onChangePeriode.bind(this)} typePeriode={this.state.typePeriode} type={this.props.params.type} className='pull-left' />
                  <div className="form-group form-group-sm pull-right">
                    <input type="search" className='form-control' value={this.state.filterName} onChange={this.onChangeFilterName.bind(this)} placeholder='Filtre sur le contact' style={{ width: '200px', border: '1px solid #ccc', marginRight: '5px' }} />
                  </div>
                </div>
              : null*/}

              {this.props.params.type === 'TypeAtelier' || this.props.params.type === 'AffairesGagnees' || this.props.params.type === 'AffairesClotures' ?
                <FilterDate onChangeDate={this.onChangeDate.bind(this)} type={this.props.params.type} className='pull-left' defaultType='weeks' />
              : null}

              {this.state.datasToDownload ?
                <div className="pull-right" style={{marginLeft: '5px'}}>
                  <CSVLink data={this.state.datasToDownload} filename={this.getFileName()} className="float-right" target="_self" separator={";"} style={{lineHeight: '30px', marginRight: '15px'}}>
                    <i className="fa fa-download" aria-hidden="true"></i> Télécharger
                  </CSVLink>
                </div>
              : null}

              {this.state.fetchInProgress === false ?
                content
              : <Loader style={{ marginTop: 150 }} />}
            </div>
          </div>
        </CustomView>
{/************************Fin Desktop/Tablette View *************************/}

{/********************* MobileView **********************/}
        <MobileOnlyView style={{marginTop:'80px'}}>
          <div className="container-fluid">
            {this.state.fetchInProgress === false ?              
              this.state.showLead ? 
                <div className="">
                  <div className="action-bar">
                    <a className="btn btn-default" onClick={() => this.showList()}>Retour à la liste</a>
                  </div>
                  {content}
                </div>
              :
                <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                {localStorage.access_redistribuer === 'true' ?
                  <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="headingATraiter">
                      <h4 className="panel-title">
                        <a role="button" className="linkToList" data-toggle="collapse" data-parent="#accordion" href={"#collapseATraiter"} aria-expanded="false" aria-controls="collapseATraiter">
                          <div className="col-xs-8">Leads à traiter</div>
                        </a>
                      </h4>
                    </div>
                    <div id="collapseATraiter" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingATraiter">
                      <div className="panel-body">
                        <TypeEnCours leads={leadsATraiter} typeEnCours="ATraiter" reloadLeads={this.reloadLeads.bind(this)} onClick={(IDLead) => this.showLead(IDLead)} />                  
                      </div>
                    </div>
                  </div>
                : null}

                {this.typesDemandesSidebar.map((type, index) => {
                  var leads = lodash.filter(this.state.leads, function(lead){
                    return lead.TypeDemande === type.name && !lead.IsATraiter;
                  });
                  this.sortLeads(leads, type);
                  return (
                  <div className="panel panel-default" key={index}>
                    <div className="panel-heading" role="tab" id={"heading"+index}>
                      <h4 className="panel-title">
                        <a role="button" className={"linkToList "+type.class} data-toggle="collapse" data-parent="#accordion" href={"#collapse"+index} aria-expanded="false" aria-controls="collapseOne">
                          <div className="col-xs-8">{type.name}</div>
                          <div className="col-xs-4 compteurs">
                            <div className="row">
                              <div className="col-xs-3" style={{padding:0}}>
                                <i className="icons icon-clock"></i>
                              </div>
                              <div className="col-xs-2 text-center" style={{padding:0}}>
                                {this.state.nbLeadsEnCours[type.name+"_EnRetard"] ? this.state.nbLeadsEnCours[type.name+"_EnRetard"] : 0}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-3" style={{padding:0}}>
                                <i className="icons icon-calendar"></i>
                              </div>
                              <div className="col-xs-2 text-center" style={{padding:0}}>
                                {this.state.nbLeadsEnCours[type.name+"_AVenir"] ? this.state.nbLeadsEnCours[type.name+"_AVenir"] : 0}
                              </div>
                            </div>
                          </div>
                        </a>
                      </h4>
                    </div>
                    <div id={"collapse"+index} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading"+index}>
                      <div className="panel-body">
                        <TypeEnCours leads={leads} typeEnCours={type.name} reloadLeads={this.reloadLeads.bind(this)} onClick={(IDLead) => this.showLead(IDLead)} />                  
                      </div>
                    </div>
                  </div>)
                })}
                </div>

              : <Loader style={{ marginTop: 150 }} />}
              </div>
        </MobileOnlyView>
{/******************* Fin MobileView ******************/}
      </div>
    );
  }
}

export default LeadsAtelier;
