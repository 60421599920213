import React, { Component } from 'react';
import { browserHistory } from 'react-router';

import DashboardVNVO from './DashboardVNVO';
import DashboardAPV from './DashboardAPV';
import Globals from '../Globals';
import Loader from '../Components/Loader';
import Dictionary from '../Dictionnary';

var lodash = require('lodash')

class Dashboard extends Component {

  constructor(props) {

    super(props);
    
    //Si on était sur l'iframe on fait cela pour ne pas avoir page blanche sur le dashboard
    if(localStorage.bypass_iFrame === 'true')
    {
      localStorage.clear();
      window.location.reload();
    }

    this.state = {
      showDashboard: '',
      typesDashboard: [],
      sites: [],
    }
  }

  async componentDidMount() {

    if (localStorage.user_profil === 'Conseiller') {
      browserHistory.push('/leadsAtelier/AffairesEncours/Prise de RDV');
    }

    if(!localStorage.dashboardPrefs)
      localStorage.dashboardPrefs = JSON.stringify({});

    var typesDashboard = [];
    if(localStorage.user_profil === 'BDC' || localStorage.user_profil === 'ChefDesVentes'){
      if(localStorage.access_leadsVNVO === 'true' || localStorage.access_leadsVNVO === undefined || localStorage.access_leadsVNVO === 'undefined')
        typesDashboard.push({type:"VN/VO", label: Dictionary.getUCFirst("VN/VO")});

      if(localStorage.access_leadsApv === 'true')
        typesDashboard.push({type:"APV", label:"APV"});
    }

    if(typesDashboard.length) {
      var dashboardPrefs = JSON.parse(localStorage.dashboardPrefs);
      this.fetchSites();

      this.setState({
        typesDashboard: typesDashboard,
        showDashboard: (dashboardPrefs.type && lodash.find(typesDashboard, {type: dashboardPrefs.type})) ? dashboardPrefs.type : typesDashboard[0].type
      })
    }
  }

  async fetchSites(){
   
    fetch(localStorage.ClientApiURL + '/dashboard/sites' , {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        sites: json,
      })
    })
    .catch(error => {
      console.log(error)
    });
  }
    
  onChangeDashboard(type) {
    var dashboardPrefs = JSON.parse(localStorage.dashboardPrefs);
    dashboardPrefs.type = type;
    localStorage.dashboardPrefs = JSON.stringify(dashboardPrefs);

    this.setState({
      showDashboard: type
    })
  }

  render() {
    
    if(this.state.typesDashboard.length === 0)
      return null;

    return (
      <div className="container-fluid dashboard">
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <h1>Tableau de bord</h1>
          <div className="button-group" role="group">
            {this.state.typesDashboard.map((type, i) => {
              return <button key={i} type="button" className={this.state.showDashboard == type.type ? "selected" : ""} onClick={this.onChangeDashboard.bind(this, type.type)}>{type.label}</button>
            })}
          </div>
        </div>
        {this.state.sites.length === 0 ? 
          <div className='col-md-12'>
            <Loader style={{ marginTop: 150 }} />
          </div>
        :
          this.state.showDashboard == "APV" ? 
            <DashboardAPV sites={this.state.sites}/>
          :
            <DashboardVNVO sites={this.state.sites}/>
        }
      </div>
    )

  }
}

export default Dashboard;
