import React, {Component} from 'react';
import Globals from '../Globals';

import moment from 'moment'
import InputTime from '../Components/InputTime';
var Datetime = require('react-datetime');

var lodash = require('lodash')

class FormToDoCreate extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      etapes: [],
      _utilisateurs: [],
      utilisateurs: [],
    }
  }

  componentWillMount() {
    this.fetchEtapes();
    this.fetchutilisateurs();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.site !== this.props.site) {
      this.fetchutilisateurs();
    }
  }

  fetchEtapes() {
    this.setState({
      etapes: [
        {
            "IDEtape": 1,
            "Description": "A qualifier"
        },
        {
            "IDEtape": 2,
            "Description": "Découverte"
        },
        {
            "IDEtape": 3,
            "Description": "Essai"
        },
        {
            "IDEtape": 4,
            "Description": "Offre"
        },
        {
            "IDEtape": 5,
            "Description": "Négociation"
        },
        {
            "IDEtape": 6,
            "Description": "Commande"
        }
      ]
    });
  }

  fetchutilisateursBySite() {

    fetch(localStorage.ClientApiURL + '/Utilisateurs?EstActif=true&LoadDetailDroitProfil=false&LoadPlaqueMarques=false', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {

      var utilisateurs = lodash.filter(json, (utilisateur) => {
        return lodash.find(utilisateur.Sites, (site, i) => {
          return parseInt(site.IDSite, 10) === parseInt(this.props.site, 10) && utilisateur.TypeProfilLead === 'Vendeur';
        });
      });

      utilisateurs = lodash.orderBy(utilisateurs, 'Nom');

      this.setState({
        utilisateurs: utilisateurs,
        selected: null
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchutilisateurs() {

    if (localStorage.user_IDUtilisateur == '-1' && localStorage.bypass_iFrame == 'true') {
      this.fetchutilisateursBySite();
      return;
    }

    fetch(localStorage.ClientApiURL + '/utilisateurs/' + localStorage.user_IDUtilisateur + "/manage?id_site=" + this.props.site , {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {

      var utilisateurs = lodash.orderBy(json, 'Nom');

      this.setState({
        utilisateurs: utilisateurs,
        selected: null
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  changeDate(event) {
    var date = event.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    this.props.onChangeValue('todoDate', date);
  }

  isValidDate(current) {
    var yesterday = Datetime.moment().subtract(1, 'day');
    return current.isAfter(yesterday);
  }

  isValidHeure(current) {
    return true;
  }

  changeHeureRelance(event) {
    this.props.onChangeValue('todoHeure', event);
    this.props.onChangeValue('todoHeureFin', moment(event).add(1, 'hours'));
  }

  changeHeureRelanceFin(event) {
    this.props.onChangeValue('todoHeureFin', event);
  }

  changeToVendeur(event) {
    this.props.onChangeValue('todoToVendeur', event.target.checked);
  }

  changeVendeur(event) {
    this.props.onChangeValue('todoVendeur', event.target.value);
  }

  changeEtape(event) {
    this.props.onChangeValue('todoEtape', event.target.value);
    this.props.onChangeValue('todoEtapeText', event.target.options[event.target.selectedIndex].text);
  }

  changeComment(event) {
    this.props.onChangeValue('todoComment', event.target.value);
  }

  getAllUsers(){
    var utilisateurs = lodash.filter(this.state.utilisateurs, (utilisateur) => {
      if (this.props.typeActive){
        if(this.props.typeActive === "VN")
          return utilisateur.EstTypeVN;
        else if(this.props.typeActive === "VO")
          return utilisateur.EstTypeVO;
      }
    });
    return utilisateurs;
  }

  render() {
    return (
      <div style={{ marginBottom: '55px' }}>

        <div className='row'>
          <div className="col-md-10 col-md-offset-1">

            {this.getAllUsers().length ?
            <div>
              <div className="form-group" style={{ marginBottom: '0px' }}>
                <div className="col-md-12" style={{ textAlign: 'left' }}>
                  <input type='checkbox' id='toVendeur' onChange={this.changeToVendeur.bind(this)} />
                  <label className="label-box" htmlFor="toVendeur">{this.props.transfertVendeurLabel ? 'Transférer à un vendeur' : 'Affecter à un vendeur'}</label>
                </div>
              </div>
            </div>
            :
            <div>
              <div className="form-group" style={{ marginBottom: '0px' }}>
                <div className="col-md-12" style={{ textAlign: 'center' }}>
                  <label className="warning">[Aucun vendeur paramétré]</label>
                </div>
              </div>
            </div>
            }

            {this.props.todoToVendeur ?
              <div>

                <div className="form-group">
                  <div className="col-md-12">
                    <select className="form-control" id="vendeur" name="vendeur" onChange={this.changeVendeur.bind(this)} /*defaultValue={this.props.todoVendeur}*/ value={this.props.todoVendeur} required='required'>
                      <option value="">Sélectionner le vendeur</option>
                      {this.getAllUsers().map((utilisateur, i) => {
                          return <option key={i} value={utilisateur.IDUtilisateur}>{utilisateur.Nom}</option>
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group" style={{ marginBottom: '25px' }}>
                  <div className='col-md-12'>
                    <a className='btn btn-default' onClick={this.props.openWeekCalendar} style={{ width: '100%', fontSize: '12px', padding: '6px 0px 4px 0px' }}>Calendrier vendeurs</a>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-md-4">TO DO</label>
                  <div className="col-md-8">
                    <select className="form-control" id="etapes" name="etapes" onChange={this.changeEtape.bind(this)} value={this.props.todoEtape} required="required">
                      <option value=''>Votre étape</option>
                      {this.state.etapes.map((etape, i) => {

                        if (etape.Description === 'Rejet' || etape.Description === 'Abandon')
                          return null;

                        return <option key={i} value={etape.IDEtape}>{etape.Description}</option>
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group" style={{ height: '90px' }}>
                  <div className="col-md-12">
                    <textarea type="text" autoComplete="off" className="form-control" placeholder="Commentaire" style={{ height: '90px' }} value={this.props.todoComment} onChange={this.changeComment.bind(this)} />
                  </div>
                </div>

                <div className="form-group datepicker-center">
                  <label className="col-md-2">Le</label>

                  <div className="col-md-8">
                    <Datetime className="datetime" locale="fr" closeOnSelect={true} timeFormat={false}  isValidDate={this.isValidDate.bind(this)} value={this.props.todoDate} onChange={this.changeDate.bind(this)} />
                  </div>
                  <div className="col-md-2">
                    <i className="icon-calendar icons" style={{ paddingTop: '4px', display: 'block' }}></i>
                  </div>
                </div>

                <div className="form-group datepicker-center">
                  <label className="col-md-2">de</label>
                  <div className="col-md-8">
                    <div className="col-md-12" style={{ padding: '0px' }}>
                      <InputTime value={this.props.todoHeure} onChange={this.changeHeureRelance.bind(this)} SelectStyle={{paddingLeft: '3px', paddingRight: '0px',  boxSizing: 'content-box', height:'19px'}} />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <i className="icon-clock icons" style={{ paddingTop: '6px', display: 'block' }}></i>
                  </div>
                </div>

                <div className="form-group datepicker-center">
                  <label className="col-md-2">à</label>
                  <div className="col-md-8">
                    <div className="col-md-12" style={{ padding: '0px' }}>
                      <InputTime value={this.props.todoHeureFin} onChange={this.changeHeureRelanceFin.bind(this)} SelectStyle={{paddingLeft: '3px', paddingRight: '0px',  boxSizing: 'content-box', height:'19px'}} />
                    </div>
                  </div>
                  <div className="col-md-2">
                    <i className="icon-clock icons" style={{ paddingTop: '6px', display: 'block' }}></i>
                  </div>
                </div>

              </div>
            : null}

          </div>
        </div>

      </div>
    );
  }
}

export default FormToDoCreate;
