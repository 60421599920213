import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import { Router, Route, browserHistory, IndexRedirect } from 'react-router'
import ReactDOM from 'react-dom';
import App from './App';
import Login from './Login';
import Logout from './Logout';
import Dashboard from './Dashboard/Dashboard';
import NotFound from './NotFound';

import LeadCreate from './LeadCreate/LeadCreate';
import Leads from './Leads/Leads.js';

import LeadsAtelier from './LeadsAtelier/LeadsAtelier.js';
import LeadsAccessoires from './LeadsAccessoires/LeadsAccessoires.js';

import Prospects from './Pages/Prospects.js';
import Prospect from './Pages/Prospect.js';
import Vehicules from './Pages/Vehicules.js';
import Vehicule from './Pages/Vehicule.js';

import IframeV5Stocks from './Pages/IframeV5Stocks.js';
import IframeV5Offres from './Pages/IframeV5Offres.js';

import VehiculeParcForm from './Pages/VehiculeParcForm.js';

import Reporting from './Pages/Reporting.js';
import ReportingNissan from './Pages/ReportingNissan.js';
import Rapport from './Pages/Rapport.js';
import RapportCreate from './Pages/RapportCreate.js';
import RapportAtelier from './LeadsAtelier/RapportAtelier.js';
import RapportAccessoires from './LeadsAccessoires/RapportAccessoires.js';
import Actions from './Pages/Actions.js';
import Scheduler from './Pages/Scheduler.js';
import SchedulerIframe from './Pages/SchedulerIframe.js';

import RapportEdit from './Pages/RapportEdit.js';

import Holidays from './Pages/Holidays.js';
import PeopleHolidays from './Pages/PeopleHolidays.js';
import PeopleHolidaysAdd from './Pages/PeopleHolidaysAdd.js';
import PeopleHolidaysEdit from './Pages/PeopleHolidaysEdit.js';

import AppelSortantIframe from './LeadCreate/AppelSortantIframe';

import ReactGA from 'react-ga';
import GestionVendeurs from "./Pages/GestionVendeurs";
import ObjectifsVendeurs from "./Pages/ObjectifsVendeurs";

import Campagnes from './Pages/Campagnes/Campagnes.js';
import CampgneFormProcess from './Pages/Campagnes/CampgneFormProcess.js';
import CampgneSynthese from './Pages/Campagnes/CampgneSynthese.js';

import Offre from './Pages/Offre.js';
import IframeSendMessage from './Pages/IframeSendMessage';
import IframeHistoriqueMessages from './Pages/IframeHistoriqueMessages';
import IframeTypesProduction from './Pages/IframeTypesProduction';
import moment from 'moment';
var Auth = require('./Auth').default;

function requireAuth(nextState, replace) {
  if (!Auth.loggedIn()) {
    replace({
      pathname: '/login',
      state: { nextPathname: nextState.location.pathname, nextSearch: nextState.location.search }
    })
  }
  else {
    //Si Nissan et connexion d'avant aujourd'hui, on déconnecte
    if(localStorage.IsNissan === "true" && (!localStorage.last_login || moment(localStorage.last_login).format('YYYY/MM/DD') !== moment().format('YYYY/MM/DD'))) {
      replace({
        pathname: '/logout',
        state: { nextPathname: nextState.location.pathname }
      })
    }
  }
}

ReactDOM.render((
  <Router history={browserHistory}>
    <Route path="/" component={App} onChange={(prevState, nextState)=> {

        if (nextState.routes[1] && (nextState.routes[1].path !== "/leads/:type" && nextState.routes[1].path !== "/leadsAtelier/:type")) {
          ReactGA.pageview(nextState.routes[1].path, nextState.routes[1].title);
        }
        else {
          ReactGA.pageview(window.location.pathname, nextState.routes[1].title);
        }

      }}>
      <Route path="login" component={Login} />
      <Route path="logout" component={Logout} />
      <Route path="/reporting/nissan" component={ReportingNissan} />
      <Route path="/reporting" component={Reporting} />
      <IndexRedirect to="dashboard" />
      <Route path="/dashboard" component={Dashboard} onEnter={requireAuth} title="Tableau de bord" />
      <Route path="/lead/create" component={LeadCreate} onEnter={requireAuth} title="Création d'un lead" />
      <Route path="/leads/:type" component={Leads} onEnter={requireAuth} />
      <Route path="/leadsAtelier/:type" component={LeadsAtelier} onEnter={requireAuth} />
      <Route path="/leadsAtelier/:type(AffairesEncours)/:typeEnCours(/:IDLead)" component={LeadsAtelier} onEnter={requireAuth} />
      <Route path="/leadsAccessoires/:type" component={LeadsAccessoires} onEnter={requireAuth} />
      <Route path="/leadsAccessoires/:type(AffairesEncours)/:typeEnCours(/:IDLead)" component={LeadsAccessoires} onEnter={requireAuth} />

      <Route path="/prospect/:prospectId/vehicule/create" component={VehiculeParcForm} onEnter={requireAuth} />

      <Route path="/prospect/:prospectId" component={Prospect} onEnter={requireAuth} title="Fiche prospect" />
      <Route path="/prospect" component={Prospects} onEnter={requireAuth} title="Recherche de prospect" />
      <Route path="/vehicule/:vehiculeId" component={Vehicule} onEnter={requireAuth} />
      <Route path="/vehicule" component={Vehicules} onEnter={requireAuth} />
      <Route path="/rapportAtelier/:IDLead" component={RapportAtelier} onEnter={requireAuth} />
      <Route path="/rapportAccessoires/:IDLead" component={RapportAccessoires} onEnter={requireAuth} />
      <Route path="/rapport/create/:IDProspect" component={RapportCreate} onEnter={requireAuth} />
      <Route path="/rapport/:IDLead" component={Rapport} onEnter={requireAuth} />
      <Route path="/rapport/:IDLead/edit" component={RapportEdit} onEnter={requireAuth} />
      <Route path="/actions" component={Actions} onEnter={requireAuth} title="Actions à faire" />
      <Route path="/agenda" component={Scheduler} onEnter={requireAuth} />

      <Route path="/stocks/:type" component={IframeV5Stocks} onEnter={requireAuth} />
      <Route path="/offres" component={IframeV5Offres} onEnter={requireAuth} />
      
      <Route path="/offre/:id" component={Offre} onEnter={requireAuth} />

      <Route path="/holidays" component={Holidays} onEnter={requireAuth} />
      <Route path="/holidays/:IDPeople" component={PeopleHolidays} onEnter={requireAuth} />
      <Route path="/holidays/:IDPeople/add" component={PeopleHolidaysAdd} onEnter={requireAuth} />
      <Route path="/holidays/:IDPeopleHoliday/edit" component={PeopleHolidaysEdit} onEnter={requireAuth} />

      <Route path="/campagnes" component={Campagnes} onEnter={requireAuth} />
      <Route path="/campagnes/:IDCampagne/synthese" component={CampgneSynthese} onEnter={requireAuth} />
      <Route path="/campagnes/:IDCampagne" component={CampgneFormProcess} onEnter={requireAuth} />

      <Route path="/lead/iframe/create" component={LeadCreate} />
      <Route path="/iframe/holidays/:IDPeople" component={PeopleHolidays} />
      <Route path="/iframe/holidays/:IDPeople/add" component={PeopleHolidaysAdd} />
      <Route path="/iframe/holidays/:IDPeopleHoliday/edit" component={PeopleHolidaysEdit} />
      <Route path="/iframe/agenda" component={SchedulerIframe} />
      <Route path="/iframe/rapport/:IDRapport" component={AppelSortantIframe} />
      <Route path="/gestionVendeurs" component={GestionVendeurs} onEnter={requireAuth}/>
      <Route path="/objectifsVendeurs" component={ObjectifsVendeurs} onEnter={requireAuth}/>

      <Route path="/iframe/sendMessage" component={IframeSendMessage}/>
      <Route path="/iframe/historiqueMessages" component={IframeHistoriqueMessages}/>

      <Route path="/iframe/typesProduction" component={IframeTypesProduction}/>

      <Route path="*" component={NotFound}/>
    </Route>
  </Router>
), document.getElementById('root'));
