import React, { Component } from 'react';

import Globals from '../Globals';

import moment from 'moment'
import ListMessages from '../Components/Prospect/ListMessages';

class IframeHistoriqueMessages extends Component {

    constructor(props) {
        super(props);

        moment.locale('fr');

        this.state = {
          nbMessages: -1
        }

        if(!localStorage.user_IDClient || !localStorage.user_login || !localStorage.user_password){
            localStorage.bypass_iFrame = true;
            localStorage.user_IDClient = this.props.location.query.idclient;
            localStorage.user_login = this.props.location.query.login;
            localStorage.user_password = this.props.location.query.password;
            localStorage.token = 'iframe'
            localStorage.version = Globals.VERSION

            localStorage.ClientApiURL = Globals.API_URL;
            var parts = window.location.hostname.split('.');
            if (parts[0] === 'dev' || parts[1] === 'dev' || parts[0] === 'preprod' || parts[1] === 'preprod' || parts[0] === 'localhost') {
              localStorage.ClientApiURL = Globals.API_URL_DEV;
            }

            if (parts[0] === 'devagri' || parts[1] === 'devagri' ) {
              localStorage.ClientApiURL = Globals.API_URL_DEV_AGRI;
            }

            if (parts[0] === 'devv5' || parts[1] === 'devv5' ) {
              localStorage.ClientApiURL = Globals.API_URL_DEV_V5;
            }
        }
    }

    render() {

        return (
            <div style={{maxWidth:'100vw'}}>
              <div className="iframe-title">Historique des messages</div>
              <div style={{display: this.state.nbMessages > 0 ? 'block':'none'}}>
                <ListMessages IDProspect={this.props.location.query.idprospect} onLoad={(messages) => this.setState({nbMessages: messages.length})}/> 
              </div>
              {this.state.nbMessages === -1 ? 
                <div className="center-div" style={{paddingTop:'100px'}}>
                  <i className="fa fa-refresh fa-spin fa-2x fa-fw"></i>
                  <br/><br/>Chargement en cours...
                </div>
              : this.state.nbMessages === 0 ? 
                <div className="center-div" style={{paddingTop:'100px'}}>
                  <br/><br/>Aucun message
                </div>
              : null}
            </div>
        );
    }
}

export default IframeHistoriqueMessages;
