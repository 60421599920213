import React from 'react';
import { withRouter } from 'react-router'
import Loader from './Components/Loader';
import Globals from './Globals';
import {SetAppStyleAndTitle} from './Utils'

import './Login.css';
var Auth = require('./Auth').default;

const Login = withRouter(
  class LoginClass extends React.Component {

    constructor(props) {
      super(props)

      this.state = {
        error: false,
        errorEnv: false,
        fetchInProgress: false,
        displayForm: false,
        displayIDClient: true,

        idclient: this.props.location.query.idclient ? this.props.location.query.idclient : '',
        login: this.props.location.query.login ? this.props.location.query.login : '',
        pass: this.props.location.query.pass ? this.props.location.query.pass : '',
      }
    }

    componentDidMount() {
      if (Auth.loggedIn() && this.props.location.query.autologin !== "true") {
        this.props.router.replace('/dashboard')
      }

      if (this.props.location.query.autologin == "true") {
        if (Auth.loggedIn()) {
          Auth.logout()
        }
        this.fetchLogin();
        return;
      }

      var parts = window.location.hostname.split('.');
      var subdomain = parts.shift();
      console.log(subdomain);
      console.log(parts[0]);
      
      if ((subdomain !== 'localhost' && subdomain !== 'devagri' && subdomain !== 'devv5') && parts[0] !== 'dev') {
        if (window.location.protocol == 'http:') {
          window.location = 'https://' + window.location.host + window.location.pathname;
        }
      }

      // localhost = dev
      // lead = potential prod
      // 192.168.101.82 = ip local
      if (subdomain !== 'localhost' && subdomain !== 'lead' && subdomain !== '192') {

        var ApiUrl = Globals.API_URL;

        if (subdomain === 'dev' || subdomain === 'preprod' || parts[0] === 'preprod' || parts[0] === 'dev') {
          ApiUrl = Globals.API_URL_DEV;
        }

        if (subdomain === 'devagri' || parts[0] === 'devagri') {
          ApiUrl = Globals.API_URL_DEV_AGRI;
        }

        if (subdomain === 'devv5' || parts[0] === 'devv5') {
          ApiUrl = Globals.API_URL_DEV_V5;
        }

        fetch(ApiUrl + '/Tools?sdomain=' + subdomain, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
          },
        })
        .then((response) => {
          if (response.status === 404) {
            this.setState({errorEnv: true});
          }
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then(json => {
          if(json.BDGp.indexOf("BDGpNi") != -1) {
            localStorage.style = "nissan";
            localStorage.IsNissan = "true";
          }
          else if(json.ProduitDescription === "MAGIR")
            localStorage.style = "magir";

          SetAppStyleAndTitle();

          this.setState({
            displayForm: true,
            displayIDClient: false,
            idclient: json.IDClient,
          });
        })
        .catch((error) => {
          console.log(error)
        });


      }
      else {
        this.setState({displayForm: true});
      }
    }

    fetchLogin() {

      this.setState({fetchInProgress: true});

      const IDClient = this.state.idclient
      const login = this.state.login
      const password = this.state.pass
      const currentStyle = localStorage.style;

      Auth.login(IDClient, login, password, (loggedIn) => {

        this.setState({fetchInProgress: false});

        if (!loggedIn)
          return this.setState({ error: true })

        if(localStorage.style !== currentStyle) //Changement de style après connexion (quand on se connecte depuis une page de connexion générique)
          SetAppStyleAndTitle();

        const { location } = this.props

        if (location.state && location.state.nextPathname) {
          this.props.router.replace(location.state.nextPathname+location.state.nextSearch)
        } else if(this.props.location.query.searchbytel == "true" && this.props.location.query.tel != undefined) {
            this.props.router.replace('/prospect?phone='+this.props.location.query.tel)
        }else{
          this.props.router.replace('/')
        }
      })
    }

    handleSubmit(event) {
      event.preventDefault();

      this.fetchLogin();
    }

    onChangeIDClient(event) {
      this.setState({idclient: event.target.value});
    }

    onChangeLogin(event) {
      this.setState({login: event.target.value});
    }

    onChangePass(event) {
      this.setState({pass: event.target.value});
    }

    render() {
      return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 col-lg-offset-5 col-md-4 col-md-offset-4 col-sm-4 col-sm-offset-4 col-xs-12">

              {this.state.displayForm ? 
                localStorage.style === "nissan" ?
                  <div className="LoginLogo"> 
                    <img src="./img/logo_nissan.png" role="presentation" />
                    <div className="bg-nissan" style={{padding:'5px', marginTop:'30px', fontWeight:'500', fontSize:'1.2em', fontFamily:'Verdana'}}>
                      SMIT Lead
                    </div>
                  </div>
                  :
                  <div className="LoginLogo">
                    <img src={localStorage.style === "magir" ? "./img/Logo_Magir_couleur.png" : "./img/logo_eSeller-lead_couleur.png"} role="presentation" />
                  </div>
              :null}

              {this.state.error && (
                <div className="alert alert-danger" role="alert">
                  Identifiants invalides
                </div>
              )}

              {this.state.errorEnv && (
                <div className="alert alert-danger text-center" role="alert">
                  Environnement inconnu
                </div>
              )}

              {this.state.displayForm ?
                <form onSubmit={this.handleSubmit.bind(this)} className="form-horizontal">
                  <div className="form-group">
                    <div className="col-sm-10 col-sm-offset-1">
                      <input type={this.state.displayIDClient ? 'text' : 'hidden'} className="form-control" placeholder="Identifiant client" value={this.state.idclient} onChange={this.onChangeIDClient.bind(this)} />
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-sm-10 col-sm-offset-1">
                      <input type="text" className="form-control" placeholder="Login" value={this.state.login} onChange={this.onChangeLogin.bind(this)} />
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-sm-10 col-sm-offset-1">
                      <input type="password" className="form-control" placeholder="Mot de passe" value={this.state.pass} onChange={this.onChangePass.bind(this)} />
                    </div>
                  </div>

                  <div className="form-group ">
                    <div className="col-sm-10 col-sm-offset-1 text-center">
                      <button type="submit" className={"btn btn-default btn-lg " + (localStorage.style === "nissan" ? "bg-nissan" : "")} disabled={this.state.fetchInProgress}>
                        {this.state.fetchInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null}
                        Connexion
                      </button>
                    </div>
                  </div>

                </form>
            :
              !this.state.errorEnv ?
                <div>
                  <Loader style={{ marginTop: 55 }} />
                </div>
              : null
            }
            </div>
          </div>
        </div>

      )
    }
  }
)

export default Login;
