import React, { Component } from 'react';

import { Link } from 'react-router';

import moment from 'moment'
import PastilleVendeur from '../../Components/PastilleVendeur';
import TableRowComment from '../../Components/TableRowComment';
import TableRowDateRelance from '../../Components/TableRowDateRelance';
import TableRowSite from '../../Components/TableRowSite';
import WelcomeProMail from '../WelcomeProMail';
import Tooltip from "rc-tooltip";

var lodash = require('lodash')

class ListRapports extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');
  }

  render() {

    if (!this.props.rapports) {
      return null;
    }

    var rapports = this.props.rapports;
    rapports.sort(function(a, b) {
      if (moment(a.InfoCreation.DateH) > moment(b.InfoCreation.DateH))
        return -1;
      return 1;
    });

    return (
      <div>

        {rapports.length ?
          <table className='table table-eseller-lead table-striped table-hover table-condensed'>
            <thead>
              <tr>
                <th>Site</th>
                <th>Suivi</th>
                <th>Date</th>
                <th>Origine</th>
                <th>Campagne</th>
                <th>Type contact</th>
                <th>Relance</th>
                <th>Action à faire</th>
                <th>Étape</th>
                <th className='text-center'>Commentaire</th>
                <th className='text-center'>Etat</th>
                <th className='text-center'></th>
              </tr>
            </thead>
            <tbody>
              {rapports.map((rapport, j) => {
                if (this.props.filter && this.props.filter !== '0') {
                  if (this.props.filter === 'atelier' && !rapport.EstAtelier) {
                    return null;
                  }

                  if (this.props.filter == 'appelSortant' && !rapport.EstAppelSortant) {
                    return null;
                  }

                  if (((this.props.filter !== 'atelier') && (this.props.filter !== 'appelSortant')) && (!rapport.Affaire || (parseInt(this.props.filter, 10) !== parseInt(rapport.Affaire.IDAffaire, 10)))) {
                    return null;
                  }

                }

                return <tr key={j}>
                  <td>
                    <TableRowSite Site={rapport.Site}/>
                  </td>
                  <td>
                    {rapport.PremiereActionEntrante && !rapport.InfoCreation.Utilisateur.EstPasserelle ?
                      <PastilleVendeur utilisateur={rapport.InfoCreation.Utilisateur} />
                    :
                      <PastilleVendeur utilisateur={rapport.SuiviPar} />
                    }
                  </td>
                  <td>{moment(rapport.ActionFaite_DO.DateHLead).format('DD/MM/Y HH:mm')}</td>
                  <td>
                    {rapport.ActionFaite_DO.OrigineAction ? rapport.ActionFaite_DO.OrigineAction.Origine : null}
                  </td>
                  <td>{rapport.Campagne ? rapport.Campagne.Nom : null}</td>
                  <td>{rapport.ActionFaite_DO.TypeContact ? rapport.ActionFaite_DO.TypeContact.Description : null}</td>
                  <td>
                    <TableRowDateRelance date={rapport.ActionAFaire_TODO.DateHProchaineEtape ? rapport.ActionAFaire_TODO.DateHProchaineEtape : rapport.ActionAFaire_TODO.DateHRelance} />
                  </td>
                  <td>{rapport.ActionAFaire_TODO.ActionAFaire ? rapport.ActionAFaire_TODO.ActionAFaire.Description : null}</td>
                  <td>{rapport.ActionAFaire_TODO.Etape ? rapport.ActionAFaire_TODO.Etape.Description : null}</td>
                  <td className='text-center'>
                    <TableRowComment comment={rapport.ActionFaite_DO.Commentaire} />
                    <WelcomeProMail rapport={rapport} />
                  </td>
                  <td className='text-center'>
                    {rapport.ActionAFaire_TODO.Etat === "Termine" ? <img src="/img/TABLER ETAT.svg" role='presentation' /> :
                      ((rapport.SuiviPar.Profil && rapport.SuiviPar.TypeProfilLead !== localStorage.user_profil && localStorage.user_profil != 'ChefDesVentes') ?
                        <span>A traiter</span>
                      :
                        <span>
                        {rapport.ActionFaite_DO.TypeContact.Description === 'Appel Sortant' ?
                          <Link to={'/lead/create?EstAppelSortant=true&prospect_id='+rapport.Prospect.IDProspect+'&IDLead='+rapport.IDLead}>
                            A traiter
                          </Link>
                        :
                          <Link to={'/rapport'+ (!rapport.Affaire || !rapport.Affaire.OrigineAction || !rapport.Affaire.Type || rapport.Affaire.Type === 'VN' || rapport.Affaire.Type === 'VO' ? '' : 'Atelier') +'/'+rapport.IDLead}>
                            A traiter
                          </Link>
                        }
                        </span>
                      )
                    }
                  </td>

                  <td>
                    {localStorage.user_profil === 'BDC' && (rapport.Affaire && rapport.Affaire.Type != 'Atelier') && ((rapport.ActionAFaire_TODO.Etat === "Termine" && localStorage.esellerLeadEditRapportCloture == 'true') || (rapport.ActionAFaire_TODO.Etat !== "Termine")) ?
                      <Tooltip overlay={<div>Modifier</div>}>
                        <Link to={'/rapport/'+rapport.IDLead+'/edit'}><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                      </Tooltip>
                    : null}
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        : null}
      </div>
    );
  }
}

export default ListRapports;
