import React, { Component } from 'react';

import { Link } from 'react-router';

import { browserHistory } from 'react-router';

import Globals from '../Globals';

import FormOrigine from './FormOrigine.js';

import TableRowSite from '../Components/TableRowSite.js';

import FormVO from './FormVO.js';
import FormVN from './FormVN.js';
import FormAtelier from './FormAtelier.js';
import FormAutres from './FormAutres.js';
import FormVDL from './FormVDL';
import FormAccessoires from './FormAccessoires.js';
import FormAppelSortant from './FormAppelSortant.js';

import FormSocieteSiteVN from './FormSocieteSiteVN.js';
import FormSocieteSiteAppelSortant from './FormSocieteSiteAppelSortant.js';
import FormSocieteSiteVO from './FormSocieteSiteVO.js';
import FormSocieteSiteAtelier from './FormSocieteSiteAtelier.js';
import FormSocieteSiteVDL from './FormSocieteSiteVDL.js';

import FormToDoCreate from './FormToDoCreate.js';
import FormToDoCreateAtelierAutres from './FormToDoCreateAtelierAutres.js';
import FormToDoCreateAtelierAutresModeDegrade from './FormToDoCreateAtelierAutresModeDegrade.js';

import FormRelance from './FormRelance.js';
import Scheduler from '../Pages/Scheduler.js';
import FormSeller from './FormSeller.js'

import VehiculeHistoriqueAtelier from '../Components/VehiculeHistoriqueAtelier';
import VehiculeParcForm from '../Pages/VehiculeParcForm.js';

import ModalSendMessage from '../Components/ModalSendMessage';
import ModalHistoriqueMessages from '../Components/ModalHistoriqueMessages';
import AffectationSiteModal from '../Components/Prospect/AffectationSiteModal';

import Loader from '../Components/Loader';

import moment from 'moment';

import ProspectFormEdit from '../Components/Prospect/FormEdit';
import ProspectFormCreate from '../Components/Prospect/Form';

import SkyLight from 'react-skylight';

import SweetAlert from 'react-bootstrap-sweetalert'; // http://djorg83.github.io/react-bootstrap-sweetalert/
import ButtonRGPD from '../Components/ButtonRGPD';
import Tooltip from "rc-tooltip";
import Dictionary from '../Dictionnary';
var lodash = require('lodash')
var Auth = require('../Auth').default;

class LeadCreate extends Component {

    constructor(props) {

        super(props);

        moment.locale('fr');

        var typesCreate = [];
        typesCreate["VO"] = true;
        typesCreate["VN"] = true;
        typesCreate["Atelier"] = true;
        typesCreate["Load"] = false;
        typesCreate["warning"] = false;

        typesCreate["leads"] = [];

        var type = ((this.props.location.query.origin === 'CallWin') ? 'VO' : 'VN');
        if (this.props.location.query.type) {
          type = this.props.location.query.type;
        }
        else if(localStorage.IsVDL === 'true'){
          type = 'VDL';
        }


    this.state = {
      type: this.props.location.query.EstAppelSortant && this.props.location.query.EstAppelSortant === "true" ? '' : type,
      canal: 'Téléphone',
      marque: '',
      societe: 0,
      site: 0,
      campagne: 0,
      origine: 0,
      origine_famille: 0,
      origine_sousfamille: 0,
      choiceOrigine: 0,

      commentVN: '',
      commentVO: '',
      commentAtelier: '',
      commentAutres: '',
      commentVDL: '',
      commentAccessoires: '',

      todoEtape: 0,
      todoEtapeText: '',
      todoDate: moment(),
      todoHeure: null,
      todoHeureFin: null,
      todoToVendeur: false,
      todoVendeur: 0,
      todoComment: '',

      relance: 0,
      relanceAction: '',
      relanceCommentaire: '',
      relanceDate: moment(),
      todoDateHeureFin: null,
      relanceHeure: null,

      prospectId: null,
      prospect: null,
      vehicules: [],
      selectedVehicule: null,
      VehiculeHistoriqueAtelier: null,
      nbVehiculesLoaded: 0,

      errors: {},
      errorsMessages: [],

      fetchCreateInProgress: false,
      successCreate: false,
      errorCreate: false,
      errorCreateMessage: '',
      successCreateMessage: 'Création du lead avec succès !',

      sJwt: null,

      typesCreate : typesCreate,
      affairesProspect : null,

      ToVendeurCallwinOK: false,
      ToVendeurCallwinNOK: false,
      ToVendeurCallwinOKPotcommun: false,

      utilisateurs: null,
      historiquesAteliers: [],

      origines: [],
      EstAppelSortant: this.props.location.query.EstAppelSortant && this.props.location.query.EstAppelSortant === "true" ? true : false,
      appelSortantCreateLead: false,
      appelSortantComment: '',
      appelSortantRelanceForm: false,
      typeAppelSortant: '',
      disabledEditOrigine: false,

      UrlMecaplanning : '',
      lockProspectMecaplanning : true,

      CallcenterTypeCreateLead: null,
      leadAppelSortant: null,

      campagneFile: null,

      appelSortantDisableRelance: true,
      appelSortantDisableCloture: true,
      codeRaisonClotureAppelSortant: null,

      lastRapport: null,
      nbMessages:0,

      userInfoLoaded: false,
      badIDClient: false,
      badLogin: false,

      sendDataToParentWindow: false,
    };

    /**** Si on passe par l'iframe ****/
    if (this.props.location.query.idclient) {
      if(localStorage.bypass_iFrame || (!localStorage.user_IDClient || !localStorage.user_login || !localStorage.user_password || !localStorage.user_profil)){
        localStorage.bypass_iFrame = true;
        localStorage.user_IDClient = this.props.location.query.idclient;
        localStorage.user_login = this.props.location.query.login;
        localStorage.user_password = this.props.location.query.password;
        localStorage.access_affectationUtilisateur = false;
        localStorage.access_creationRelance = false;
        localStorage.user_IDUtilisateur = this.props.location.query.idutilisateur ? this.props.location.query.idutilisateur : -1;
        localStorage.token = 'iframe'
        localStorage.version = Globals.VERSION
        localStorage.eSellerLeadAtelierMailRapport = 'true'
        localStorage.CodePays = this.props.location.query.codepays
        localStorage.CodeLangue = this.props.location.query.codelangue

        localStorage.ClientApiURL = Globals.API_URL;
        var parts = window.location.hostname.split('.');
        if (parts[0] === 'dev' || parts[1] === 'dev' || parts[0] === 'preprod' || parts[0] === 'localhost') {
          localStorage.ClientApiURL = Globals.API_URL_DEV;
        }

        if (parts[0] === 'devagri' || parts[1] === 'devagri') {
          localStorage.ClientApiURL = Globals.API_URL_DEV_AGRI;
        }

        if (parts[0] === 'devv5' || parts[1] === 'devv5') {
          localStorage.ClientApiURL = Globals.API_URL_DEV_V5;
        }

        if (this.props.location.query.NomApplication === "Honda e-seller") {
          localStorage.ClientApiURL = Globals.API_URL_HONDA;
        }
      }
    }
  }

    async componentDidMount() {
        var prospectId = this.props.location.query.prospect_id;
        if (prospectId) {
            this.setState({prospectId: prospectId}, () => {
                this.fetchProspect();
            });
        }

        if (localStorage.bypass_iFrame === "true") {
          this.setState({
            todoVendeur: localStorage.user_IDUtilisateur,
            societe: this.props.location.query.IDPlaque ? this.props.location.query.IDPlaque : 0,
            site: this.props.location.query.IDSite ? this.props.location.query.IDSite : 0,
          })
          if(this.props.location.query.idclient) {
            this.fetchUserInfo();
          }
        }

        if (this.props.location.query.origin === 'CallWin') {
            var newState = {
              canal: 'Téléphone'
            };
            if(this.props.location.query.forcepotcommun === "1"){
              newState = {
                ToVendeurCallwinNOK: true,
                ToVendeurCallwinOK: false
              }
            }
            this.setState(newState)
            this.fetchConfigCreateLeadCallcenter();
            // search prospect with this.props.location.query.calling
            this.fetchSearchProspectWelcompePro(this.props.location.query.calling)
        }
        else if(this.props.location.query.idclient) {
          await this.fetchUserInfo();
        }

        if (this.state.EstAppelSortant && this.props.location.query.IDLead) {
            this.initAppelSortantRelance();
        }
    }

    fetchConfigCreateLeadCallcenter() {
      fetch(localStorage.ClientApiURL + '/info', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(json => {
        localStorage.eSellerLeadAtelierMailRapport = json.eSellerLeadAtelierMailRapport;
        localStorage.IsAgri = json.IsAgri;
        localStorage.IsVDL = json.IsVDL;
        localStorage.DPOContactEnabled = json.DPOContactEnabled;
        this.setState({
          CallcenterTypeCreateLead: json.esellerLeadCallcenterTypeCreateLead ? json.esellerLeadCallcenterTypeCreateLead : '',
        }, () => {
          // search origin with this.props.location.query.called
          this.fetchConfigWelcomePro(this.props.location.query.called);
        })
      })
      .catch((error) => {
        console.log(error)
      });
    }

    async fetchUserInfo() {
      await fetch(localStorage.ClientApiURL + '/info', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(json => {
        localStorage.IsAgri = json.IsAgri;
        localStorage.IsVDL = json.IsVDL;
        localStorage.IsMoto = json.IsMoto;
        localStorage.DPOContactEnabled = json.DPOContactEnabled;
        this.setState({
          userInfoLoaded: true
        });
      })
      .catch((error) => {
        console.log(error)
      });
    }

    changeToVendeurCallwinOK(event) {
      var checked = event.target.checked;

      this.setState({
        ToVendeurCallwinOK: checked,
        ToVendeurCallwinNOK: false,
      })
    }

    changeToVendeurCallwinNOK(event) {
      var checked = event.target.checked;

      this.setState({
        ToVendeurCallwinOK: false,
        ToVendeurCallwinNOK: checked,
      })
    }

  initAppelSortantRelance() {
    fetch(localStorage.ClientApiURL + '/Leads/' + this.props.location.query.IDLead, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {

      this.setState({
        campagne: json.Campagne.IDCampagne,
        societe: json.Site.Plaque.IDPlaque,
        site: json.Site.IDSite,
        defaultOrigine: json.ActionFaite_DO.OrigineAction.IDOrigineAction,
        campagneFile: json.Campagne.File,
        leadAppelSortant: json,
      }, () => {
        this.setState({ 'disabledEditOrigine' : true,  });
      });
    })
  }

  fetchSearchProspectWelcompePro(phone) {
    fetch(localStorage.ClientApiURL + '/Prospects?phone=' + phone.trim(), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        prospects: json,
      })
    })
    .catch((error) => {
      console.log(error)
    });
  }

    fetchConfigWelcomePro(phone) {
      fetch('https://emailparser.selectup.com/api/mailboxInfos/' + phone.trim(), {
          method: 'GET',
      })
      .then((response) => {
          if (!response.ok) {
              throw Error(response.statusText);
          }
          return response.json();
      })
      .then(json => {
          this.setState({
              societe: json.plaque,
              site: json.site,
              defaultOrigine: json.action,
          }, () => {
            if (this.state.CallcenterTypeCreateLead === '') {
              this.fetchUtilisateurs();
            }
          })
      })
      .catch((error) => {
          console.log(error)
      });
  }

  fetchMecaplanningToken() {

    if(!this.state.prospect){
      return;
    }

    if(!this.state.prospectId){
      var pr = this.state.prospect.Prive.replace(/\./g,'').replace(/\ /g, '');
      if(this.state.prospect.Nom && this.state.prospect.Prenom && pr.length === 10){
        this.setState({ lockProspectMecaplanning : false });
      }
      else{
        this.setState({ lockProspectMecaplanning : true });
        return;
      }
    }

    if (!this.state.site) {
      return;
    }

    let body = JSON.stringify({
      "IDSite": this.state.site
    });

    fetch(localStorage.ClientApiURL + '/WidgetTokens', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: body
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        sJwt: json.sJWT,
      }, () =>{
        this.getUrlMecaplanning();
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

fetchProspect() {
    fetch(localStorage.ClientApiURL + '/Prospects/' + this.state.prospectId, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
        },
    })
    .then((response) => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.json();
    })
    .then(json => {
      this.setState({
        prospect: json,
        vehicules: [],
        lockProspectMecaplanning : false,
      }, () => {
        if (this.state.prospect.LiensVehicules) {
          if (this.state.prospect.LiensVehicules.length === 1) {
            this.setState({
              selectedVehicule: this.state.prospect.LiensVehicules[0].IDVehicule
            })
          }
          if(this.state.sendDataToParentWindow) //Si on doit envoyer les infos à la fenêtre parent on a besoin de tous les véhicules donc on charge tout.
            this.loadVehicules(this.state.prospect.LiensVehicules.length)
          else //Sinon on en charge que 10 et on affiche un bouton "afficher plus de véhicules"
            this.loadVehicules(10);
        }
        if(this.state.sendDataToParentWindow && (!this.state.prospect.LiensVehicules || this.state.prospect.LiensVehicules.length === 0)) { //Si on doit envoyer les infos prospect à la fenêtre parent et que le prospect n'a pas de véhicule, c'est le moment
          this.setState({
            sendDataToParentWindow: false
          })
          this.sendDataToParentWindow();
        }

        if(localStorage.bypass_iFrame !== "true" && !this.props.location.query.IDSite && json.SiteAffectation &&
            (
              (this.state.type === "VN" && json.SiteAffectation.EstVN === true) ||
              (this.state.type === "VO" && json.SiteAffectation.EstVO === true)
            )
          ){
          var societe = json.SiteAffectation.Plaque.IDPlaque;
          var site = json.SiteAffectation.IDSite;
          this.setState({
            societe: societe,
            site: site,
          }, () => {
            if (this.props.location.query.origin === 'CallWin' && this.state.CallcenterTypeCreateLead === '') {
              this.fetchUtilisateurs();
            }
          });
        }
      });
      this.fetchAffaires();
      this.fetchRapports();
       /* Afin de connaître le nombre de messages dans l'historique, on doit charger la liste des messages.
        * Or cette liste est inclue dans une modal, et le contenu des modals n'est chargé qu'à l'ouverture de celle-ci.
        * La modal est donc mise dans un <div> caché afin de pouvoir l'afficher par défaut et charger la liste.
        * Le <div> caché doit être affiché lorsqu'on veut voir la modal (voir fonction openModal())
        * Le chargement du contenu des modals est effectué dès le chargement de la page dans la version 0.5.0 de react-skylight
        * ce qui pourrait résoudre ce problème */ 
      if(localStorage.ModuleEmailSMS && localStorage.ModuleEmailSMS === 'true')
        this.refs.modalHistoriqueMessages.show();
    })
    .catch((error) => {
      console.log(error)
    });
  }


fetchHistoriqueAteliers() {
    fetch(localStorage.ClientApiURL + '/Prospects/' + this.state.prospectId + '/historiquesateliers', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
        },
    })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {

            var historiquesAteliers = json.sort(function(a, b) {
                if (moment(a.Date) > moment(b.Date))
                    return -1;
                return 1;
            });

            var societe = this.state.prospect.SiteAffectation ? this.state.prospect.SiteAffectation.Plaque.IDPlaque : '';
            var site = this.state.prospect.SiteAffectation ? this.state.prospect.SiteAffectation.IDSite : '';

            if (this.state.EstAppelSortant && this.props.location.query.IDLead) {
              societe = this.state.societe;
              site = this.state.site;
            }

            this.setState({
              historiquesAteliers: historiquesAteliers,
                societe: societe,
                site: site,
            }, () => {
                //console.log(this.state)
            });
        })
        .catch((error) => {
            console.log(error)
        });
}

loadVehicules(nb){
  var nbVehiculesLoaded = this.state.nbVehiculesLoaded;
  for(let i = this.state.nbVehiculesLoaded; i < (this.state.nbVehiculesLoaded + nb); ++i){
    ++nbVehiculesLoaded;
    if(!this.state.prospect.LiensVehicules[i])
      break;
    this.fetchVehicule(this.state.prospect.LiensVehicules[i].IDVehicule);
  }
  this.setState({
    nbVehiculesLoaded: nbVehiculesLoaded
  })
}

fetchVehicule(IDVehicule) {
    fetch(localStorage.ClientApiURL + '/Vehicules/' + IDVehicule, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
        },
    })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            var vehicules = this.state.vehicules;
            vehicules.push(json);

            var newState = {
              vehicules: vehicules
            };

            if (json.Immatriculation === this.props.location.query.immat || json.VIN === this.props.location.query.VIN) {
              newState.selectedVehicule = json.IDVehicule
            }
            
            //Si on doit envoyer les infos client à la fenêtre parent et que tous les véhicules ont été chargés, c'est le moment
            if(this.state.sendDataToParentWindow && vehicules.length === this.state.prospect.LiensVehicules.length){
              this.sendDataToParentWindow();
              newState.sendDataToParentWindow = false;
            }
            this.setState(newState);
        })
        .catch((error) => {
            console.log(error)
        });
}

fetchAffaires() {
    fetch(localStorage.ClientApiURL + '/Prospects/' + this.state.prospectId + '/affaires', {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
        },
    })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {

            var affaires = lodash.filter(json, (affaire) => {
                affaire.fetchLeadDone = false;
                return ((affaire.Statut === 'AValider') || (affaire.Statut === 'EnCours')) && affaire.NombreDeRapport >= 1;
            });
            this.setState({
                affairesProspect: affaires,
            });
            this.checkAvailableTypes();
            this.fetchLeadsIds();
        })
        .catch((error) => {
            console.log(error)
        });
}

fetchRapports() {
  fetch(localStorage.ClientApiURL + '/Prospects/' + this.state.prospectId + '/rapports', {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
      },
  })
    .then((response) => {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.json();
    })
    .then(json => {
        var rapports = json;
        rapports = lodash.filter(rapports, function(r){
          if(r.PremiereActionEntrante && !r.InfoCreation.Utilisateur.EstPasserelle)
            return r.InfoCreation.Utilisateur.TypeProfilLead && r.InfoCreation.Utilisateur.TypeProfilLead !== "BDC" && r.InfoCreation.Utilisateur.TypeProfilLead !== "WelcomePro";
          else 
            return r.SuiviPar.TypeProfilLead && r.SuiviPar.TypeProfilLead !== "BDC" && r.SuiviPar.TypeProfilLead !== "WelcomePro";
        });
        var lastRapport = lodash.maxBy(rapports, function(r){
          return r.InfoCreation.DateH;
        });
        //console.log("Dernier rapport", rapports[0]);
        this.setState({
          lastRapport: lastRapport
        });
    })
    .catch((error) => {
        console.log(error)
    });
}

  fetchLeadsIds(){
    var tmp_ = this.state.typesCreate

    if (!this.state.affairesProspect) {
        return;
    }

    this.state.affairesProspect.map((affaire, i) =>{
      return fetch(localStorage.ClientApiURL + '/affaires/' + affaire.IDAffaire + '/leads', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(json => {
          if(json.length){
            if(tmp_["leads"][i]){
              tmp_["leads"][i].IDLead = json[0].IDLead;
              tmp_["leads"][i].fetchLeadDone = true;
              this.setState({
                typesCreate :  tmp_,
              })
            }
          }
      })
      .catch((error) => {
        console.log(error)
      });
    })

}

// TODO : A RECODER
// Afficher une information Ã  l'utilisateur que le prospect dispose dÃ©jÃ  d'une affaire de ce type
checkAvailableTypes(){
    if (!this.state.affairesProspect)
        return;

    var tmp_ = this.state.typesCreate;
    this.state.affairesProspect.map((affaire, i) => {
      if(affaire && affaire.OrigineAction && affaire.Type){
        //affaire.IDLead = this.fetchLeadId(affaire.IDAffaire);
        tmp_["leads"].push(affaire);
        //tmp_["leads"][affaire.Type][tmp_["leads"][affaire.Type].length-1].IDLead= this.fetchLeadId(affaire.IDAffaire);
        tmp_[affaire.Type] = false;
        tmp_["warning"] = true;
      }
      return null;
    });
    var mess="";
    if(!tmp_["VN"])
        mess+="VN";
    if(!tmp_["VO"])
    {
        if(!tmp_["VN"])
            mess+=",";
        mess+="VO";
    }
    if(!tmp_["Atelier"])
    {
        if(!tmp_["VN"] || !tmp_["VO"])
            mess+=",";
        mess+="Atelier";
    }
    tmp_["message"] = mess;

    this.setState({
        typesCreate :  tmp_,
    })
}

isValidForm() {
    let fields = [];

    if (!this.state.canal) {
        fields.push('Canal');
    }

    /*if (!parseInt(this.state.societe, 10)) {
        fields.push('Société');
    }*/

    if (!parseInt(this.state.site, 10)) {
        fields.push('Site');
    }

    if (this.state.type === 'VO' || this.state.type === 'VN') {
      if (!parseInt(this.state.origine_famille, 10)) {
        fields.push('Famille');
      }
      if (!parseInt(this.state.origine_famille, 10)) {
        fields.push('Sous Famille');
      }
      if (!parseInt(this.state.choiceOrigine, 10)) {
        fields.push('Origine');
      }
    }

    if (!this.state.prospect) {
        fields.push('Informations prospect');
    }

    if (!this.state.prospect.Civilite) {
      fields.push('Civilite du prospect');
    }
    if (!this.state.prospect.Nom) {
      fields.push('Nom du prospect');
    }
    if (this.state.prospect.TypeClient === 'Particulier' && !this.state.prospect.Prenom) {
      fields.push('Prénom du prospect');
    }
    if (this.state.prospectId) {
      if ((!this.state.prospect.Mail || !this.state.prospect.Mail.length) && (!this.state.prospect.Telephones || !this.state.prospect.Telephones.length)) {
        fields.push('Mail ou téléphone du prospect');
      }
    }
    else {
      if (this.state.prospect.TypeClient === 'Particulier') {
        if ((!this.state.prospect.Mail || !this.state.prospect.Mail.length) && !this.state.prospect.Mobile  && !this.state.prospect.Prive) {
          fields.push('Mail ou téléphone du prospect');
        }
      }
      else {
        if ((!this.state.prospect.Mail || !this.state.prospect.Mail.length) && !this.state.prospect.Mobile  && !this.state.prospect.Professionnel) {
          fields.push('Mail ou téléphone du prospect');
        }
      }
    }
    
    if ((this.type === 'VO' || this.type === 'VN') && this.state.todoToVendeur && !parseInt(this.state.todoVendeur, 10)) {
        fields.push('Vendeur');
    }

    if ((this.type === 'VO' || this.type === 'VN') && this.state.todoToVendeur && !parseInt(this.state.todoEtape, 10)) {
        fields.push('Etape');
    }

    let fields_string = '';
    fields.map((field, i) => {
        fields_string += "\n- " + field;
        return field;
    });

    if (fields.length > 0)
        alert('Des champs obligatoires ne sont pas renseignés :' + fields_string);

    return (fields.length > 0) ? false : true;
}

handleSubmit(event) {
    event.preventDefault();

    //divergences pour l'appel sortant
    if(this.state.EstAppelSortant && !this.state.appelSortantCreateLead){
      //relance
      if(this.state.appelSortantRelanceForm){
         this.validAppelSortantRelance();
      }
      else{
        //cloture
         this.appelSortantCloture();
      }
      return;
    }

    if (!this.isValidForm()) {
        return false;
    }

    this.setState({
        fetchCreateInProgress: true,
    });

    if (this.state.prospectId) {
        this.refs.ProspectFormEdit.save();
        this.createLead();
        return;
    }

    let body = this.state.prospect;
    body.InfoCreation = {};
    body.InfoCreation.Site = {};
    body.InfoCreation.Site.IDSite = this.state.site;
    body.Origine = 'eSeller Lead';
    body.Telephones = [];
    if (this.state.prospect.Mobile) {
        body.Telephones.push({
            "Type": 3,
            "Numero": this.state.prospect.Mobile
        })
    }
    if (this.state.prospect.Prive) {
        body.Telephones.push({
            "Type": 1,
            "Numero": this.state.prospect.Prive
        })
    }
    if (this.state.prospect.Fax) {
        body.Telephones.push({
            "Type": 2,
            "Numero": this.state.prospect.Fax
        })
    }
    if (this.state.prospect.Professionnel) {
        body.Telephones.push({
            "Type": 0,
            "Numero": this.state.prospect.Professionnel
        })
    }

    var lignesAdresse = this.state.prospect.Adresse.split("\n");
    var colAdresse = ['Adresse','AdresseComplement','Adresse3','Adresse4'];
    for(var i in colAdresse)
      body[colAdresse[i]] = '';
    var colAdresseNum = 0;
    for(var i in lignesAdresse){
      if(lignesAdresse[i] != "" || (lignesAdresse[i-1] && lignesAdresse[i-1] != "")) {
        body[colAdresse[colAdresseNum]] = lignesAdresse[i];
        ++colAdresseNum;
        if(colAdresseNum > 3)break;
      }
    }

    fetch(localStorage.ClientApiURL + '/Prospects', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
        },
        body: JSON.stringify(body),
    })
        .then((response) => {
            if (!response.ok) {
                response.json().then((value) => {
                    this.setState({
                        fetchCreateInProgress: false,
                        errorCreate: true,
                        errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                    });
                });

                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            if(this.state.prospect.TypeClient === "Societe"){
              this.refs.ProspectFormCreate.submitInterlocuteurs(json);
            }
            this.setState({
                prospectId: json,
            }, async () => {
              if(this.state.vehicules.length > 0){
                var vehiculesIDs = await this.createVehicules();
                this.setState({
                  selectedVehicule: vehiculesIDs[this.state.selectedVehicule] ? vehiculesIDs[this.state.selectedVehicule] : null
                }, () => {this.createLead()})
              }
              else {
                this.createLead();
              }
            });
        })
        .catch((error) => {
            console.log(error)
            this.setState({ fetchInProgress: false })
        });

}

async createVehicules() {
  var vehiculesIDs = [];
  for(var i in this.state.vehicules) {
    var vehicule = this.state.vehicules[i];
    var body = JSON.stringify(vehicule);

    try {
      var response = await fetch(localStorage.ClientApiURL+'/prospects/'+ this.state.prospectId +'/vehicules' , {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
        body: body,
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      var json = await response.json();

      vehiculesIDs[vehicule.IDVehicule] = json;
    }
    catch(error){
      console.log(error)
    }
  }

  return vehiculesIDs;
}

createLead() {
    if ((!this.state.EstAppelSortant || (this.state.EstAppelSortant && this.state.appelSortantCreateLead)) && (this.state.type === 'Atelier' || this.state.type === 'Autres') && localStorage.eSellerLeadAtelierMailRapport === 'true') {
      this.createRapportAtelierSendEmail();
      return;
    }

    if(this.state.EstAppelSortant && this.state.appelSortantCreateLead){
      this.validAppelSortantLead();
      return;
    }

    let affaireComment = '';

    if (this.state.type === 'VN') {
        affaireComment = this.state.commentVN;
    }
    else if (this.state.type === 'VO') {
        affaireComment = this.state.commentVO;
    }
    else if (this.state.type === 'Atelier') {
        affaireComment = this.state.commentAtelier;
    }
    else if (this.state.type === 'Autres') {
        affaireComment = this.state.commentAutres;
    }
    else if(this.state.type === 'VDL') {
        affaireComment = this.state.commentVDL;
    }
    else if(this.state.type === 'Accessoires') {
        affaireComment = this.state.commentAccessoires;
    }

    let body = {
        "Prospect": {
            "IDProspect": this.state.prospectId,
        },
        "Campagne" : (this.state.campagne ? { "IDCampagne":  this.state.campagne, } : null),
        "OrigineAction": {
            "IDOrigineAction": this.state.choiceOrigine ? this.state.choiceOrigine : null,
            "EstLead": true
        },
        "Statut": 3,
        Description: affaireComment,
        "CanalOrigine": this.state.canal,
    };

    if (this.state.type === 'Atelier' && this.state.selectedVehicule) {
        body.Vehicule = {
            IDVehicule: this.state.selectedVehicule
        };
    }
    body.Type = this.state.type;
    body = JSON.stringify(body);
    console.log(body)

    fetch(localStorage.ClientApiURL + '/Affaires/', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
        },
        body: body,
    })
        .then((response) => {
            if (!response.ok) {
                response.json().then((value) => {
                    this.setState({
                        fetchCreateInProgress: false,
                        errorCreate: true,
                        errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                    });
                });

                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            localStorage.refreshHeaderCounters = "1";
            let id_affaire = json;
            let date_relance = moment(this.state.todoDate).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
            if (this.state.todoToVendeur && this.state.todoHeure) {
                date_relance.set('hour', this.state.todoHeure.get('hour')).set('minute', this.state.todoHeure.get('minute'));
            }

            let date_relance_fin = null;
            if (this.state.todoDateHeureFin) {
              date_relance_fin = this.state.todoDateHeureFin;
            }
            else if (this.state.todoHeureFin) {
              date_relance_fin = moment(date_relance);
              date_relance_fin.set('hour', this.state.todoHeureFin.get('hour')).set('minute', this.state.todoHeureFin.get('minute'));
            }

            var todoToVendeur = this.state.todoToVendeur;

            if (this.props.location.query.origin === 'CallWin' && this.state.CallcenterTypeCreateLead === '' && this.state.ToVendeurCallwinOK) {
              date_relance = moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
              todoToVendeur = true;
            }

            var ARedistribuer = false;
            if (localStorage.bypass_iFrame === "true") {
              if (this.state.todoVendeur <= 0 && this.state.type !== 'Atelier') {
                ARedistribuer = true;
              }
            }
            else if(this.state.type === 'Atelier'){
              ARedistribuer = false;
            }
            else {
              ARedistribuer = !todoToVendeur;
            }

            var commentRapport = this.state.todoComment;

            var IDEtape = (todoToVendeur && this.state.todoEtape) ? this.state.todoEtape : 1; // Si pas de vendeur, on met "A qualifier";
            if (this.state.type === 'Atelier') {
              IDEtape = 7; // En cours
              if (this.state.commentAtelier.indexOf('Prise de RDV') !== -1 && this.state.commentAtelier.indexOf('RDV Confirmé : Oui') !== -1) {
                IDEtape = 9; // RDV Pris
              }
              if (commentRapport === '') {
                commentRapport = this.state.commentAtelier;
              }
            }

            let body = {
                "IDLead": null,
                "Affaire": {
                    "IDAffaire": id_affaire,
                },
                "Campagne" : (this.state.campagne ? { "IDCampagne":  this.state.campagne, } : null),
                "Site": {
                    "IDSite": this.state.site,
                },
                "Prospect": {
                    "IDProspect": this.state.prospectId,
                },
                "SuiviPar": {
                    "IDUtilisateur": this.state.todoVendeur <= 0 ? 0 : this.state.todoVendeur, // Soit il est affecté au vendeur, soit il est dans le pot commun pour être redistribuÃ©
                },
                "ARedistribuer": ARedistribuer,
                "PremiereActionEntrante": true,
                "ActionFaite_DO": {
                    "OrigineAction": {
                        "IDOrigineAction": this.state.choiceOrigine ? this.state.choiceOrigine : null,
                        "EstLead": true
                    },
                    "CanalOrigine": this.state.canal,
                    "TypeContact": {
                        "Description": "eSeller Lead"
                    },
                    Commentaire: commentRapport,
                },
                "ActionAFaire_TODO": {
                    "Etape": {
                        "IDEtape": IDEtape,
                    },
                    "ActionAFaire": {
                        "Description": this.state.todoEtapeText ? this.state.todoEtapeText : "Téléphoner",
                    },
                    "NatureActionAFaire": "Téléphone",
                    "NiveauInteret": {
                        "IDNiveauInteret": 6,
                    },
                    "EstDateLimite": todoToVendeur && this.state.todoHeure ? true : false,
                    "DateHRelance": date_relance.format(),
                    "DateHFinRelance": date_relance_fin ? date_relance_fin.format() : null,
                    "DateHProchaineEtape": date_relance.format(),
                    "Etat": "EnCours",
                    "Cloturer": null
                }
            };

            if (this.state.EstAppelSortant && this.props.location.query.IDLead) {
                body['IDLeadsATerminer'] = [this.props.location.query.IDLead];
            }

            body = JSON.stringify(body);
            console.log(body)

            fetch(localStorage.ClientApiURL + '/Leads/', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'APIKey': Globals.API_KEY,
                    'IDClient': localStorage.user_IDClient,
                    'Login': localStorage.user_login,
                    'Password': localStorage.user_password
                },
                body: body,
            })
                .then((response) => {
                    if (!response.ok) {
                        response.json().then((value) => {
                            this.setState({
                                fetchCreateInProgress: false,
                                errorCreate: true,
                                errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                            });
                        });

                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then(json => {
                    let id_affaire = json;
                    
                    var extract = json.split('_')
                    
                    if (this.state.type === 'Atelier' && this.state.todoVendeur) {
                      fetch(localStorage.ClientApiURL + '/leads/atelier/sendMail?id_prospect='+this.state.prospectId+'&id_destinataire='+this.state.todoVendeur+'&id_rapport='+extract[1], {
                          method: 'POST',
                          headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json',
                          'APIKey': Globals.API_KEY,
                          'IDClient': localStorage.user_IDClient,
                          'Login': localStorage.user_login,
                          'Password': localStorage.user_password
                          },
                          body: JSON.stringify({mailContent: affaireComment, IDVehicule: this.state.selectedVehicule}),
                      })
                      .then((response) => {
                          if (!response.ok) {
                          response.json().then((value) => {
                              this.setState({
                              fetchCreateInProgress: false,
                              errorCreate: true,
                              errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                              });
                          });
                      
                          throw Error(response.statusText);
                          }
                          this.setState({
                          fetchCreateInProgress: false,
                          successCreate: true,
                          errorCreate: false,
                          errorCreateMessage: '',
                          });
                      })
                      .catch((error) => {
                          console.warn(error);
                      });
                    }

                    if (!this.state.relance) {
                        this.setState({
                            fetchCreateInProgress: false,
                            successCreate: true,
                            errorCreate: false,
                            errorCreateMessage: '',
                        });
                        return;
                    }

                    let date_relance = this.state.relanceDate;
                    if (this.state.relance && this.state.relanceHeure) {
                        date_relance.set('hour', this.state.relanceHeure.get('hour')).set('minute', this.state.relanceHeure.get('minute'));
                    }

                    let body = JSON.stringify({
                        "IDLead": null,
                        "Affaire": {
                            "IDAffaire": id_affaire,
                        },
                        "Campagne" : (this.state.campagne ? { "IDCampagne":  this.state.campagne, } : null),
                        "Site": {
                            "IDSite": this.state.site,
                        },
                        "Prospect": {
                            "IDProspect": this.state.prospectId,
                        },
                        "SuiviPar": {
                            "IDUtilisateur": localStorage.user_IDUtilisateur,
                        },
                        "ActionFaite_DO": {
                            "OrigineAction": {
                                "IDOrigineAction": this.state.choiceOrigine ? this.state.choiceOrigine : null,
                                "EstLead": true
                            },
                            "CanalOrigine": this.state.canal,
                            "TypeContact": {
                                "Description": "eSeller Lead"
                            },
                            Commentaire: this.relanceCommentaire,
                        },
                        "ActionAFaire_TODO": {
                            "Etape": {
                                "IDEtape": this.state.todoToVendeur && this.state.todoEtape ? this.state.todoEtape : 1, // Si pas de vendeur, on met "A qualifier"
                            },
                            "ActionAFaire": {
                                "Description": this.state.relanceAction ? this.state.relanceAction : this.state.todoEtapeText,
                            },
                            "NatureActionAFaire": "Téléphone",
                            "NiveauInteret": {
                                "IDNiveauInteret": 6,
                            },
                            "EstDateLimite": this.state.relance && this.state.relanceHeure ? true : false,
                            "DateHRelance": this.state.relance ? date_relance.format() : null,
                            "DateHProchaineEtape":this.state.relance ? date_relance.format() : null,
                            "Etat": "EnCours",
                            "Cloturer": null
                        }
                    });

                    fetch(localStorage.ClientApiURL + '/Leads/', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'APIKey': Globals.API_KEY,
                            'IDClient': localStorage.user_IDClient,
                            'Login': localStorage.user_login,
                            'Password': localStorage.user_password
                        },
                        body: body,
                    })
                        .then((response) => {
                            if (!response.ok) {
                                response.json().then((value) => {
                                    this.setState({
                                        fetchCreateInProgress: false,
                                        errorCreate: true,
                                        errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                                    });
                                });

                                throw Error(response.statusText);
                            }
                            return response.json();
                        })
                        .then(json => {
                          console.log(json)
                            this.setState({
                                fetchCreateInProgress: false,
                                successCreate: true,
                                errorCreate: false,
                                errorCreateMessage: '',
                            });
                        })
                        .catch((error) => {
                            console.warn(error);
                        });
                        
                })
                .catch((error) => {
                    console.warn(error);
                    this.setState({
                        fetchCreateInProgress: false,
                        errorCreate: true,
                        errorCreateMessage: error,
                    });
                });

        })
        .catch((error) => {
            console.warn(error);
            this.setState({
                fetchCreateInProgress: false,
                errorCreate: true,
                errorCreateMessage: error,
            });
        });
}


  createRapportAtelierSendEmail() {
    let affaireComment = '';

    if (this.state.type === 'Atelier') {
      if(this.refs.FormAtelierRef.state.type === "MECAPLANNING"){
        affaireComment += "Type de demande : MECAPLANNING"+ '\n';
        if(this.refs.FormAtelierRef.state.mecaplanningrdv){
          affaireComment += "Rendez-vous pris : Oui"+ '\n';
          }
          else{
          affaireComment += "Rendez-vous pris : Non"+ '\n';
          }
      }
      else{
        affaireComment = this.state.commentAtelier;
      }
    }
    else if (this.state.type === 'Autres') {
      affaireComment = this.state.commentAutres;
    }

    // Créer rapport prospect
    let body = {
      IDLead: null,
      "Campagne" : (this.state.campagne ? { "IDCampagne":  this.state.campagne, } : null),
      Site: {
        IDSite: this.state.site,
      },
      Prospect: {
        IDProspect: this.state.prospectId,
      },
      "SuiviPar": {
        "IDUtilisateur": 0,
      },
      ActionFaite_DO: {
        "OrigineAction": {
          "IDOrigineAction": this.state.choiceOrigine ? this.state.choiceOrigine : null,
        },
        "DateHLead": moment().format(),
        "TypeContact": {
          "Description": "Atelier",
        },
        "CanalOrigine": this.state.canal,
        Commentaire: affaireComment,
      },
      "ActionAFaire_TODO": {
        "Etape": null,
        "ActionAFaire": null,
        "NatureActionAFaire": "Téléphone",
        "NiveauInteret": {
          "IDNiveauInteret": 1,
        },
        "EstDateLimite": false,
        "DateHRelance": null,
        "DateHFinRelance": null,
        "DateHProchaineEtape": null,
        "Etat": "Termine",
      },
      "EstAtelier": true,
    };

    body = JSON.stringify(body);

    console.log(body)

    fetch(localStorage.ClientApiURL + '/Leads/creer', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: body,
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          this.setState({
            fetchCreateInProgress: false,
            errorCreate: true,
            errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
          });
        });

        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {

      if (!Array.isArray(this.state.todoVendeur) || this.state.todoVendeur.length <= 0) {
        this.setState({
          fetchCreateInProgress: false,
          successCreate: true,
          errorCreate: false,
          errorCreateMessage: '',
        });
        return;
      }

      // Envoyer email aux différents conseillers
      this.state.todoVendeur.map( (vendeur, i) => {

            fetch(localStorage.ClientApiURL + '/leads/atelier/sendMail?id_prospect='+this.state.prospectId+'&id_destinataire='+vendeur+'&id_rapport='+json, {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'APIKey': Globals.API_KEY,
                'IDClient': localStorage.user_IDClient,
                'Login': localStorage.user_login,
                'Password': localStorage.user_password
              },
              body: JSON.stringify({mailContent: affaireComment, IDVehicule: this.state.selectedVehicule}),
            })
            .then((response) => {
              if (!response.ok) {
                response.json().then((value) => {
                  this.setState({
                    fetchCreateInProgress: false,
                    errorCreate: true,
                    errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                  });
                });

                throw Error(response.statusText);
              }
              this.setState({
                fetchCreateInProgress: false,
                successCreate: true,
                errorCreate: false,
                errorCreateMessage: '',
              });
            })
            .catch((error) => {
              console.warn(error);
            });
        });
      })
      .catch((error) => {
        console.warn(error);
      });
  }


handleFieldChanged(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.validateField(name, value);
}

changeDateRelance(event) {
    this.setState({
        todoDate: event.target.value,
    })
}

getUrlMecaplanning() {
    var url = "/widget_mecaplanning.html?";

    if (this.state.prospect) {
        if (this.state.prospect.Nom)
            url += "sNom=" + this.state.prospect.Nom + "&";

        if (this.state.prospect.Prenom)
            url += "sPrenom=" + this.state.prospect.Prenom + "&";

      if (this.state.prospect.Telephones && this.state.prospect.Telephones.length){
        if(this.state.prospect.Telephones[0].Numero){
          var pr = this.state.prospect.Telephones[0].Numero.replace(/\./g,'').replace(/\ /g, '');
          url += "sTel=" + this.state.prospect.Telephones[0].Numero + "&";
        }
      }

      if(!this.state.prospectId){
        if(this.state.prospect.Prive){
          var pr = this.state.prospect.Prive.replace(/\./g,'').replace(/\ /g, '');
          if (pr && pr.length){
            url += "sTel=" + pr + "&";
          }
        }
      }
    }
    url += "sJwt=" + this.state.sJwt + "&";
    this.setState({
      UrlMecaplanning : url,
    });
  }

fetchUtilisateurs() {
    fetch(localStorage.ClientApiURL + '/utilisateurs?EstActif='+true+'&LoadDetailDroitProfil='+false+'LoadPlaqueMarques='+false, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
        },
    })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
          console.log('users for ' + this.state.site)
            var users = lodash.filter(json, (u) => {

              if (!u.TypeProfilLead || (u.TypeProfilLead !== 'Vendeur' && u.TypeProfilLead !== 'Conseiller')) {
                return false;
              }

              var exist = false;
              exist = lodash.filter(u.Sites, (s) => {
                  return parseInt(s.IDSite, 10) === parseInt(this.state.site, 10);
              })
              return exist.length > 0;
            });

            this.setState({
                utilisateurs: users,
                utilisateurs_all: json,
                todoVendeur: 0,
            });
        })
        .catch((error) => {
            console.log(error)
        });
}

// Est appelÃ© lors du changement de valeur dans les composants enfants
changeValue(attr, value) {
    this.setState({
        [attr]: value
    }, () => {
      if (attr === 'site') {
        this.fetchMecaplanningToken();
        if (this.props.location.query.origin === 'CallWin' && this.state.CallcenterTypeCreateLead === '') {
          this.fetchUtilisateurs();
        }
      }
      if(attr === 'lockProspectMecaplanning'){
        this.fetchMecaplanningToken();
      }
    });


    if (attr === 'type' && !this.state.EstAppelSortant){
      //si on change le type d'affaire on regénère les familles, sous familles et actions
      this.setState({
        origine_famille : 0,
        origine_sousfamille : 0,
        choiceOrigine : 0
      });
    }

    //si on change le type du formulaire, on reset le site sélectionné
    if(attr === "type" && localStorage.bypass_iFrame !== "true" && !this.state.EstAppelSortant){
        if(!this.props.location.query.called){
          this.setState({
              societe : 0,
              site : 0,
          });
        }
        if(!localStorage.IsVDL === 'true')
          this.refs.formsocietesite.generateAll();
    }

    if (attr === 'type' && this.state.prospectId){
      if(value === 'Atelier') {
          this.fetchHistoriqueAteliers();
      }
      else{
          if(localStorage.bypass_iFrame !== "true" && !this.props.location.query.IDSite && this.state.prospect && this.state.prospect.SiteAffectation){
            var societe = this.state.prospect.SiteAffectation.Plaque.IDPlaque;
            var site = this.state.prospect.SiteAffectation.IDSite;
            this.setState({
              societe: societe,
              site: site,
            });
          }
      }
    }
}

propageSuccess() {
    if (localStorage.bypass_iFrame === "true") {
        window.parent.window.postMessage(this.state.prospectId, this.props.location.query.origin)
    }
    else {
        browserHistory.push('/prospect/' + this.state.prospectId);
    }
}

hideAlert() {
    this.setState({
        errorCreate: false,
        errorCreateMessage: '',
    });
}

changeProspect(prospect) {
    this.setState({
      prospect: prospect,
    }, () => {
      this.fetchMecaplanningToken()
    });
  }

openModelWeekCalendar() {
    if (this.refs.modalWeekCalendar) {
        this.refs.modalWeekCalendar.show();
    }
}

onSelectCalendar(start, end, resource) {
    this.refs.modalWeekCalendar.hide();
    this.setState({
        todoDate: moment(start).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0),
        todoHeure: start,
        todoDateHeureFin: end,
        todoVendeur: resource ? resource : 0,
    })
}

disabledButtonValid(event) {


    if (this.state.fetchCreateInProgress) {
        return true;
    }

    /*
    if (!this.state.canal) {
      return true;
    }

    if (!parseInt(this.state.societe, 10)) {
      return true;
    }

    if (!parseInt(this.state.site, 10)) {
      return true;
    }

    if (!parseInt(this.state.origine, 10)) {
      return true;
    }

    if (!parseInt(this.state.choiceOrigine, 10)) {
      return true;
    }

    if (!this.state.prospect) {
      return true;
    }

    if (this.state.todoToVendeur && (!parseInt(this.state.todoEtape, 10) || !parseInt(this.state.todoVendeur, 10))) {
      return true;
    }
    */

    if(this.state.EstAppelSortant && this.state.appelSortantCreateLead && !this.state.typeAppelSortant){
      return true;
    }

    // CallWin
    if (this.props.location.query.origin === 'CallWin') {
      if (!this.state.ToVendeurCallwinOK && !this.state.ToVendeurCallwinNOK) {
        return true;
      }
      if (this.state.ToVendeurCallwinOK && !this.state.ToVendeurCallwinNOK && (!this.state.todoVendeur || this.state.todoVendeur <= 0) && !this.state.ToVendeurCallwinOKPotcommun) {
        return true;
      }
    }
    else {
      if (this.state.type === 'Atelier' && localStorage.eSellerLeadAtelierMailRapport == "true" && !this.state.todoVendeur.length && !this.state.EstAppelSortant) {
        return true;
      }
    }

    return false;
  }

  disabledButtonCancel(event) {
    if (this.state.fetchCreateInProgress) {
        return true;
    }
    return false;
  }

  getAllUsers() {
    var utilisateurs = lodash.filter(this.state.utilisateurs, (utilisateur) => {
      
        if (utilisateur.EstPasserelle)
            return false;

        if (localStorage.IsVDL === 'true') 
          return true;

        if(this.state.type === "VN")
            return utilisateur.EstTypeVN;
        else if(this.state.type === "VO")
            return utilisateur.EstTypeVO;
        else if(this.state.type === "Atelier")
          return utilisateur.EstTypeConseiller;
        else if(this.state.type === "Autre")
          return utilisateur.EstTypeAutre;
    });
    return utilisateurs;
}

changeVendeurCallWin(event) {
    this.setState({
        todoVendeur: event.target.value,
        todoEtape: 1, // étape "Ã  qualifier"
    });
}

changeVehicule(IDVehicule) {
    if (this.state.selectedVehicule === IDVehicule) {
        this.setState({
            selectedVehicule: null,
        })
    }
    else {
        this.setState({
            selectedVehicule: IDVehicule
        })
    }
}

isCheckedVehicule(IDVehicule) {
    return (this.state.selectedVehicule && (IDVehicule === this.state.selectedVehicule));
}

selectProspect(IDProspect) {
    this.setState({
        prospectId: IDProspect,
        sendDataToParentWindow: this.props.location.query.sendDataToParentWindow == 1
    }, () => {
        this.fetchProspect()
    })
}

getDefaultPhoneProspect() {
    if ((this.props.location.query.phone !== undefined && this.props.location.query.phone !== '') || (this.props.location.query.calling !== '')) {
        if (this.props.location.query.phone !== undefined && this.props.location.query.phone !== '')
            return this.props.location.query.phone
        if (this.props.location.query.calling !== '')
            return this.props.location.query.calling
    }

    return '';
}

// créer un rapport fermé sur la fiche client (relance)
  appelSortantCloture() {
    if (!this.isValidForm()) {
      return false;
    }

    this.setState({
      fetchCreateInProgress: true,
    });

    if(!this.state.appelSortantCloture){
      if (this.state.prospectId) {
        this.refs.ProspectFormEdit.save();
      }
    }

    let body = {
      IDLead: null,
      "Campagne" : (this.state.campagne ? { "IDCampagne":  this.state.campagne, } : null),
      Site: {
        "IDSite": this.state.site,
      },
      Prospect: {
        "IDProspect": this.state.prospectId,
      },
      "SuiviPar": {
        "IDUtilisateur": localStorage.user_IDUtilisateur,
      },
      ActionFaite_DO: {
        "OrigineAction": {
            "IDOrigineAction": this.state.choiceOrigine ? this.state.choiceOrigine : null,
            "EstLead": true
          },
          "CanalOrigine": this.state.canal,
        "DateHLead": moment().format(),
        "TypeContact": {
          "Description": "Appel Sortant",
        },
        Commentaire: this.state.appelSortantComment,
        AppelSortantCodeRaison: this.state.codeRaisonClotureAppelSortant,
      },
      "ActionAFaire_TODO": {
        "Etape": null,
        "ActionAFaire": {
          "Description": "Téléphoner",
        },
        "NatureActionAFaire": "Téléphone",
        "NiveauInteret": {
          "IDNiveauInteret": 1,
        },
        "EstDateLimite": false,
        "DateHRelance": null,
        "DateHFinRelance": null,
        "DateHProchaineEtape": null,
        "Cloturer": {
          AffaireRaisonStop: null,
          AutreRaisonStop: this.state.codeRaisonClotureAppelSortant ? this.state.codeRaisonClotureAppelSortant : 'Appel sortant',
        },
        "Etat": "Termine",
      },
    };

    if (this.props.location.query.IDLead) {
      body['IDLeadsATerminer'] = [this.props.location.query.IDLead];
    }

    body = JSON.stringify(body);

    console.log(body)


    fetch(localStorage.ClientApiURL + '/Leads/creer', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: body,
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          this.setState({
            fetchCreateInProgress: false,
            errorCreate: true,
            errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
          });
        });

        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      if(!this.state.appelSortantCreateLead){
        this.setState({
          fetchCreateInProgress: false,
          successCreate: true,
          errorCreate: false,
          errorCreateMessage: '',
          successCreateMessage: 'Clôture avec succès !',
        });
      }
      else{
        this.proceedAppelSortantLead();
      }
    })
    .catch((error) => {
      console.warn(error);
    });
  }

  // créer un rapport ouvert sur la fiche client (relance action à faire)
  appelSortantRelance() {

    this.setState({
        appelSortantRelanceForm: true,
    })

}

  cancelAppelSortantRelance(){
    this.setState({
      appelSortantRelanceForm : false,
    });
  }

  //créer un rapport fermé - appel sortant créer lead
  validAppelSortantLead(){
    this.appelSortantCloture();
  }
  proceedAppelSortantLead(){
    let affaireComment = '';

    if (this.state.typeAppelSortant === 'VN') {
      affaireComment = this.state.commentVN;
    }
    else if (this.state.typeAppelSortant === 'VO') {
      affaireComment = this.state.commentVO;
    }
    else if (this.state.typeAppelSortant === 'Atelier') {
      affaireComment = this.state.commentAtelier;
    }
    else if (this.state.typeAppelSortant === 'Autres') {
      affaireComment = this.state.commentAutres;
    }
    else if(this.state.type === 'VDL') {
      affaireComment = this.state.commentVDL;
    }
    else if(this.state.type === 'Accessoires') {
      affaireComment = this.state.commentAccessoires;
    }

    let body = {
      "Prospect": {
        "IDProspect": this.state.prospectId,
      },
      "Campagne" : (this.state.campagne ? { "IDCampagne":  this.state.campagne, } : null),
      "OrigineAction": {
        "IDOrigineAction": this.state.choiceOrigine ? this.state.choiceOrigine : null,
        "EstLead": true
      },
      "Statut": 3,
      Description: affaireComment,
      "CanalOrigine": this.state.canal,
    };

    if (this.state.type === 'Atelier' && this.state.selectedVehicule) {
      body.Vehicule = {
        IDVehicule: this.state.selectedVehicule
      };
    }
    body.Type = this.state.typeAppelSortant;
    body = JSON.stringify(body);
    console.log(body)

    fetch(localStorage.ClientApiURL + '/Affaires/', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: body,
    })
    .then((response) => {
      if (!response.ok) {
        response.json().then((value) => {
          this.setState({
            fetchCreateInProgress: false,
            errorCreate: true,
            errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
          });
        });

        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      let id_affaire = json;
      let date_relance = moment(this.state.todoDate).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
      if (this.state.todoToVendeur && this.state.todoHeure) {
        date_relance.set('hour', this.state.todoHeure.get('hour')).set('minute', this.state.todoHeure.get('minute'));
      }

      var ARedistribuer = true;
      if (this.state.todoToVendeur || localStorage.bypass_iFrame === "true") {
        ARedistribuer = false;
        if (this.state.ToVendeurCallwinNOK) {
          ARedistribuer = true;
        }
      }

      if (this.state.ToVendeurCallwinOK) {
        date_relance = moment().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
      }

      var IDUtilisateur = true;
      if (this.state.todoToVendeur || localStorage.bypass_iFrame === "true") {
        IDUtilisateur = this.state.todoVendeur;
        if (this.state.ToVendeurCallwinNOK) {
          IDUtilisateur = 0;
        }
      }

      let body = {
       "IDLead": null,
       "Affaire": {
          "IDAffaire": id_affaire,
       },
       "Campagne" : (this.state.campagne ? { "IDCampagne":  this.state.campagne, } : null),
        "Site": {
          "IDSite": this.state.site,
        },
        "Prospect": {
          "IDProspect": this.state.prospectId,
        },
        "SuiviPar": {
          "IDUtilisateur": IDUtilisateur, // Soit il est affecté au vendeur, soit il est dans le pot commun pour être redistribué
        },
        "ARedistribuer": ARedistribuer,
        "PremiereActionEntrante": true,
        "ActionFaite_DO": {
          "OrigineAction": {
            "IDOrigineAction": this.state.choiceOrigine ? this.state.choiceOrigine : null,
            "EstLead": true
          },
          "CanalOrigine": this.state.canal,
          "TypeContact": {
            "Description": "eSeller Lead"
          },
          Commentaire: this.state.todoComment,
        },
        "ActionAFaire_TODO": {
          "Etape": {
            "IDEtape": this.state.todoToVendeur && this.state.todoEtape ? this.state.todoEtape : 1, // Si pas de vendeur, on met "A qualifier"
          },
          "ActionAFaire": {
            "Description": this.state.todoEtapeText ? this.state.todoEtapeText : "Téléphoner",
          },
          "NatureActionAFaire": "Téléphone",
          "NiveauInteret": {
            "IDNiveauInteret": 6,
          },
          "EstDateLimite": this.state.todoToVendeur && this.state.todoHeure ? true : false,
          "DateHRelance": date_relance.format(),
          "DateHFinRelance": this.state.todoDateHeureFin ? this.state.todoDateHeureFin.format() : null,
          "DateHProchaineEtape": date_relance.format(),
          "Etat": "EnCours",
          "Cloturer": null
        }
      };

      if (this.state.EstAppelSortant && this.props.location.query.IDLead) {
        body['IDLeadsATerminer'] = [this.props.location.query.IDLead];
      }

      body = JSON.stringify(body);

      fetch(localStorage.ClientApiURL + '/Leads/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
        body: body,
      })
      .then((response) => {
        if (!response.ok) {
          response.json().then((value) => {
            this.setState({
              fetchCreateInProgress: false,
              errorCreate: true,
              errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
            });
          });

          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(json => {
        let id_affaire = json;

        this.setState({
          fetchCreateInProgress: false,
          successCreate: true,
          errorCreate: false,
          errorCreateMessage: '',
        });
        return;
      })
      .catch((error) => {
        console.warn(error);
        this.setState({
          fetchCreateInProgress: false,
          errorCreate: true,
          errorCreateMessage: error,
        });
      });

    })
    .catch((error) => {
      console.warn(error);
      this.setState({
        fetchCreateInProgress: false,
        errorCreate: true,
        errorCreateMessage: error,
      });
    });
  }

  // créer un rapport ouvert sur la fiche client (relance action à faire)
  validAppelSortantRelance() {

    if (!this.isValidForm()) {
        return false;
    }

    this.setState({
        fetchCreateInProgress: true,
    });



    let date_relance = this.state.relanceDate;
    if (this.state.relanceHeure) {
        date_relance.set('hour', this.state.relanceHeure.get('hour')).set('minute', this.state.relanceHeure.get('minute'));
    }

    let body = {
        IDLead: this.props.location.query.IDLead ? this.props.location.query.IDLead : null,
        "Campagne" : (this.state.campagne ? { "IDCampagne":  this.state.campagne, } : null),
        Site: {
            "IDSite": this.state.site,
        },
        Prospect: {
            "IDProspect": this.state.prospectId,
        },
        "SuiviPar": {
            "IDUtilisateur": localStorage.user_IDUtilisateur,
        },
        ActionFaite_DO: {
            "OrigineAction": {
                "IDOrigineAction": this.state.choiceOrigine ? this.state.choiceOrigine : null,
                "EstLead": true
            },
            "CanalOrigine": this.state.canal,
            "DateHLead": moment().format(),
            "TypeContact": {
                "Description": "Appel Sortant",
            },
            Commentaire: this.state.appelSortantComment,
            AppelSortantCodeRaison: this.state.codeRaisonClotureAppelSortant,
        },
        "ActionAFaire_TODO": {
            "Etape": null,
            "ActionAFaire": {
                "Description": "Téléphoner",
            },
            "NatureActionAFaire": "Téléphone",
            "NiveauInteret": {
                "IDNiveauInteret": 1,
            },
            "EstDateLimite": false,
            "DateHRelance": date_relance.format(),
            "DateHFinRelance": null,
            "DateHProchaineEtape": null,
            "Etat": "EnCours",
        },
        "EstAppelSortant": true,
    };

    if (this.props.location.query.IDLead) {
        body['IDLeadsATerminer'] = [this.props.location.query.IDLead];
    }

    body = JSON.stringify(body);

    console.log(body)

    fetch(localStorage.ClientApiURL + '/Leads/' + (this.props.location.query.IDLead ? 'traiter_lead' : 'creer'), {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
        },
        body: body,
    })
        .then((response) => {
            if (!response.ok) {
                response.json().then((value) => {
                    this.setState({
                        fetchCreateInProgress: false,
                        errorCreate: true,
                        errorCreateMessage: value.Message + ((value.ExceptionMessage) ? '\n' + value.ExceptionMessage : ''),
                    });
                });

                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            this.setState({
                fetchCreateInProgress: false,
                successCreate: true,
                errorCreate: false,
                errorCreateMessage: '',
                successCreateMessage: 'Création de la relance avec succès !'
            });
        })
        .catch((error) => {
            console.warn(error);
        });
}

appelSortantCreateLead() {
    // affiche le formualaire de création d'un lead
    this.setState({
      appelSortantCreateLead: true,
      disabledEditOrigine: true,
    });
    if(this.state.type && this.state.typeAppelSortant === '')
    {
      this.setState({  'typeAppelSortant' : this.state.type });
    }
  }

cancelAppelSortantCreateLead(){
  //Annule l'affichage du formulaire de création d'un lead (depuis appel sortant)
  this.setState({
    appelSortantCreateLead: false,
    disabledEditOrigine: false,
    typeAppelSortant: '',
    todoVendeur:0,
  });
}

finishCreateVehicule(vehicule) {
  if(this.state.prospectId === null){
    vehicule.IDVehicule = 'new'+Date.now();
    var vehicules = this.state.vehicules;
    vehicules.push(vehicule);
    this.setState({
      vehicules:vehicules,
      selectedVehicule: vehicule.IDVehicule,
    });
    this.refs.modalVehiculeParcClientCreate.hide();
  }
  else{
    var IDVehicule = vehicule;
    this.refs.modalVehiculeParcClientCreate.hide();
    this.fetchVehicule(IDVehicule);
    this.setState({
        selectedVehicule: IDVehicule
    })
  }
}

finishEditVehicule(vehicule){
  if(this.state.prospectId === null){
    var vehicules = this.state.vehicules;
    lodash.remove(vehicules, (vehicule) => {
      return vehicule.IDVehicule === this.state.selected_vehicule.IDVehicule;
    });
    vehicules.push(vehicule);
    this.setState({
      vehicules: vehicules
    })
  }
  else {
    var vehicules = this.state.vehicules
    lodash.remove(vehicules, (v) => {
      return v.IDVehicule === this.state.selected_vehicule.IDVehicule;
    });
    this.setState({
      vehicules: vehicules
    })
    this.fetchVehicule(vehicule);
  }
  this.refs.modalVehiculeEdit.hide();
}

callEditVehicule(id){
  var vehicule = lodash.find(this.state.vehicules, {IDVehicule: id});
  this.setState({
      selected_vehicule: vehicule,
  });
  this.refs.modalVehiculeEdit.show();
}

handleClickType(event){
    this.setState({
      'typeAppelSortant' : event.target.value
    });
  }

  async changeEstPossede(IDVehicule, clientVehicule, event) {

    await fetch(localStorage.ClientApiURL + '/prospects/'+ clientVehicule.IDClient +'/vehicules/'+IDVehicule+'/possede' , {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
      body: JSON.stringify({EstPossede: event.target.checked ? true : false}),
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return
    })
    .catch((error) => {
      console.log(error)
    });

    var vehicules = this.state.vehicules;
    vehicules = lodash.filter(vehicules, (v) => {
      return v.IDVehicule != IDVehicule;
    })

    this.setState({
      vehicules: vehicules,
      selectedVehicule: IDVehicule == this.state.selectedVehicule ? null : this.state.selectedVehicule,
    }, () => {
      this.fetchVehicule(IDVehicule);
    });
  }

  openModalSendMessage() {
    if(!this.state.prospect.SiteAffectation){ //Si le prospect n'est pas affecté à un point de vente il faut le faire avant d'envoyer un message
      this.openModal('affectSiteModal');
    }
    else 
      this.openModal('modalSendMessage');
  }

  openModal(refModal) {
    this.refs[refModal].show();

    if(refModal === "modalHistoriqueMessages"){
      this.setState({
        showHistoriqueMessages:true
      })
    }
  }

  siteSaved(IDSite){
    var prospect = this.state.prospect;
    prospect.SiteAffectation = {
      IDSite: IDSite
    };
    this.setState({
      prospect:prospect
    });
    this.openModalSendMessage();
  }

  /* La liste des origines est chargée dans tous les cas (iframe ou pas, id_prospect ou pas...),
   * Si une erreur 401 se produit lors de ce chargement, c'est que l'idclient, le login ou le mot de passe est mauvais */
  async errorLoadingOrigines(response){
    var badLogin = false, badIDClient = false;

    if(response.status === 401){
      badLogin = true;
      const response = await fetch(localStorage.ClientApiURL + '/info', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
      });

      if(!response.ok && response.status === 500)
        badIDClient = true;

      this.setState({
        badLogin: badLogin,
        badIDClient: badIDClient
      });
    }
  }

  /* Si l'option (param GET) sendDataToParentWindow = 1, on envoie les données du prospect à la fenêtre parent */
  sendDataToParentWindow(){
    var champsProspect = ['Civilite','Nom','Prenom','TypeClient','Adresse','AdresseComplement','Adresse3','Adresse4','CodePostal','Ville','Telephones','Mail','MailPro'];
    var champsVehicule = ['Marque', 'Modele', 'Version', 'Immatriculation'];
    var data = {
      prospect: lodash.pickBy(this.state.prospect, (val, key) => {
        return champsProspect.indexOf(key) !== -1;
      })
    };
    data.prospect.Vehicules = this.state.vehicules.map((vehicule) => {
      return lodash.pickBy(vehicule, (val, key) => {
        return champsVehicule.indexOf(key) !== -1;
      })
    });
    window.parent.window.postMessage(JSON.stringify(data), "*");
  }

  render() {
    if(this.props.location.query.idclient && !this.state.userInfoLoaded){
      return (
        <div className="center-div" style={{paddingTop:'100px'}}>
            <i className="fa fa-refresh fa-spin fa-2x fa-fw"></i>
        </div>
      )
    }

    if(this.state.badIDClient || this.state.badLogin){
      return (
        <div className="center-div" style={{paddingTop:'100px'}}>
          <i className="fa fa-exclamation-circle fa-2x"></i><br/>
          {this.state.badIDClient ? "IDClient incorrect" : "Login/Password incorrects"}
        </div>);
    }

    return (
      <div className={'page-sidebar-right' + (this.props.location.query.origin && (this.props.location.query.origin.toString().substr(0, 4) === 'http' || this.props.location.query.origin === 'DBS' || (this.props.location.query.origin === 'CallWin' && this.state.CallcenterTypeCreateLead !== '')) ? ' hide-sidebar' : '')}>

        <SkyLight hideOnOverlayClicked ref="modalWeekCalendar" dialogStyles={{ width: '90%', left: '5%', marginLeft: '0%', height: '90%', top: '5%', marginTop: '0%', overflow: 'scroll', zIndex: 9999}}>
          <div className='inner-large-content'>
            <Scheduler IDPlaque={this.state.societe} IDSite={this.state.site} onSelectCalendar={this.onSelectCalendar.bind(this)} typeLead={this.state.type} />
          </div>
        </SkyLight>

        <SkyLight hideOnOverlayClicked ref="modalVehiculeHistoriqueAtelier" dialogStyles={{ width: '90%', left: '5%', marginLeft: '0%', height: '90%', top: '5%', marginTop: '0%', overflow: 'scroll', zIndex: 9999}}>
          <div className='inner-large-content'>
            <VehiculeHistoriqueAtelier IDProspect={this.state.prospectId} IDVehicule={this.state.VehiculeHistoriqueAtelier} />
          </div>
        </SkyLight>

        <SkyLight hideOnOverlayClicked ref="modalVehiculeParcClientCreate"
                dialogStyles={{padding:0, width: '90%', left: '5%', marginLeft: '0%', height: '90%', top: '5%', marginTop: '0%', overflow: 'scroll', zIndex: 9999 }}
                title={"Création d'un "+ Dictionary.getUCFirst("véhicule") +" en parc"}
                titleStyle={{ padding: 10, margin: 0, color: 'white' }}
                closeButtonStyle={{ color: 'white'}}>
          <div className='inner-large-content'>
            <VehiculeParcForm IDProspect={this.state.prospectId} finishCreateVehicule={this.finishCreateVehicule.bind(this)} />
          </div>
        </SkyLight>

        <SkyLight hideOnOverlayClicked ref="modalVehiculeEdit"
                dialogStyles={{padding:0, width: '90%', left: '5%', marginLeft: '0%', height: '90%', top: '5%', marginTop: '0%', overflow: 'scroll', zIndex: 9999 }}
                title={"Edition d'un "+ Dictionary.getUCFirst("véhicule") +" en parc"}
                titleStyle={{ padding: 10, margin: 0, color: 'white' }}
                closeButtonStyle={{ color: 'white'}}>
          <div className='inner-large-content'>
            <VehiculeParcForm IDProspect={this.state.prospectId} vehicule={this.state.selected_vehicule} finishEditVehicule={this.finishEditVehicule.bind(this)} />
          </div>
        </SkyLight>

        {localStorage.ModuleEmailSMS && localStorage.ModuleEmailSMS === 'true' && this.state.prospect && this.state.prospect.IDProspect ?
          <span>
          {!this.state.prospect.SiteAffectation ?
            <AffectationSiteModal ref="affectSiteModal" prospect={this.state.prospect} onSiteSaved={(IDSite) => this.siteSaved(IDSite)}/>
          :null}
          <ModalSendMessage ref="modalSendMessage" prospect={this.state.prospect} affaires={this.state.affairesProspect} onMessageSent={() => this.refs.modalHistoriqueMessages.refresh()}/>
          </span>
        : null}

        {localStorage.ModuleEmailSMS && localStorage.ModuleEmailSMS === 'true' && this.state.prospect && this.state.prospect.IDProspect ?
          <div style={{display:this.state.showHistoriqueMessages?'block':'none'}}>
            <ModalHistoriqueMessages ref="modalHistoriqueMessages" prospectId={this.state.prospectId} onMessagesLoaded={(nbMessages) => this.setState({nbMessages:nbMessages})}/>
          </div>
        :null}

        {this.state.successCreate ?
          <SweetAlert success title='' onConfirm={() => this.propageSuccess()} confirmBtnText={localStorage.bypass_iFrame === 'true' && this.props.location.query.origin === 'DBS' ? "Fermez la fenêtre en utilisant la croix en haut à droite" : "OK"} confirmBtnBsStyle={localStorage.bypass_iFrame === 'true' && this.props.location.query.origin === 'DBS' ? 'link' : 'primary'}>
            <span>{this.state.successCreateMessage}</span>
          </SweetAlert>
        : null}

        {this.state.errorCreate ?
          <SweetAlert error title="Une erreur s'est produite" onConfirm={() => this.hideAlert()}>
            {this.state.errorCreateMessage}
          </SweetAlert>
        : null}

        <div className="page-sidebar-right-content">
          <div className="col-md-12">

            <form className="form-horizontal create-lead" onSubmit={this.handleSubmit.bind(this)}>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                  <FormOrigine onChangeValue={this.changeValue.bind(this)}
                    type={this.state.type}
                    canal={this.state.canal}
                    origine_famille={this.state.origine_famille}
                    origine_sousfamille={this.state.origine_sousfamille}
                    choiceOrigine={this.state.choiceOrigine}
                    campagne={this.state.campagne}
                    typesCreate={this.state.typesCreate}
                    typeActive={this.state.type}
                    defaultOrigine={this.state.defaultOrigine}
                    viewTypeAtelierAutre={this.props.location.query.origin && (this.props.location.query.origin.toString().substr(0, 4) === 'http' || this.props.location.query.origin === 'DBS') ? false : true}
                    ref='FormOrigine'
                    EstAppelSortant={this.state.EstAppelSortant}
                    appelSortantCreateLead={this.state.appelSortantCreateLead}
                    disabledEditOrigine={this.state.disabledEditOrigine}
                    typeAppelSortant={this.state.typeAppelSortant}
                    iframeOrigine={this.props.location.query.origin}
                    campagneFile={this.state.campagneFile}
                    leadAppelSortant={this.state.leadAppelSortant}
                    onErrorLoadingOrigines={localStorage.bypass_iFrame === 'true' ? this.errorLoadingOrigines.bind(this) : null}
                    />

                  <br />

                  <div className="row">
                    <div className="col-md-12">
                    <ul className="nav-tabs nav alt-style" style={{ marginRight: '-15px' }}>
                      <li className="active" style={{width:'100%'}}>
                        <a data-toggle="tab" href="#form-prospect">
                          <h2>
                            <img src="/img/PROSPECT.svg" role='presentation' /> Prospect
                            {this.state.type === 'Atelier' && this.state.historiquesAteliers.length ?
                            <span>
                              <i className="fa fa-info-circle" aria-hidden="true" style={{ marginLeft: '10px', fontSize: '14px' }}></i>
                              <TableRowSite Site={this.state.prospect ? this.state.prospect.SiteAffectation : null} style={{ color: 'white', fontSize: '12px', backgroundColor: '#3E4955', padding: '5px', paddingLeft: '10px', paddingRight: '10px', marginLeft: '10px',  }} />
                            </span>
                            : null}

                            {this.state.prospect ?
                              <span style={{ fontSize: '12px', marginLeft: '15px' }}>Réf. : {this.state.prospect.IDProspect}</span>
                            : null}

                            {this.state.prospect && this.state.prospect.SuiviPars && this.state.prospect.SuiviPars.length ?
                              <span style={{ fontSize: '12px', marginLeft: '15px' }}>
                                Suivi par : <span className='blue-c bold'>{this.state.prospect.SuiviPars[0].Nom}</span> &nbsp;
                                <Tooltip trigger={['hover']} overlay={<div>{this.state.prospect.SuiviPars.map((people, i) => { return <div key={i}>{people.Nom}</div> })}</div>}>
                                  <i className="fa fa-users" aria-hidden="true"></i>
                                </Tooltip>
                              </span>
                            : null}

                            {this.props.location.query.origin === 'CallWin' && this.state.prospects && this.state.prospect && this.state.prospectId && this.state.prospect.SuiviPars && this.state.prospect.SuiviPars.length ?
                              <span className='prospect-details-suivi' style={{ paddingLeft: 15, fontSize: '11px' }}>
                                Suivi par : <span className='blue-c bold'>{this.state.prospect.SuiviPars[0].Nom}</span> &nbsp;
                                <Tooltip trigger={['hover']} overlay={<div>{this.state.prospect.SuiviPars.map((people, i) => { return <div key={i}>{people.Nom}</div> })}</div>}>
                                  <i className="fa fa-users" aria-hidden="true"></i>
                                </Tooltip>
                              </span>
                            : null}

                            {this.state.lastRapport?
                              <span style={{ fontSize: '12px', marginLeft: '20px' }}>
                                Dernière action par : <span className='blue-c bold'> 
                                {this.state.lastRapport.PremiereActionEntrante && !this.state.lastRapport.InfoCreation.Utilisateur.EstPasserelle ?
                                  this.state.lastRapport.InfoCreation.Utilisateur.Nom
                                  :
                                  this.state.lastRapport.SuiviPar.Nom
                                }</span></span>
                              :null}

                          </h2>
                        </a>

                        {localStorage.DPOContactEnabled === 'true' && this.state.EstAppelSortant && this.state.prospect ?
                          <div className="pull-right">
                            <ButtonRGPD IDProspect={this.state.prospect.IDProspect} IDSite={this.state.site} IDSociete={this.state.societe} style={{marginRight: '5px', marginTop:'2px', padding:'8px', height:'30px', lineHeight:'15px'}}/>
                          </div>
                          : null
                        }

                        {localStorage.ModuleEmailSMS && localStorage.ModuleEmailSMS === 'true' && this.state.prospect && this.state.prospect.IDProspect ?
                          <div className="pull-right">
                            <button type="button" className="btn btn-default btn-sm" onClick={() => this.openModal("modalHistoriqueMessages")} style={{marginRight: '5px', marginTop:'2px', padding:'8px', height:'30px', lineHeight:'15px'}}>Historique des messages ({this.state.nbMessages})</button>
                            <button type="button" className="btn btn-default btn-sm" onClick={() => this.openModalSendMessage()} style={{marginRight: '5px', marginTop:'2px', padding:'8px', height:'30px', lineHeight:'15px'}}>Envoyer Email/SMS</button>
                          </div>
                          :null}
                      </li>
                    </ul>

                      {this.props.location.query.origin === 'CallWin' && this.state.prospects && !this.state.prospectId ?
                        <table className='table table-condensed' style={{ marginTop: '10px' }}>
                          <thead>
                            <tr>
                              <th>Nom</th>
                              <th>Prénom</th>
                              <th>Email</th>
                              <th>Téléphones</th>
                              <th>Adresse</th>
                              <th>Code postal</th>
                              <th>Ville</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.prospects.map((p) => {
                              return (<tr key={p.IDProspect}>
                                <td>{p.Nom}</td>
                                <td>{p.Prenom}</td>
                                <td>{p.EMail}</td>
                                <td>
                                  {p.Telephones.map(x => x.Numero).join(', ')}
                                </td>
                                <td>{p.Adresse}</td>
                                <td>{p.CodePostal}</td>
                                <td>{p.Ville}</td>
                                <td><a style={{cursor:'pointer'}} onClick={this.selectProspect.bind(this, p.IDProspect)}>Choisir</a></td>
                              </tr>)
                            })}
                          </tbody>
                        </table>
                      :null}

                      {this.props.location.query.origin === 'CallWin' && this.state.prospects && this.state.prospect && this.state.prospectId ?
                        <div>
                          <Link to={'/prospect/' + this.state.prospectId} style={{ marginLeft: '35px', fontSize: '12px', marginTop: '12px', display: 'inline-block'}}>Voir la fiche du prospect</Link>
                          <span> / </span>
                          <a onClick={(e) => {
                            this.setState({
                              prospectId: null,
                              vehicules:[],
                            })
                          }} style={{ fontSize: '12px', marginTop: '12px', display: 'inline-block'}}>Changer de prospect</a>
                        </div>
                      : null}

                      {this.state.prospectId && this.state.prospect ?
                        <ProspectFormEdit prospect={this.state.prospect} ref="ProspectFormEdit" noDisplayButtonSave={true} typesCreate={localStorage.bypass_iFrame && (this.props.location.query.origin !== 'CallWin') ? {"leads": []} : this.state.typesCreate} typeActive={this.state.type}/>
                      :
                        <ProspectFormCreate
                          onChange={this.changeProspect.bind(this)}
                          onChangeValue={this.changeValue.bind(this)}
                          defaultName={(this.props.location.query.name !== '') ? this.props.location.query.name : ''}
                          defaultEmail={(this.props.location.query.email !== '') ? this.props.location.query.email : ''}
                          defaultPhone={this.getDefaultPhoneProspect()}
                          lockProspectMecaplanning={this.state.lockProspectMecaplanning}
                          ref="ProspectFormCreate"
                        />
                      }

                    </div>
                  </div>

                  {/****** Parc Client ******/}
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="nav-tabs nav alt-style" style={{ marginRight: '-15px' }}>
                        <li className="active">
                          <a data-toggle="tab" href="#form-prospect">
                            {localStorage.IsAgri === 'true' ? 
                                <h2>
                                  <img src="/img/TRACTEUR.svg" role='presentation' /> Matériels en parc
                                </h2>
                              :
                                <h2>
                                  <img src="/img/AFFAIRE VO.svg" role='presentation' /> Véhicules en parc
                                </h2>
                            }
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-12" style={{paddingBottom:15}}>
                      <br />
                      <table className='table table-condensed' style={{marginBottom:'0px'}}>
                        <thead>
                          <tr>
                            <th></th>
                            <th>Marque</th>
                            <th>Modèle</th>
                            <th>Immat</th>
                            <th>Chassis</th>
                            <th>KMs</th>
                            <th className='text-center'>Historique atelier</th>
                            <th className='text-center'>Est possédé ?</th>
                            <th className='text-center'>
                              <Tooltip placement="left" trigger={['hover']} overlay={"Ajouter un "+ (localStorage.IsAgri === 'true' ? "matériel" : "véhicule")}>
                                  <a className="picto picto-add" onClick={(e) => this.refs.modalVehiculeParcClientCreate.show()}></a>
                              </Tooltip>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.vehicules && this.state.vehicules.length ?
                            this.state.vehicules.map((v) => {
                            var clientVehicule = lodash.findLast(v.LiensClients, (v) => {
                              return v.IDClient == this.state.prospectId;
                            });

                            return (<tr key={v.IDVehicule}>
                                <td>
                                  <input type="checkbox" name={"vehicule_"+v.IDVehicule} id={"vehicule_"+v.IDVehicule} onChange={this.changeVehicule.bind(this, v.IDVehicule)} checked={this.isCheckedVehicule(v.IDVehicule)} />
                                </td>
                                <td>{v.Marque}</td>
                                <td>{v.Modele}</td>
                                <td>{v.Immatriculation}</td>
                                <td>{v.VIN}</td>
                                <td>{v.Kilometrage}</td>
                                <td className='text-center'>
                                  {v.NombreHistoriqueAteliers ?
                                    <a onClick={(e) => {
                                      this.setState({
                                        VehiculeHistoriqueAtelier: v.IDVehicule,
                                      }, () => {
                                        this.refs.modalVehiculeHistoriqueAtelier.show()
                                      })
                                    }}>Voir</a>
                                  : null}
                                </td>
                                {this.state.prospectId ? 
                                  <td className='text-center'><input type='checkbox' name='EstPossede' checked={clientVehicule && clientVehicule.EstPossede} onChange={this.changeEstPossede.bind(this, v.IDVehicule, clientVehicule)} /></td>
                                :
                                  <td className='text-center'><input type='checkbox' checked={true} /></td>
                                }
                                <td className="action text-center">
                                    <Tooltip placement="left" trigger={['hover']} overlay="Modifier">
                                        <a className="picto picto-edit" onClick={(e)=> this.callEditVehicule(v.IDVehicule)}></a>
                                    </Tooltip>
                                </td>
                              </tr>)
                            })
                          
                          : (this.state.prospectId && !this.state.prospect) || (this.state.prospect && this.state.prospect.LiensVehicules && this.state.prospect.LiensVehicules.length) ?
                            <tr>
                              <td colSpan={9} className="text-center" style={{padding:20}}>
                              <i className="fa fa-refresh fa-spin fa-fw"></i>
                              &nbsp;Chargement des {localStorage.IsAgri === 'true' ? 'matériels' : 'véhicules'}
                              </td>
                            </tr>
                          : 
                            <tr>
                              <td colSpan={9} className="text-center" style={{padding:20}}> 
                                <em>Aucun {localStorage.IsAgri === 'true' ? 'matériel' : 'véhicule'}</em>
                              </td>
                            </tr> 
                        }    
                        </tbody>
                      </table>
                      {this.state.prospect && this.state.prospect.LiensVehicules && this.state.nbVehiculesLoaded < this.state.prospect.LiensVehicules.length && this.state.nbVehiculesLoaded === this.state.vehicules.length ? 
                        <div className="col-md-12 text-center" style={{cursor:'pointer'}} onClick={this.loadVehicules.bind(this, 10)}>
                          <a>Afficher plus de véhicules</a>
                        </div>
                      :
                      null}
                    </div>
                  </div>
                  {/******* Fin parc client ********/}


                  { (this.state.type === 'VN' && !this.state.EstAppelSortant)
                  || (this.state.EstAppelSortant && this.state.appelSortantCreateLead && this.state.typeAppelSortant === 'VN') ? <FormVN changeValue={this.changeValue.bind(this)} defaultComment={this.state.appelSortantComment} IDSite={this.state.site}/> : null}

                  {(this.state.type === 'VO' && !this.state.EstAppelSortant)
                  ||  (this.state.EstAppelSortant && this.state.appelSortantCreateLead &&  this.state.typeAppelSortant === 'VO')? <FormVO changeValue={this.changeValue.bind(this)} defaultComment={this.state.appelSortantComment} /> : null}

                  {(this.state.type === 'Atelier' && !this.state.EstAppelSortant)
                  ||  (this.state.EstAppelSortant && this.state.appelSortantCreateLead &&  this.state.typeAppelSortant === 'Atelier') ? <FormAtelier ref="FormAtelierRef" lockProspectMecaplanning={this.state.lockProspectMecaplanning} UrlMecaplanning={this.state.UrlMecaplanning} site={this.state.site} changeValue={this.changeValue.bind(this)} defaultComment={this.state.appelSortantComment} /> : null}

                  {(this.state.type === 'Autres' && !this.state.EstAppelSortant)
                  ||  (this.state.EstAppelSortant && this.state.appelSortantCreateLead &&  this.state.typeAppelSortant === 'Autres')  ? <FormAutres changeValue={this.changeValue.bind(this)} defaultComment={this.state.appelSortantComment} /> : null}

                  { (this.state.type === 'VDL' && !this.state.EstAppelSortant)
                  || (this.state.EstAppelSortant && this.state.appelSortantCreateLead && this.state.typeAppelSortant === 'VDL') ? <FormVDL changeValue={this.changeValue.bind(this)} defaultComment={this.state.appelSortantComment} /> : null}

                  { (this.state.type === 'Accessoires' && !this.state.EstAppelSortant)
                  || (this.state.EstAppelSortant && this.state.appelSortantCreateLead && this.state.typeAppelSortant === 'Accessoires') ? <FormAccessoires changeValue={this.changeValue.bind(this)} defaultComment={this.state.appelSortantComment} /> : null}

                  {this.state.EstAppelSortant && !this.state.appelSortantCreateLead ?
                    <FormAppelSortant
                      changeValue={this.changeValue.bind(this)}
                      appelSortantRelanceForm={this.state.appelSortantRelanceForm}
                      relanceDate={this.state.relanceDate}
                      relanceHeure={this.state.relanceHeure}
                      displayRaisonCloture={this.props.location.query.IDLead && (this.state.leadAppelSortant && this.state.leadAppelSortant.Campagne && this.state.leadAppelSortant.Campagne.IDCampagne) ? true : false}

                      />
                  : null}

                  {this.props.location.query.origin === 'CallWin' && (this.state.CallcenterTypeCreateLead === 'PriorisationKiamo' || this.state.CallcenterTypeCreateLead === 'PriorisationAppelManuel') ?

                      <div className="row">

                          <div className="col-md-3">
                              {this.state.type === 'VN' ?
                                  <FormSocieteSiteVN
                                      onChangeValue={this.changeValue.bind(this)}
                                      textColor="#304B55"
                                      marginleft="15%"
                                      marque={this.state.marque}
                                      societe={this.state.societe}
                                      site={this.state.site}
                                      typeActive={this.state.type}
                                      ref="formsocietesite"
                                      origin={this.props.location.query.origin} 
                                      location="CallWin" />
                                  : null}

                              {this.state.type === 'VO' ?
                                  <FormSocieteSiteVO
                                      onChangeValue={this.changeValue.bind(this)}
                                      textColor="#304B55"
                                      marginleft="15%"
                                      marque={this.state.marque}
                                      societe={this.state.societe}
                                      site={this.state.site}
                                      typeActive={this.state.type}
                                      ref="formsocietesite"
                                      origin={this.props.location.query.origin} 
                                      location="CallWin"/>
                                  : null}

                          </div>

                          <div className="col-md-9">
                            <FormSeller
                              onChangeValue={this.changeValue.bind(this)}
                              IDSite={this.state.site}
                              societe={this.state.societe}
                              site={this.state.site}
                              CallcenterTypeCreateLead={this.state.CallcenterTypeCreateLead}
                              />
                          </div>

                      </div>
                    : null}

                    {this.props.location.query.origin === 'CallWin' && (this.state.CallcenterTypeCreateLead === '') && this.state.societe != null && this.state.site && this.props.location.query.forcepotcommun !== "1" ?
                      <div className="col-md-10 col-md-offset-1">
                        <div className="col-md-6">
                          <div className="form-group" style={{ marginBottom: '0px' }}>
                            <div className="col-md-12" style={{ textAlign: 'center' }}>
                              <input type='checkbox' id='toVendeurCallwinOK' onChange={this.changeToVendeurCallwinOK.bind(this)} checked={this.state.ToVendeurCallwinOK} />
                              <label className="label-box" htmlFor="toVendeurCallwinOK" style={{fontSize: '14px', fontWeight: 'bold'}}>Lead transmis</label>
                            </div>
                          </div>

                          {this.state.ToVendeurCallwinOK ?
                            <div className="form-group">
                              <div className="col-md-12">
                                <select className="form-control" id="vendeur" name="vendeur" onChange={this.changeVendeurCallWin.bind(this)} defaultValue={this.state.todoVendeur} value={this.state.todoVendeur}>
                                  <option value="0">Vendeur</option>
                                  {this.getAllUsers().map((utilisateur, i) => {
                                      return <option key={i} value={utilisateur.IDUtilisateur}>{utilisateur.Nom}</option>
                                  })}
                                </select>
                              </div>
                            </div>
                          : null}
                        </div>

                        <div className="col-md-6">
                          <div className="form-group" style={{ marginBottom: '0px' }}>
                            <div className="col-md-12" style={{ textAlign: 'center' }}>
                              <input type='checkbox' id='toVendeurCallwinNOK' onChange={this.changeToVendeurCallwinNOK.bind(this)} checked={this.state.ToVendeurCallwinNOK} />
                              <label className="label-box" htmlFor="toVendeurCallwinNOK" style={{fontSize: '14px', fontWeight: 'bold'}}>Lead non transmis</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    : null}

                  {this.state.EstAppelSortant && !this.state.appelSortantCreateLead && !this.state.appelSortantRelanceForm ?
                    <div className="col-md-12" style={{ marginBottom: '15px', marginTop: '15px', }}>
                      <div className="text-center">
                        <button type="submit" className="text-center btn btn-default submit-vo" disabled={this.disabledButtonValid() || (this.props.location.query.IDLead && (this.state.leadAppelSortant && this.state.leadAppelSortant.Campagne && this.state.leadAppelSortant.Campagne.IDCampagne) && this.state.appelSortantDisableCloture)} style={{ marginRight: '15px'}}>
                          {this.state.fetchCreateInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null} Clôturer
                        </button>

                        <button type="submit" className="text-center btn btn-default submit-vo" disabled={this.disabledButtonValid() || (this.props.location.query.IDLead && (this.state.leadAppelSortant && this.state.leadAppelSortant.Campagne && this.state.leadAppelSortant.Campagne.IDCampagne) && this.state.appelSortantDisableRelance)} style={{ marginRight: '15px'}} onClick={this.appelSortantRelance.bind(this)}>
                          {this.state.fetchCreateInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null} Relance
                        </button>

                        <button type="submit" className="text-center btn btn-default submit-vo" disabled={this.disabledButtonValid()} style={{ marginRight: '15px'}} onClick={this.appelSortantCreateLead.bind(this)}>
                          {this.state.fetchCreateInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null} Créer un lead
                        </button>
                      </div>
                    </div>
                  : null}

                  {!this.state.EstAppelSortant || (this.state.EstAppelSortant && this.state.appelSortantCreateLead) ?
                    <div className="col-md-12" style={{ marginBottom: '15px', marginTop: '15px', }}>
                      <div className="text-center">
                        <button type="submit" className="text-center btn btn-default submit-vo" disabled={this.disabledButtonValid()}>
                          {this.state.fetchCreateInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null} Valider {this.state.type !== 'Atelier' && this.state.type !== 'Autres' ? 'le lead' : ''}
                        </button>

                        {!this.props.location.query.origin ?
                          <button type="submit" className="text-center btn btn-default submit-vo" disabled={this.disabledButtonCancel()} style={{marginLeft: '10px'}} onClick={() => {
                            if (window.confirm('Êtes-vous certains de vouloir annuler la création du lead ?')) {
                              if(this.state.EstAppelSortant)
                                this.cancelAppelSortantCreateLead();
                              else
                                window.location.reload();
                            }
                          }}>
                            {this.state.fetchCreateInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null} Annuler
                          </button>
                        : null}
                      </div>
                    </div>
                  : null}

                  {this.state.EstAppelSortant && this.state.appelSortantRelanceForm ?
                    <div className="col-md-12" style={{ marginBottom: '15px', marginTop: '15px', }}>
                      <div className="text-center">
                        <button type="submit" className="text-center btn btn-default submit-vo" style={{ marginRight: '15px'}} onClick={this.cancelAppelSortantRelance.bind(this)}>
                          {this.state.fetchCreateInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null} Annuler
                        </button>
                        <button type="submit" className="text-center btn btn-default submit-vo" disabled={this.disabledButtonValid()} style={{ marginRight: '15px'}}>
                          {this.state.fetchCreateInProgress ? <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> : null} Créer la relance
                        </button>
                      </div>
                    </div>
                  : null}
                </div>

              </div>
            </form>

          </div>
        </div>

        <div className="page-sidebar-right-sidebar dark-skin" style={!Auth.loggedIn() ? {paddingTop: '0'} : null}>

          <div className="page-sidebar-right-sidebar-container">
            {localStorage.IsVDL === 'true' ? 
              <FormSocieteSiteVDL
                onChangeValue={this.changeValue.bind(this)}
                marque={this.state.marque}
                societe={this.state.societe}
                site={this.state.site}
                typeActive={this.state.type}
                callwin={this.props.location.query.called ? true : false}
                ref="formsocietesite"
                origin={this.props.location.query.origin} />
            :
              <span>
              {this.state.type === 'VN'  && !this.state.EstAppelSortant?
                <FormSocieteSiteVN
                  onChangeValue={this.changeValue.bind(this)}
                  marque={this.state.marque}
                  societe={this.state.societe}
                  site={this.state.site}
                  typeActive={this.state.type}
                  prospect={this.state.prospect}
                  callwin={this.props.location.query.called ? true : false}
                  ref="formsocietesite"
                  origin={this.props.location.query.origin} />
              : null}

              {this.state.type &&  this.state.type !== 'Atelier' && this.state.type !== "Autres" && this.state.EstAppelSortant?
                <FormSocieteSiteAppelSortant
                  onChangeValue={this.changeValue.bind(this)}
                  marque={this.state.marque}
                  prospect={this.state.prospect}
                  societe={this.state.societe}
                  site={this.state.site}
                  typeActive={this.state.type}
                  ref="formsocietesite"
                  origin={this.props.location.query.origin} />
              : null}

              {this.state.type === 'VO' && !this.state.EstAppelSortant ?
                <FormSocieteSiteVO
                  onChangeValue={this.changeValue.bind(this)}
                  marque={this.state.marque}
                  societe={this.state.societe}
                  site={this.state.site}
                  typeActive={this.state.type}
                  callwin={this.props.location.query.called ? true : false}
                  ref="formsocietesite"
                  origin={this.props.location.query.origin} />
              : null}

              {this.state.type === 'Atelier' || this.state.type === 'Autres'?
                <FormSocieteSiteAtelier
                  onChangeValue={this.changeValue.bind(this)}
                  marque={this.state.marque}
                  societe={this.state.societe}
                  site={this.state.site}
                  typeActive={this.state.type}
                  ref="formsocietesite"
                  origin={this.props.location.query.origin} />
              : null}
              </span>
            }

            {localStorage.access_affectationUtilisateur === 'true' && (!this.state.EstAppelSortant || (this.state.EstAppelSortant && this.state.appelSortantCreateLead)) &&
            (this.state.type === 'Atelier' || this.state.type === 'Autres') && this.state.site && localStorage.eSellerLeadAtelierMailRapport === 'false' ?
              <FormToDoCreateAtelierAutres
                onChangeValue={this.changeValue.bind(this)}
                todoToVendeur={this.state.todoToVendeur}
                todoVendeur={this.state.todoVendeur}
                site={this.state.site}
                typeActive={this.state.type} />
            : null}

            {localStorage.access_affectationUtilisateur === 'true' && (!this.state.EstAppelSortant || (this.state.EstAppelSortant && this.state.appelSortantCreateLead)) &&
              (this.state.type === 'Atelier' || this.state.type === 'Autres') && this.state.site && localStorage.eSellerLeadAtelierMailRapport === 'true' ?
              <FormToDoCreateAtelierAutresModeDegrade
                onChangeValue={this.changeValue.bind(this)}
                todoToVendeur={this.state.todoToVendeur}
                todoVendeur={this.state.todoVendeur}
                site={this.state.site}
                typeActive={this.state.type} />
            : null}

            {localStorage.access_affectationUtilisateur === 'true' && (!this.state.EstAppelSortant || (this.state.EstAppelSortant && this.state.appelSortantCreateLead)) && (this.state.type === 'VN' || this.state.type === 'VO') && this.state.site ?
              <FormToDoCreate
                openWeekCalendar={this.openModelWeekCalendar.bind(this)}
                onChangeValue={this.changeValue.bind(this)}
                todoDate={this.state.todoDate}
                todoHeure={this.state.todoHeure}
                todoHeureFin={this.state.todoHeureFin}
                todoToVendeur={this.state.todoToVendeur}
                todoVendeur={this.state.todoVendeur}
                site={this.state.site}
                todoEtape={this.state.todoEtape}
                todoComment={this.state.todoComment}
                typeActive={this.state.type} />
            : null}

            {this.state.site && localStorage.access_creationRelance === 'true' && this.state.todoToVendeur && this.state.type !== 'Atelier' && this.state.type !== 'Autres' ?
              <FormRelance
                onChangeValue={this.changeValue.bind(this)}
                relance={this.state.relance}
                relanceDate={this.state.relanceDate}
                relanceHeure={this.state.relanceHeure}
                relanceAction={this.state.relanceAction}
                relanceCommentaire={this.state.relanceCommentaire}
                />
            : null}

            <div className='clearfix'></div>

          </div>

        </div>

      </div>
    );

}
}

export default LeadCreate;
