import React, { Component } from 'react';
import { Link } from 'react-router';
import FilterOrigine from './FilterOrigine';
import FilterInteret from './FilterInteret';
import FilterEtape from './FilterEtape';
import FilterSocieteSite from './FilterSocieteSite';
import FilterVendeur from './FilterVendeur';
import FilterType from './FilterType';
import FilterMarque from './FilterMarque';
var lodash = require('lodash');

class Sidebar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showFilters: false,
      currentLocation: '',

      //Les cases pré-cochées car paramètres de filtrages définis dans l'url
      queryFilters: {
        "sites":[],
        "etapes":[],
        "interets":[],
        "origines":[],
        "vendeurs":[],
        "marques":[],
      }
    }
  }

  componentDidUpdate(){
    var currentLocation = window.location.pathname;
    if(currentLocation !== this.state.currentLocation) {
      var initFilters = [];
      var queryFiltersPresent = false;

      if(this.props.queryFilters){
        var queryFilters = this.props.queryFilters;
        for(var type in this.state.queryFilters){
          if(!queryFilters[type] || queryFilters[type] == "")
            continue;

          queryFiltersPresent = true;
          if(type == 'sites')
            initFilters[type] = lodash.map(queryFilters[type].split(","), (f) => {return parseInt(f);});
          else 
            initFilters[type] = queryFilters[type].split(",");
        }
      }

      this.setState({
        queryFilters: initFilters,
        showFilters: queryFiltersPresent,
        currentLocation: currentLocation
      });
    }
  }

  onClick(event) {
    if (event.target.href === window.location.href) {
      this.setState({ showFilters: !this.state.showFilters });
    }
  }

  render() {

    return (
      <div  className="container-fluid">
        <div className="row">
          <div className="col-md-12">

            <Link to={'/leads/LeadsARedistribuer'} className={"btn btn-default linkToList " + (this.state.showFilters ? 'withFilters' : '')} activeClassName="active" onClick={this.onClick.bind(this)}>
              Leads à redistribuer <span></span>
            </Link>

            {this.state.currentLocation === '/leads/LeadsARedistribuer' && this.state.showFilters ?
              <div>
                <FilterSocieteSite leads={this.props.leads} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['sites']} />
                <FilterOrigine type={this.props.type} leads={this.props.leads} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['origines']}/>
                <FilterType typeAffichage={this.props.type} leads={this.props.leads} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['types']}/>
                <FilterMarque marques={this.props.marques} leads={this.props.leads} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['marques']}/>
              </div>
            : null}



            {localStorage.user_profil === 'BDC' ?
              <div>
                <br/>
                <br/>

                <Link to={'/leads/AffairesEncoursBDC'} className={"btn btn-orange linkToList " + (this.state.showFilters ? 'withFilters' : '')} activeClassName="active" onClick={this.onClick.bind(this)}>
                  Leads en cours de traitement <span></span>
                </Link>

                {this.state.currentLocation === '/leads/AffairesEncoursBDC' && this.state.showFilters ?
                  <div>
                    <FilterEtape etapes={this.props.etapes} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['etapes']} />
                    <FilterInteret interets={this.props.interets} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['interets']} />
                    <FilterOrigine type={this.props.type} leads={this.props.leads} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['origines']}/>
                    <FilterSocieteSite leads={this.props.leads} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['sites']}/>
                    <FilterVendeur vendeurs={this.props.vendeurs} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['vendeurs']}/>
                    <FilterType typeAffichage={this.props.type} leads={this.props.leads} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['types']}/>
                    <FilterMarque marques={this.props.marques} leads={this.props.leads} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['marques']}/>
                  </div>
                : null}
              </div>

            : null}

            <br/><br/>

            <Link to={'/leads/AffairesEncoursVendeur'} className={"btn btn-warning linkToList " + (this.state.showFilters ? 'withFilters' : '')} activeClassName="active" onClick={this.onClick.bind(this)}>
              Affaires en cours <span></span>
            </Link>

            {this.state.currentLocation === '/leads/AffairesEncoursVendeur' && this.state.showFilters ?
              <div>
                <FilterEtape etapes={this.props.etapes} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['etapes']}/>
                <FilterInteret interets={this.props.interets} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['interets']}/>
                <FilterOrigine type={this.props.type} leads={this.props.leads} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['origines']}/>
                <FilterSocieteSite leads={this.props.leads} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['sites']}/>
                <FilterVendeur vendeurs={this.props.vendeurs} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['vendeurs']}/>
                <FilterType typeAffichage={this.props.type} leads={this.props.leads} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['types']}/>
                <FilterMarque marques={this.props.marques} leads={this.props.leads} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['marques']}/>
              </div>
            : null}

            <br /><br />

            <Link to={'/leads/AffairesGagnees'} className={"btn btn-success linkToList " + (this.state.showFilters ? 'withFilters' : '')} activeClassName="active" onClick={this.onClick.bind(this)}>
              Gagnés <span></span>
            </Link>

            {this.state.currentLocation === '/leads/AffairesGagnees' && this.state.showFilters ?
              <div>
                <FilterOrigine type={this.props.type} leads={this.props.leads} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['origines']}/>
                <FilterSocieteSite leads={this.props.leads} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['sites']}/>
                <FilterVendeur vendeurs={this.props.vendeurs} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['vendeurs']}/>
                <FilterType typeAffichage={this.props.type} leads={this.props.leads} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['types']}/>
              </div>
            : null}

            <br /><br />

            <Link to={'/leads/AffairesClotures'} className={"btn btn-danger linkToList " + (this.state.showFilters ? 'withFilters' : '')} activeClassName="active" onClick={this.onClick.bind(this)}>
              Clôturés <span></span>
            </Link>

            {this.state.currentLocation === '/leads/AffairesClotures' && this.state.showFilters ?
              <div>
                <FilterOrigine type={this.props.type} leads={this.props.leads} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['origines']}/>
                <FilterSocieteSite leads={this.props.leads} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['sites']}/>
                <FilterVendeur vendeurs={this.props.vendeurs} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['vendeurs']}/>
                <FilterType typeAffichage={this.props.type} leads={this.props.leads} onChange={this.props.onChangeFilters} selected={this.state.queryFilters['types']}/>
              </div>
            : null}

          </div>
        </div>
      </div>
    );
  }
}

export default Sidebar;
