import React, { Component } from 'react';
import { Link } from 'react-router';
import HistoriqueProspect from './Pages/HistoriqueProspect.js'
import Scheduler from './Pages/Scheduler.js'
import NewsModal from './Pages/News/Modal'
import SkyLight from 'react-skylight';
import { browserHistory } from 'react-router'
import {MobileOnlyView, CustomView, isMobileOnly} from "react-device-detect";

import ReactGA from 'react-ga';

class Header extends Component {

  constructor(props) {
    super(props);

    this.state = {
      displayListProduct: false,
    }
  }

  //handle change of page and popup closure
  handleProspectRedirect(attr, value){
    this.refs.modalHistoriqueProspect.hide();
    browserHistory.push('/prospect/' + value);
  }

  getNbLeads(type){
    return this.props.nbLeads[type] ? this.props.nbLeads[type] : 0;
  }

  genererMenuVendeur() {

    var currentLocation = window.location.pathname;
    var splitLocation = currentLocation.split('/');
    var baseLocation = splitLocation[1] ? splitLocation[1] : '';

    return <ul className="nav navbar-nav navbar-nav-main">
      
        <li className={currentLocation === '/prospect' ? 'active' : ''}>
          <Link to={'/prospect'} className="text-center" onClick={() => ReactGA.event({category: 'Menu', action: 'Créer un lead'})}>
            Contacts
          </Link>
        </li>

        <li className={currentLocation === '/stocks/vn' ? 'active' : ''}>
          <Link to={'/stocks/vn'} className="text-center" onClick={() => ReactGA.event({category: 'Menu', action: 'Stock VN'})}>
            Stock VN
          </Link>
        </li>

        <li className={currentLocation === '/stocks/vo' ? 'active' : ''}>
          <Link to={'/stocks/vo'} className="text-center" onClick={() => ReactGA.event({category: 'Menu', action: 'Stock VO'})}>
            Stock VO
          </Link>
        </li>
        
        <li className={(currentLocation === '/leads/LeadsARedistribuer' || currentLocation === '/leads/AffairesEncoursBDC' || currentLocation === '/leads/AffairesEncoursVendeur' || currentLocation === '/leads/AffairesGagnees' || currentLocation === '/leads/AffairesClotures') ? 'active' : ''}>
          <Link to={'/leads/LeadsARedistribuer'} className="text-center" onClick={() => ReactGA.event({category: 'Menu', action: 'Suivi des leads VN / VO'})}>
            Leads
          </Link>
        </li>

        <li className={currentLocation === '/actions' ? 'active' : ''}>
          <Link to={'/actions'} className="text-center" onClick={() => ReactGA.event({category: 'Menu', action: 'Action à faire'})}>
            Actions
          </Link>
        </li>

        <li className={currentLocation === '/offres' ? 'active' : ''}>
          <Link to={'/offres'} className="text-center" onClick={() => ReactGA.event({category: 'Menu', action: 'Offres'})}>
            Offres
          </Link>
        </li>

      </ul>
  }

  genererMenu() {

    var currentLocation = window.location.pathname;
    var splitLocation = currentLocation.split('/');
    var baseLocation = splitLocation[1] ? splitLocation[1] : '';

    return <ul className="nav navbar-nav navbar-nav-main">
        {localStorage.user_profil !== 'Conseiller' && localStorage.user_profil !== 'Magasinier' && localStorage.IsAgri !== 'true' && localStorage.IsMoto !== 'true' ?
          <li className={currentLocation === '/prospect' ? 'active' : ''}>
            <Link to={'/prospect'} className="text-center" onClick={() => ReactGA.event({category: 'Menu', action: 'Créer un lead'})}>
              Créer un lead
            </Link>
          </li>
        : null}
        {/* TODO: Le === 'undefined' sert à ce que les utilisateurs déjà connectés au moment de la mise en prod n'aient pas leur menu VN/VO qui disparaissent et soient obligés de déconnecter / reconnecter
          * A supprimer à partir de 24h après mise en prod (pareil pour dashboard) */}
        {localStorage.access_leadsVNVO === 'true' || localStorage.access_leadsVNVO === undefined || localStorage.access_leadsVNVO === 'undefined' ?
          <li className={(currentLocation === '/leads/LeadsARedistribuer' || currentLocation === '/leads/AffairesEncoursBDC' || currentLocation === '/leads/AffairesEncoursVendeur' || currentLocation === '/leads/AffairesGagnees' || currentLocation === '/leads/AffairesClotures') ? 'active' : ''}>
            <Link to={'/leads/LeadsARedistribuer'} className="text-center" onClick={() => ReactGA.event({category: 'Menu', action: 'Suivi des leads VN / VO'})}>
              {localStorage.IsVDL === 'true' ? 'Suivi des leads VDL' 
              :localStorage.IsAgri === 'true' ? 'Suivi des leads Matériels'
              :'Suivi des leads VN / VO'} 
              {localStorage.user_profil === 'BDC' && this.props.nbLeads !== null ? " ("+(this.getNbLeads("VN") + this.getNbLeads("VO"))+")" : null}
            </Link>
          </li>
        : null}
        {localStorage.access_leadsApv === 'true' ?
          <li className={currentLocation.indexOf('/leadsAtelier') !== -1 ? 'active' : ''}>
            <Link to={localStorage.eSellerLeadAtelierMailRapport === 'true' ? 
                        '/leadsAtelier/TypeAtelier' 
                      :localStorage.user_profil === "Conseiller" ?
                        '/leadsAtelier/AffairesEncours/Prise de RDV' 
                      :'/leadsAtelier/AffairesEncours/ATraiter'} 
              className="text-center" onClick={() => ReactGA.event({category: 'Menu', action: 'Suivi leads Atelier'})}>
              {localStorage.IsAgri === 'true' ? 'Suivi des leads Atelier' : 'Suivi leads APV'}
              {localStorage.user_profil === 'BDC' && this.props.nbLeads !== null ? " ("+this.getNbLeads("Atelier")+")" : null}
            </Link>
          </li>
        : null}
        {localStorage.IsVDL === 'true' ?
          <li className={(baseLocation === 'leadsAccessoires') ? 'active' : ''}>
            <Link to={'/leadsAccessoires/AffairesEncours'} className="text-center" onClick={() => ReactGA.event({category: 'Menu', action: 'Leads Accessoires'})}>
              Suivi leads Accessoires
            </Link>
          </li>
        : null}
        {localStorage.user_profil !== 'Conseiller' && localStorage.user_profil !== 'Magasinier' && localStorage.IsAgri !== 'true' && localStorage.IsMoto !== 'true' ?
          <li className={currentLocation === '/actions' ? 'active' : ''}>
            <Link to={'/actions'} className="text-center" onClick={() => ReactGA.event({category: 'Menu', action: 'Action à faire'})}>
              Action à faire
            </Link>
          </li>
        : null}
        {localStorage.esellerLeadCampagne && localStorage.esellerLeadCampagne === 'true' && localStorage.user_profil !== 'Conseiller' && localStorage.user_profil !== 'Magasinier' && localStorage.IsAgri !== 'true' && localStorage.IsMoto !== 'true' ?
          <li className={currentLocation === '/campagnes' ? 'active' : ''}>
            <Link to={'/campagnes'} className="text-center">
              Campagnes
            </Link>
          </li>
        : null}
        {localStorage.access_reporting === 'true' && localStorage.style === "nissan" ?
            <li className="dropdown">
              <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Reporting <span className="caret"></span></a>
              <ul className="dropdown-menu">
                <li><Link to={'/reporting'} className="text-center" target="_blank" style={{ paddingTop: '12px', paddingBottom: '12px', height: 'auto' }}>Reporting</Link></li>
                <li><Link to={'/reporting/nissan'} className="text-center" target="_blank" style={{ paddingTop: '12px', paddingBottom: '12px', height: 'auto' }}>Suivi d'activité</Link></li>
              </ul>
            </li>
        : null}

        {localStorage.access_reporting === 'true' && localStorage.style !== "nissan" && localStorage.IsAgri !== 'true' && localStorage.IsMoto !== 'true' ?
          <li className={currentLocation === '/reporting' ? 'active' : ''}>
            <Link to={'/reporting'} className="text-center" target="_blank">
              Reporting
            </Link>
          </li>
        : null}

      </ul>
  }

  render() {
    var currentLocation = window.location.pathname;
    var splitLocation = currentLocation.split('/');
    var baseLocation = splitLocation[1] ? splitLocation[1] : '';

    return (
      <div>

      <nav className={"navbar navbar-default navbar-fixed-top hidden-print " + (localStorage.style === "nissan" ? "nissan" : "")}>
        <div className="container-fluid">
          <div className="navbar-header">
            <button type="button" className="collapsed navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-6" aria-expanded="false">
              <span className="sr-only">Menu</span>
              <span className="icon-bar">
              </span>
              <span className="icon-bar">
              </span>
              <span className="icon-bar">
              </span>
            </button>

            <div style={{ position: 'absolute', zIndex: '99', left: '12px', top: '12px' }} onClick={() => {
              this.setState({displayListProduct: !this.state.displayListProduct})
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '50px', height: '50px', color: '#9A0059', cursor: 'pointer' }} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </div>

            {this.state.displayListProduct ?
              <div style={{ position: 'absolute', zIndex: '99', left: '0px', top: '80px', width: '240px' }} >
                <Link to={'/dashboard'} className="navbar-brand produit-poste-vendeur">
                  <img src="/img/logo_eSeller-lead_blanc.png" role="presentation" />
                </Link>
                <Link to={'/dashboard'} className="navbar-brand produit-market">
                  <img src="/img/logo_eSeller-lead_blanc.png" role="presentation" />
                </Link>
                <Link to={'/dashboard'} className="navbar-brand produit-backoffice">
                  <img src="/img/logo_eSeller-lead_blanc.png" role="presentation" />
                </Link>
                <Link to={'/dashboard'} className="navbar-brand produit-report">
                  <img src="/img/logo_eSeller-lead_blanc.png" role="presentation" />
                </Link>
                <Link to={'/dashboard'} className="navbar-brand produit-vo">
                  <img src="/img/logo_eSeller-lead_blanc.png" role="presentation" />
                </Link>
                <Link to={'/dashboard'} className="navbar-brand produit-edatavoice">
                  <img src="/img/logo_eSeller-lead_blanc.png" role="presentation" />
                </Link>
              </div>
            : null}
            

            <Link to={'/dashboard'} className="navbar-brand">
              {localStorage.style === "nissan" ?
                <div className="navbar-title">
                  <img src="/img/logo_nissan_blanc.png" role="presentation" />
                  SMIT Lead
                </div>
              :
                <img src={localStorage.style === "magir" ? "/img/Logo_Magir_blanc.png" : "/img/logo_eSeller-lead_blanc.png"} role="presentation" />
              }
            </Link>
            
            <MobileOnlyView>
                <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                  <div className="user-box">
                    <div className="profil-picture">
                      {localStorage.user_profil === 'Conseiller' ?
                        <img src="/img/Conseiller.png" role="presentation" />
                      : null}

                      {localStorage.user_profil === 'BDC' || localStorage.user_profil === 'WelcomePro' ?
                        <img src="/img/OPERATRICE.png" role="presentation" />
                      : null}

                      {localStorage.user_profil === 'ChefDesVentes' ?
                        <div className='initiales'>{localStorage.user_initiales}</div>
                      : null}
                    </div>
                    <div className="profil-names">
                      {localStorage.user_name} {localStorage.user_profil ? "(" + localStorage.user_profil + ")" : null}
                      &nbsp;<img src="/img/Arrow_Yellow.svg" className="profil-toggle" role='presentation' />
                    </div>
                  </div>
                </a>
                <ul className="dropdown-menu" style={{left:'auto',right:60}}>
                  <li><Link to={'/logout'}>Déconnexion <i className="icon-power icons"></i></Link></li>
                </ul>
            </MobileOnlyView>

          </div>

          
          <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-6">
            
            {localStorage.user_profil == 'Vendeur' ?
              this.genererMenuVendeur()
            : null}

            {localStorage.user_profil == 'ChefDesVentes' || localStorage.user_profil == 'BDC' || localStorage.user_profil == 'WelcomePro' || localStorage.user_profil == 'Conseiller' ?
              this.genererMenu()
            : null}

            <CustomView condition={!isMobileOnly}>
            <ul className="nav navbar-nav navbar-right">
              <li className="dropdown">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                  <div className="user-box">
                    <div className="profil-picture">
                      {localStorage.user_profil === 'Conseiller' ?
                        <img src="/img/Conseiller.png" role="presentation" />
                      : null}

                      {localStorage.user_profil === 'BDC' || localStorage.user_profil === 'WelcomePro' ?
                        <img src="/img/OPERATRICE.png" role="presentation" />
                      : null}

                      {localStorage.user_profil === 'ChefDesVentes' ?
                        <div className='initiales'>{localStorage.user_initiales}</div>
                      : null}
                    </div>
                    <div className="profil-names">
                      <div className="line-name">{localStorage.user_name}</div>
                      <div className="line-profile">
                      {
                        localStorage.user_profil ? 
                          localStorage.user_profil === "ChefDesVentes" ? 
                            "(Manager)"
                          :
                            "(" + localStorage.user_profil + ")"
                        :null}
                      </div>
                    </div>
                    <img src="/img/Arrow_Yellow.svg" className="profil-toggle" role='presentation' />
                  </div>
                </a>
                <ul className="dropdown-menu">
                  <li><a href="#" onClick={(e) => {this.refs.newsModal.fetchNewsAndShow(); e.preventDefault();}}>News <i className=" icon-envelope-open icons"></i></a></li>
                  <li><a onClick={() => {this.refs.modalAgendaHeader.show(); ReactGA.event({category: 'Menu', action: 'Calendrier vendeurs'}); }}>Calendrier vendeurs <i className="icon-calendar icons"></i></a></li>
                  {/*<li><Link to={'/holidays'}>Gestion des absences <i className="icon-calendar icons"></i></Link></li>*/}
                  <li><a onClick={() => {this.refs.modalHistoriqueProspect.show(); ReactGA.event({category: 'Menu', action: 'Profils consultés'}); }}>Profils consultés <i className="icon-people icons"></i></a></li>
                    {localStorage.user_profil === 'ChefDesVentes' ?
                      <li><Link to={'/gestionVendeurs'}>Gestion des vendeurs <i className="icon-organization icons"></i></Link></li>
                    : null}
                    {localStorage.user_profil === 'ChefDesVentes' ?
                      <li><Link to={'/objectifsVendeurs'}>Objectifs vendeurs <i className="icon-target icons"></i></Link></li>
                    : null}
                  <li><Link to={'/holidays'}>Gestion des absences <i className="icon-event icons"></i></Link></li>
                  <li><Link to={'/logout'}>Déconnexion <i className="icon-power icons"></i></Link></li>
                </ul>
              </li>
            </ul>
            </CustomView>
          </div>
        </div>
      </nav>

      <SkyLight hideOnOverlayClicked ref="modalAgendaHeader" dialogStyles={{ width: '90%', left: '5%', marginLeft: '0%', height: '90%', top: '5%', marginTop: '0%', overflow: 'scroll', zIndex: 9999}}>
        <div className='inner-large-content'>
          <Scheduler />
        </div>
      </SkyLight>

      <SkyLight hideOnOverlayClicked ref="modalHistoriqueProspect" dialogStyles={{ width: '60%', left: '5%', marginLeft: '20%', height: '90%', top: '5%', marginTop: '0%', overflow: 'scroll', zIndex: 9999}}>
        <div className='inner-large-content'>
          <HistoriqueProspect onChange={this.handleProspectRedirect.bind(this)}/>
        </div>
      </SkyLight>

      <NewsModal ref="newsModal"/>

      </div>
    );
  }
}

export default Header;