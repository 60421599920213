import React, {Component} from 'react';
import Globals from '../Globals';

var lodash = require('lodash')

class FormVN extends Component {

  constructor(props) {
    super(props);

    this.state = {
      plaques: [],
      marques: [],
      modeles:[],
      marque : '',
      modele : '',
      budget: '',
      energie : '',
      boite : '',
      comment: this.props.defaultComment,
      reprise : false,
      ventemarchand : false,
      financement: false,
      demandeessai: false,

      fetchingModeles: false,

      //Spécifique Agri
      saisieMarqueMan: false,
    }
  }

  componentDidMount() {
    this.fetchMarques();
  }

  componentDidUpdate(prevProps){
    if(prevProps.IDSite != this.props.IDSite && localStorage.IsAgri !== 'true')
      this.loadMarques();
  }

  fetchMarques() {
    if(localStorage.IsAgri === 'true')
    {
      this.fetchMarquesFromCatalogue();
      return;
    }

    fetch(localStorage.ClientApiURL + '/Plaques', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        plaques: json
      }, () => {
        this.loadMarques();
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  loadMarques(){
    var marques = [];

    lodash.map(this.state.plaques, (plaque) => {
      var sites = lodash.filter(plaque.Sites, (site) => {
        return site.Est_eSellerLeadActif === true;
      });

      if (!sites.length) {
        return;
      }

      var site;
      if(this.props.IDSite){ 
        site = lodash.find(sites, (s) => {return s.IDSite == this.props.IDSite});

        if(site){
          lodash.each(site.Marques, (marque) => {
            marques.push(marque.Description);
          });
          if(marques.length === 0){ //Si le site n'a pas de marque on prend celles de la société
            lodash.each(plaque.Marques, (marque) => {
              marques.push(marque.Description);
            })
          }
          return;
        }
      }
      else { //Si pas de site précisé on prend les marques de tout le groupe
        lodash.each(sites, (site) => {
          lodash.each(site.Marques, (marque) => {
            marques.push(marque.Description);
          });
        });
      }
    });

    marques = lodash.uniq(marques);

    this.setState({
      marques: marques,
      marque: marques.length === 1 ? marques[0] : ''
    }, () => {
      if(marques.length === 1) {
        this.props.changeValue('commentVN', this.generateComment());
        if(this.state.marque.toLowerCase() === "nissan")
          this.fetchModeles();
      }
    });
  }

  fetchMarquesFromCatalogue(){
    fetch(localStorage.ClientApiURL + '/referentiel/marques', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        marques: JSON.parse(json),
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchModeles(){
    this.setState({
      fetchingModeles: true
    })
    fetch(localStorage.ClientApiURL + '/nissan/modeles', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      this.setState({
        modeles: json,
        fetchingModeles: false
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  generateComment() {
    let comment = '';

    if(localStorage.IsAgri === 'true'){
      comment += 'Marque : ' + this.state.marque + '\n';
      comment += 'Modèle : ' + this.state.modele + '\n';
      comment += 'Prix : ' + this.state.budget + '\n';
      comment += 'Financement : ' + (this.state.financement ? 'oui' : 'non') + '\n';
      comment += 'Demande de démonstration : ' + (this.state.demandeessai ? 'oui' : 'non') + '\n';
      comment += 'Commentaire :\n' + this.state.comment + '\n';
    }
    else {
      comment += 'Marque : ' + this.state.marque + '\n';
      comment += 'Modèle : ' + this.state.modele + '\n';
      comment += 'Energie : ' + this.state.energie + '\n';
      comment += 'Boîte : ' + this.state.boite + '\n';
      comment += 'Prix : ' + this.state.budget + '\n';

      comment += 'Reprise : ' + (this.state.reprise ? 'oui' : 'non') + '\n';
      comment += 'Vente à marchand : ' + (this.state.ventemarchand ? 'oui' : 'non') + '\n';
      comment += 'Financement : ' + (this.state.financement ? 'oui' : 'non') + '\n';
      comment += 'Demande d\'essai : ' + (this.state.demandeessai ? 'oui' : 'non') + '\n';
      comment += 'Commentaire :\n' + this.state.comment + '\n';
    }

    return comment;
  }

  updateChangeValue(event){
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name == "marque" && value == "_SAISIE-MANUELLE_"){
      this.setState({
        marque: '',
        saisieMarqueMan: true
      }, () => this.refs.saisieMarqueMan.focus());
      return;
    }

    var newState = {
      [name]: value
    }

    if(name === "marque" && value.toLowerCase() === "nissan"){
      this.fetchModeles();
      newState.modele = "";
    }

    this.setState(
     newState
    , () => {
      this.props.changeValue('commentVN', this.generateComment());
    });
  }

  renderAgri(){
    return (
      <div className="panel panel-default form-interet">
      <ul className="nav-tabs nav alt-style" style={{ marginRight: '-15px' }}>
        <li className="active">
          <a data-toggle="tab" href="#form-vn">
            <h2>
              <img src="/img/AFFAIRE MN.svg" role='presentation' /> Interêt Neuf
            </h2>
          </a>
        </li>
      </ul>

      <div className="row">
        <div className="col-md-4 col-xs-12">
          <div className="form-group">
            <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Marque</label>
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              {localStorage.IsAgri === 'true' ? 
                this.state.saisieMarqueMan ? 
                  <input type="text" name="marque" className="form-control" ref="saisieMarqueMan" onChange={this.updateChangeValue.bind(this)} />
                :
                  <select className='form-control' name="marque" onChange={evt => this.updateChangeValue(evt)}>
                    <option value=""></option>
                    <option value="_SAISIE-MANUELLE_">Saisir une marque</option>
                    {this.state.marques.map((marque, i) => {
                      return (<option key={i} value={marque.Descr}>{marque.Descr}</option>)
                    })}
                  </select>
              :
                <select className='form-control' name="marque" onChange={evt => this.updateChangeValue(evt)}>
                  <option value=""></option>
                  {this.state.marques.map((marque) => {
                    return (<option key={marque} value={marque}>{marque}</option>)
                  })}
                </select>
              }
            </div>
          </div>

          <div className="form-group">
            <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Modèle</label>
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              <input type="text" name="modele" className="form-control" onChange={evt => this.updateChangeValue(evt)} />
            </div>
          </div>
        </div>


        <div className="col-md-4 col-xs-12">
          <div className="form-group">
            <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Prix</label>
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              <input type="text" name="budget" className="form-control" onChange={evt => this.updateChangeValue(evt)}/>
            </div>
          </div>

          <div className="form-group">
            
          </div>
        </div>

        <div className="col-md-1 col-xs-12"></div>
        <div className="col-md-4 col-xs-12">
          <div className="form-group">
            <div className="col-md-12">
                <textarea  name="comment" id="comment" style={{ width: '100%' }} placeholder="Commentaire" onChange={evt => this.updateChangeValue(evt)} value={this.state.comment}></textarea>
            </div>
          </div>
          <div className="form-group checkbox-lines">
            <div className="col-md-6 col-xs-12">
              <input type="checkbox" name="reprise" id="reprise" onChange={evt => this.updateChangeValue(evt)} />
              <label className="label-box" htmlFor="reprise"> Reprise </label>
            </div>

            <div className="col-md-6 col-xs-12">
              <input type="checkbox" name="financement" id="financement" onChange={evt => this.updateChangeValue(evt)} />
              <label className="label-box" htmlFor="financement"> Financement </label>
            </div>

            <div className="col-md-6 col-xs-12">
              <input type="checkbox" name="demandeessai" id="demandeessai" onChange={evt => this.updateChangeValue(evt)} />
              <label className="label-box" htmlFor="demandeessai" style={{whiteSpace: 'nowrap'}}> Demande de démonstration </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }

  render() {
    if(localStorage.IsAgri === 'true')
      return this.renderAgri();

    return (
      <div className="panel panel-default form-interet">
      <ul className="nav-tabs nav alt-style" style={{ marginRight: '-15px' }}>
        <li className="active">
          <a data-toggle="tab" href="#form-vn">
            <h2>
              <img src="/img/AFFAIRE VN.svg" role='presentation' /> Interêt VN
            </h2>
          </a>
        </li>
      </ul>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <div className="form-group">
            <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Marque</label>
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              <select className='form-control' name="marque" onChange={evt => this.updateChangeValue(evt)} value={this.state.marque}>
                <option value=""></option>
                {this.state.marques.map((marque) => {
                  return (<option key={marque} value={marque}>{marque}</option>)
                })}
              </select>
            </div>
          </div>

          <div className="form-group">
            <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Modèle</label>
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              {this.state.marque.toLowerCase() === 'nissan' ? 
                <select className='form-control' name="modele" onChange={evt => this.updateChangeValue(evt)} required="required">
                  <option value="">{this.state.fetchingModeles ? "Chargement des modèles..." : ""}</option>
                  {this.state.modeles.map((modele) => {
                    return (<option key={modele} value={modele}>{modele}</option>)
                  })}
                </select>
              :
                <input type="text" name="modele" className="form-control" onChange={evt => this.updateChangeValue(evt)} />
              }
            </div>
          </div>

          <div className="form-group">
            <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Prix</label>
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              <input type="text" name="budget" className="form-control" onChange={evt => this.updateChangeValue(evt)}/>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-xs-12">
          <div className="form-group">
            <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Énergie</label>
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              <select name="energie" className="form-control" onChange={evt => this.updateChangeValue(evt)}>
                <option value=""></option>
                <option value="Essence">Essence</option>
                <option value="Diesel">Diesel</option>
                <option value="GPL">GPL</option>
                <option value="Electrique">Electrique</option>
                <option value="Hybride">Hybride</option>
              </select>
            </div>
          </div>

          <div className="form-group">
            <label className="col-lg-3 col-md-4 col-sm-3 col-xs-12 control-label">Boîte</label>
            <div className="col-lg-9 col-md-8 col-sm-9 col-xs-12">
              <select name="boite" className="form-control" onChange={evt => this.updateChangeValue(evt)}>
                <option value=""></option>
                <option value="Automatique">Automatique</option>
                <option value="Manuelle">Manuelle</option>
              </select>
            </div>
          </div>
        </div>

        <div className="col-md-1 col-xs-12"></div>
        <div className="col-md-4 col-xs-12">
          <div className="form-group">
            <div className="col-md-12">
                <textarea  name="comment" id="comment" style={{ width: '100%' }} placeholder="Commentaire" onChange={evt => this.updateChangeValue(evt)} value={this.state.comment}></textarea>
            </div>
          </div>
          <div className="form-group checkbox-lines">
            <div className="col-md-6 col-xs-12">
              <input type="checkbox" name="reprise" id="reprise" onChange={evt => this.updateChangeValue(evt)} />
              <label className="label-box" htmlFor="reprise"> Reprise </label>
            </div>

            <div className="col-md-6 col-xs-12">
              <input type="checkbox" name="financement" id="financement" onChange={evt => this.updateChangeValue(evt)} />
              <label className="label-box" htmlFor="financement"> Financement </label>
            </div>

            <div className="col-md-6 col-xs-12">
              <input type="checkbox" name="demandeessai" id="demandeessai" onChange={evt => this.updateChangeValue(evt)} />
              <label className="label-box" htmlFor="demandeessai"> Demande d'essai </label>
            </div>
          </div>
        </div>
      </div>

      </div>
    );
  }
}

export default FormVN;
