import React, { Component } from 'react';

import { browserHistory } from 'react-router';

import Loader from '../Components/Loader';
import Globals from '../Globals';

class IframeV5Stocks extends Component {

  constructor(props) {
    super(props);

    this.state = {

    }
  }

  componentDidMount() {

  }

  render() {

    var url = 'https://' + localStorage.urlEseller + '/iframe_v5.asp?LoginWEB=' + localStorage.user_login + '&Password=' + localStorage.user_password + '&Redirect=stock' + this.props.params.type;

    return (
        <iframe src={url} style={{ border: 'none', width: '100%', height: '700px' }} />
    );
  }
}

export default IframeV5Stocks;
