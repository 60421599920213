export function GetNestedData(root, path) {
  var paths = path.split('.');
  var last_index = paths.length - 1;
  var value = "";
  for(var i in paths){
      var key = paths[i];
      if (!root[key]) break;
      if (i==last_index) {value = root[key]; break;}
      root = root[key];
  }
  return value;
}

export function SetNestedData(root, path, value) {
  var paths = path.split('.');
  var last_index = paths.length - 1;
  paths.forEach(function(key, index) {
    if (!(key in root)) root[key] = {};
    if (index==last_index) root[key] = value;
    root = root[key];
  });
  return root;
}

export function SetAppStyleAndTitle() {
  switch(localStorage.style)
  {
    case 'nissan':
      document.getElementsByTagName('title')[0].text = "SMIT Lead";
    break;
    case 'magir':
      document.getElementById('favicon').href = process.env.PUBLIC_URL+"/favicon_magir.ico";
      document.getElementById('stylesheet').href = process.env.PUBLIC_URL+"/css/style-magir.css";
      document.getElementsByTagName('title')[0].text = "M'Agir Lead";
    break;
    default:
      //Le favicon par défaut est défini dans index.html
      document.getElementsByTagName('title')[0].text = "eSeller Lead";
    break;
  }
}