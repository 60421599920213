import React, { Component } from 'react';
import Globals from '../../Globals';
import SkyLight from 'react-skylight';
import InputMask from 'react-input-mask';
import Tooltip from "rc-tooltip";
var lodash = require('lodash')

class ListInterlocuteurs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: false, //Récupération des interlocuteurs en cours
            loaded:false, //Les interlocuteurs ont été récupérés
            saving:false,  //Sauvegarde d'un interlocuteur en cours
            savingResult:"", //Résultat de la sauvegarde 
            interlocuteurs:[], //Liste des interlocuteurs
            fonctions:[], //Liste des fonctions (dirigeant, secrétaire, etc...)

            interlocuteurEdit:{
                IDInterlocuteur:0,
                Nom:"",
                Prenom:"",
                IDFonction:0,
                Telephone:"",
                Email:""
            },
        }
    }

    componentDidMount(){
        this.fetchInterlocuteurs();
        this.fetchFonctions();
    }

    fetchInterlocuteurs() {
        if(!this.props.IDProspect || this.props.IDProspect === null) {
            this.setState({
                loaded:true,
            })
            return;
        }
        this.setState ({
            fetching:true,
        })
        fetch(localStorage.ClientApiURL + '/Prospects/' + this.props.IDProspect + '/interlocuteurs', {
            method: 'GET',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
            },
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            //console.log(json)
            this.setState({
                fetching:false,
                loaded:true,
                interlocuteurs: json,
            });
        })
        .catch((error) => {
            console.log(error)
        });
    }

    fetchFonctions() {
        fetch(localStorage.ClientApiURL + '/Prospects/interlocuteurs/fonctions', {
            method: 'GET',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
            },
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            var interlocuteurEdit = this.state.interlocuteurEdit;
            interlocuteurEdit.IDFonction = json[0].IDFonction;
            this.setState({
                fonctions: json,
                interlocuteurEdit: interlocuteurEdit
            });
        })
        .catch((error) => {
            console.log(error)
        });
    }

    canSubmit(){
        return (!this.state.saving && !this.state.fetching);
    }

    changeModalFormValue(target) {
        var interlocuteurEdit = this.state.interlocuteurEdit;
        var value;
        if (target.name === 'Prenom') {
            value = target.value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}); // uppercase all first letter of words
        }
        else if (target.name === 'Nom') {
            value = target.value.toUpperCase();
        }
        else 
            value = target.value;
        interlocuteurEdit[target.name] = value;
        this.setState({
            interlocuteurEdit: interlocuteurEdit
        });
    }

    showModalAddInterlocuteur() {
        if(!this.state.saving){
            this.setState({
                interlocuteurEdit:{
                    IDInterlocuteur:0,
                    Nom:"",
                    Prenom:"",
                    IDFonction:this.state.fonctions[0]?this.state.fonctions[0].IDFonction:0,
                    Telephone:"",
                    Email:""
                }
            });
            this.refs.interlocuteurModal.show();
        }
    }

    showModalEditInterlocuteur(interloc) {
        if(!this.state.saving){
            this.setState({
                interlocuteurEdit: {
                    IDInterlocuteur:interloc.IDInterlocuteur,
                    Nom:interloc.Nom,
                    Prenom:interloc.Prenom,
                    IDFonction:interloc.IDFonction,
                    Telephone:interloc.Telephone,
                    Email:interloc.Email
                }
            });
            this.refs.interlocuteurModal.show();
        }
    }

    submitInterlocuteur() {
        if(this.state.interlocuteurEdit.Nom.trim() === "" && this.state.interlocuteurEdit.Prenom.trim() === ""){
            alert("Vous devez indiquer au moins un nom ou un prénom");
            return false;
        }
        if(this.state.interlocuteurEdit.Telephone !== "" && !this.state.interlocuteurEdit.Telephone.match(/0(1|2|3|4|5|6|7|8|9)[0-9.]{12}/)){
            alert("N° de téléphone incorrect");
            return false;
        }
        if(this.state.interlocuteurEdit.Email !== "" && !this.state.interlocuteurEdit.Email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)){
            alert("Email incorrect");
            return false;
        }
        if(!this.state.interlocuteurEdit.IDFonction){
            alert("Veuillez indiquer la fonction");
            return false;
        }

        if(this.props.IDProspect === null){
            this.setInterlocuteur(0, this.state.interlocuteurEdit);
            this.refs.interlocuteurModal.hide();
            this.setState({
                interlocuteurEdit:{
                    IDInterlocuteur:0,
                    Nom:"",
                    Prenom:"",
                    IDFonction:this.state.fonctions[0].IDFonction,
                    Telephone:"",
                    Email:""
                },
            });
            return;
        }

        var method, request, body;
        if(this.state.interlocuteurEdit.IDInterlocuteur !== 0){
            method = "PUT";
            request = "/interlocuteurs/"+this.state.interlocuteurEdit.IDInterlocuteur;
            body = this.state.interlocuteurEdit;
        }
        else {
            method = "POST";
            request = "/interlocuteurs";
            body = [this.state.interlocuteurEdit];
        }

        this.setState({
            saving: true,
            savingResult: "",
        });
        fetch(localStorage.ClientApiURL + '/Prospects/' + this.props.IDProspect + request, {
            method:method,
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
            },
            body: JSON.stringify(body)
        })
        .then((response) => {
            if (!response.ok) {
                this.setState({
                    saving: false,
                    savingResult: "ko",
                });
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            //console.log(json);
            var IDInterloc;
            if(method === 'POST')IDInterloc = json[0];
            else IDInterloc = this.state.interlocuteurEdit.IDInterlocuteur;
            this.setInterlocuteur(IDInterloc, this.state.interlocuteurEdit);
            this.setState({
                saving: false,
                savingResult: "ok",
                interlocuteurEdit:{
                    IDInterlocuteur:0,
                    Nom:"",
                    Prenom:"",
                    IDFonction:this.state.fonctions[0].IDFonction,
                    Telephone:"",
                    Email:""
                },
            }, () => {
                setTimeout(() => {
                    this.refs.interlocuteurModal.hide();
                    this.setState({
                        savingResult: "",
                    });
                }, 500)
            });
        })
        .catch((error) => {
            console.log(error)
        });
    }

    submitInterlocuteurs(IDProspect){
        fetch(localStorage.ClientApiURL + '/Prospects/' + IDProspect + '/interlocuteurs', {
            method:'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
            },
            body: JSON.stringify(this.state.interlocuteurs)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then(json => {
            //console.log(json);
        })
        .catch((error) => {
            console.log(error)
        });
    }

    deleteInterlocuteur(interloc){
        if(!window.confirm("Supprimer définitivement l'interlocuteur \""+interloc.Nom+" "+interloc.Prenom+"\" ?")){
            return false;
        }

        if(this.props.IDProspect === null){
            var interlocuteurs = this.state.interlocuteurs;
            lodash.remove(interlocuteurs, function(i) {
                return i.IDInterlocuteur === interloc.IDInterlocuteur;
            });
            this.setState({
                interlocuteurs:interlocuteurs
            });
            return;
        }

        fetch(localStorage.ClientApiURL + '/Prospects/' + this.props.IDProspect + '/interlocuteurs/'+interloc.IDInterlocuteur, {
            method:'DELETE',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'APIKey': Globals.API_KEY,
            'IDClient': localStorage.user_IDClient,
            'Login': localStorage.user_login,
            'Password': localStorage.user_password
            },
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            var interlocuteurs = this.state.interlocuteurs;
            lodash.remove(interlocuteurs, function(i) {
                return i.IDInterlocuteur === interloc.IDInterlocuteur;
            });
            this.setState({
                interlocuteurs:interlocuteurs
            });
        })
        .catch((error) => {
            console.log(error)
        });

    }

    setInterlocuteur(IDInterloc, interlocuteur){
        //console.log(interlocuteur);
        var interlocuteurs = this.state.interlocuteurs;
        var newInterlocuteur = interlocuteur;
        newInterlocuteur.NomFonction = lodash.find(this.state.fonctions, f => {return f.IDFonction === parseInt(newInterlocuteur.IDFonction, 10);}).Nom;
        if(IDInterloc !== 0)
            newInterlocuteur.IDInterlocuteur = IDInterloc;
        else if(newInterlocuteur.IDInterlocuteur === 0) 
            newInterlocuteur.IDInterlocuteur = Date.now().toString(36)+Math.random().toString(36).substr(2, 5);
        var idx = lodash.findIndex(interlocuteurs, i => {return i.IDInterlocuteur === newInterlocuteur.IDInterlocuteur});

        if(idx !== -1){
            interlocuteurs[idx] = newInterlocuteur;
        }
        else {
            interlocuteurs.push(newInterlocuteur);
        }

        this.setState({
            interlocuteurs: interlocuteurs
        });
    }

    render() {
        if(!this.state.loaded)
            return null;

        const formatTelFR = localStorage.eSellerLeadFormatTelFR == 'true';

        return (
            <div className="row">
                <div className="col-md-12">
                    <table className="table-striped list list-interlocuteurs" style={{minWidth:'300px'}}>
                        <thead>
                            <tr>
                                <th colSpan={6}>Interlocuteurs</th>
                            </tr>
                            <tr>
                                <th>Nom</th>
                                <th>Fonction</th>
                                <th>Téléphone</th>
                                <th>Mail</th>
                                <th colSpan={2} style={{textAlign:'right'}}>
                                    <Tooltip placement="left" trigger={['hover']} overlay="Ajouter un interlocuteur">
                                        <a className="picto picto-add" onClick={() => this.showModalAddInterlocuteur()}></a>
                                    </Tooltip>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.interlocuteurs.length > 0 ?
                                this.state.interlocuteurs.map((interloc, i) => {
                                    return(<tr key={"interlocuteur"+i}>
                                        <td>{interloc.Nom} {interloc.Prenom}</td>
                                        <td>{interloc.NomFonction}</td>
                                        <td>{interloc.Telephone}</td>
                                        <td>{interloc.Email}</td>
                                        <td className="action">
                                            <Tooltip placement="left" trigger={['hover']} overlay="Modifier">
                                                <a className="picto picto-edit" onClick={() => this.showModalEditInterlocuteur(interloc)}></a>
                                            </Tooltip>
                                        </td>
                                        <td className="action">
                                            <Tooltip placement="left" trigger={['hover']} overlay="Supprimer">
                                                <a className="picto picto-delete" onClick={() => this.deleteInterlocuteur(interloc)}></a>
                                            </Tooltip>
                                        </td>
                                    </tr>);
                                })
                            :
                            <tr><td colSpan={6}>Aucun interlocuteur</td></tr>
                            }
                        </tbody>
                    </table>
                </div>

                <SkyLight hideOnOverlayClicked ref="interlocuteurModal"
                    dialogStyles={{ padding: 0, width: '50%', maxWidth: '600px', minWidth: '300px', height: 'auto', left: '0', right: '0', top: '25%', margin: 'auto', zIndex: 9999, background: "#efefef" }}
                    title={this.state.interlocuteurEdit.IDInterlocuteur !== 0 ? "Modifier interlocuteur" : "Ajouter un interlocuteur"}
                    titleStyle={{ padding:10, margin:0, color:'white', fontSize:'18px'}}
                    closeButtonStyle={{color:'white'}}>
                    <div className="inner-large-content" style={{ height: '100%', padding: '20px' }}>
                        <div className="form-group" style={{marginTop:'0px',marginLeft:'0px',marginRight:'0px'}}>
                            <label className="col-sm-3 col-xs-12">Nom</label>
                            <div className="col-sm-9 col-xs-12">
                                <input type="text" className="form-control" ref="Nom" name="Nom" onChange={(e) => this.changeModalFormValue(e.target)} value={this.state.interlocuteurEdit.Nom}/>
                            </div>
                        </div>
                        <div className="form-group" style={{marginTop:'0px',marginLeft:'0px',marginRight:'0px'}}>
                            <label className="col-sm-3 col-xs-12">Prénom</label>
                            <div className="col-sm-9 col-xs-12">
                                <input type="text" className="form-control" name="Prenom" onChange={(e) => this.changeModalFormValue(e.target)} value={this.state.interlocuteurEdit.Prenom}/>
                            </div>
                        </div>
                        <div className="form-group" style={{marginTop:'0px',marginLeft:'0px',marginRight:'0px'}}>
                            <label className="col-sm-3 col-xs-12">Fonction</label>
                            <div className="col-sm-9 col-xs-12">
                                <select className="form-control" name="IDFonction" onChange={(e) => this.changeModalFormValue(e.target)} value={this.state.interlocuteurEdit.IDFonction ? this.state.interlocuteurEdit.IDFonction : ""}>
                                    {this.state.fonctions.map((fonction) => {
                                        return (
                                            <option value={fonction.IDFonction} key={"fonction"+fonction.IDFonction}>{fonction.Nom}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="form-group" style={{marginTop:'0px',marginLeft:'0px',marginRight:'0px'}}>
                            <label className="col-sm-3 col-xs-12">Téléphone</label>
                            <div className="col-sm-9 col-xs-12">
                                <InputMask type="text" className="form-control" name="Telephone" mask={formatTelFR ? "99.99.99.99.99" : null} maskChar=" " pattern={formatTelFR ? "0(1|2|3|4|5|6|7|8|9)[0-9.]{12}" : null} onChange={(e) => this.changeModalFormValue(e.target)} value={this.state.interlocuteurEdit.Telephone}/>
                            </div>
                        </div>
                        <div className="form-group" style={{marginTop:'0px',marginLeft:'0px',marginRight:'0px'}}>
                            <label className="col-sm-3 col-xs-12">Email</label>
                            <div className="col-sm-9 col-xs-12">
                                <input type="email" className="form-control" name="Email" onChange={(e) => this.changeModalFormValue(e.target)} value={this.state.interlocuteurEdit.Email}/>
                            </div>
                        </div>
                        <div className="form-group text-right" style={{margin:'0px'}}>
                            {this.state.saving ?
                                <span style={{ marginRight: '15px' }}>
                                    <i className="fa fa-refresh fa-spin fa-fw" aria-hidden="true"></i>&nbsp;Enregistrement en cours
                                </span>
                                :
                                this.state.savingResult !== "" ?
                                    this.state.savingResult === "ko" ?
                                        <span className="text-danger" style={{ marginRight: '15px' }}>
                                            <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Erreur lors de l'enregistrement de l'interlocuteur
                                </span>
                                        :
                                        this.state.savingResult === "ok" ?
                                            <span className="text-success" style={{ marginRight: '15px' }}>
                                                <i className="fa fa-check-circle" aria-hidden="true"></i>&nbsp;Interlocuteur enregistré
                                </span>
                                            : null
                                    : null
                            }
                            <button type="button" className="btn btn-default btn-sm" disabled={!this.canSubmit()} onClick={() => this.submitInterlocuteur()}>Valider</button>
                        </div>
                    </div>
                </SkyLight>
            </div>
        );
    }
}

export default ListInterlocuteurs;

