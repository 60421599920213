import React, { Component } from 'react';
import { Link } from 'react-router';

import Globals from '../Globals';
import FilterOrigine from '../Leads/FilterOrigine';
import FilterInteret from '../Leads/FilterInteret';
import FilterAction from '../Leads/FilterAction';
import FilterSocieteSite from '../Leads/FilterSocieteSite';
import FilterCampagne from '../Leads/FilterCampagne';
import TableRowCampagneOrigine from '../Components/TableRowCampagneOrigine';
import TableRowComment from '../Components/TableRowComment';
import TableRowDateRelance from '../Components/TableRowDateRelance';
import TableRowSite from '../Components/TableRowSite';

import Loader from '../Components/Loader';
import FilterDate from '../Components/FilterDate';

import PastilleVendeur from '../Components/PastilleVendeur';

import moment from 'moment'
var lodash = require('lodash')

class Actions extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      leads: [],
      filters: [],
      distinctEtapes: [],
      distinctActions: [],
      distinctInterets: [],
      distinctCampagnes: [],
      displaySidebar: true,
      cacheLead: [],
      selectedProspect: null,
      dateStart: moment().startOf('days'),
      dateEnd: moment().endOf('days'),
    }
  }

  componentWillMount() {
    this.fetchDatas();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.type !== this.props.params.type) {
      this.setState({
        leads: [],
        cacheLead: [],
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.params.type === prevProps.params.type) {
      return;
    }

    this.setState({
      leads: [],
      filters: [],
      distinctEtapes: [],
      distinctActions: [],
      distinctInterets: [],
      distinctVendeurs: [],
      distinctCampagnes: [],
      cacheLead: [],
      selectedProspect: null,
      fetchInProgress: false,
      dateStart: moment().startOf('days'),
      dateEnd: moment().endOf('days'),
    }, () => {
      this.fetchDatas();
    })
  }

  async fetchDatas() {
    this.setState({
      fetchInProgress: true,
    });

    var leads = [];

    if (localStorage.access_superviseurBDC === 'true') {

      var mes_sites = !(localStorage.EstSuperviseur === 'true');

      await fetch(localStorage.ClientApiURL + '/Leads?premiere_action_entrante=&a_redisribuer=&mes_sites='+mes_sites+'&type_profil=BDC&date_relance_fin='+moment(this.state.dateEnd).format('Y-MM-DDT23:59:59'), {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'APIKey': Globals.API_KEY,
          'IDClient': localStorage.user_IDClient,
          'Login': localStorage.user_login,
          'Password': localStorage.user_password
        },
      })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        leads = json;
      })
      .catch((error) => {
        console.log(error)
      });

    }

    fetch(localStorage.ClientApiURL + '/utilisateurs/'+localStorage.user_IDUtilisateur+'/rapports?date_relance_fin='+moment(this.state.dateEnd).format('Y-MM-DDT23:59:59'), {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {

      leads = lodash.uniqBy(lodash.concat(json, leads), "IDLead");

      this.setState({
        leads: leads,
      }, () => {
        this.generateCacheLeads();
        this.generateDistinctActions();
        this.generateDistinctEtapes();
        this.generateDistinctInterets();
        this.generateDistinctCampagnes();
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  generateDistinctActions() {
    var actions = lodash.countBy(this.state.leads, function(lead) {
      return lead.ActionAFaire_TODO.ActionAFaire ? lead.ActionAFaire_TODO.ActionAFaire.Description : "";
    });
    this.setState({distinctActions: actions});
  }

  generateDistinctEtapes() {
    var etapes = lodash.countBy(this.state.leads, function(lead) {
      return lead.ActionAFaire_TODO.Etape ? lead.ActionAFaire_TODO.Etape.Description : "";
    });
    this.setState({distinctEtapes: etapes});
  }

  generateDistinctCampagnes() {
    var campagnes = lodash.countBy(this.state.leads, function(lead) {
      return lead.Campagne ? lead.Campagne.Nom : '';
    });
    this.setState({distinctCampagnes: campagnes});
  }

  generateDistinctInterets() {
    var niveaux = [];

    var interets = lodash.countBy(this.state.leads, function(lead) {

      if (niveaux.indexOf(lead.ActionAFaire_TODO.NiveauInteret.Niveau) < 0) {
        niveaux[lead.ActionAFaire_TODO.NiveauInteret.Niveau] = {
          name: lead.ActionAFaire_TODO.NiveauInteret.Description,
          niveau: lead.ActionAFaire_TODO.NiveauInteret.Niveau
        }
      }
      return lead.ActionAFaire_TODO.NiveauInteret.Niveau;
    });

    lodash.forIn(interets, function(value, key){
      niveaux[key].count = value;
    });

    this.setState({distinctInterets: niveaux});
  }

  generateCacheLeads() {
    var leads = this.state.leads;

    if (this.state.filters) {
      if (this.state.filters['origines'] && this.state.filters['origines'].length) {
        var filter_origines = this.state.filters['origines'];
        leads = lodash.filter(leads, function(lead) {
          var check = lead.ActionFaite_DO.OrigineAction ? lead.ActionFaite_DO.OrigineAction.Famille+"-"+lead.ActionFaite_DO.OrigineAction.SousFamille+"-"+lead.ActionFaite_DO.OrigineAction.Origine : "";
          return filter_origines.indexOf(check) > -1;
        });
      }

      if (this.state.filters['actions'] && this.state.filters['actions'].length) {
        var filter_actions = this.state.filters['actions'];
        leads = lodash.filter(leads, function(lead) {
          var check = lead.ActionAFaire_TODO.ActionAFaire ? lead.ActionAFaire_TODO.ActionAFaire.Description : "";
          return filter_actions.indexOf(check) > -1;
        });
      }

      if (this.state.filters['etapes'] && this.state.filters['etapes'].length) {
        var filter_etapes = this.state.filters['etapes'];
        leads = lodash.filter(leads, function(lead) {
          var check = lead.ActionAFaire_TODO.Etape ? lead.ActionAFaire_TODO.Etape.Description : "";
          return filter_etapes.indexOf(check) > -1;
        });
      }

      if (this.state.filters['sites'] && this.state.filters['sites'].length) {
        var filter_sites = this.state.filters['sites'];
        leads = lodash.filter(leads, function(lead) {
          return filter_sites.indexOf(lead.Site ? lead.Site.IDSite : "") > -1;
        });
      }

      if (this.state.filters['vendeurs'] && this.state.filters['vendeurs'].length) {
        var filter_vendeurs = this.state.filters['vendeurs'];
        leads = lodash.filter(leads, function(lead) {
          return filter_vendeurs.indexOf(lead.SuiviPar.Nom) > -1;
        });
      }

      if (this.state.filters['campagnes'] && this.state.filters['campagnes'].length) {
        var filter_campagnes = this.state.filters['campagnes'];
        leads = lodash.filter(leads, function(lead) {
          return filter_campagnes.indexOf(lead.Campagne ? lead.Campagne.Nom : '') > -1;
        });
      }
    }

    leads.sort(function(a, b) {
      var date_a, date_b;

      date_a = moment(a.ActionAFaire_TODO.DateHRelance);
      date_b = moment(b.ActionAFaire_TODO.DateHRelance);

      if (!date_a || !date_b) {
        return -1;
      }

      if (date_a < date_b)
        return -1;

      if ((date_a.format('DMY HH:mm') === date_b.format('DMY HH:mm')) && (parseInt(a.IDLead, 10) && parseInt(b.IDLead, 10))) {
        if (parseInt(a.IDLead, 10) < parseInt(b.IDLead, 10)) {
          return -1;
        }
      }

      return 1;
    });

    this.setState({cacheLead: leads, fetchInProgress: false})
  }

  onChangeFilters(type, datas) {
    var currentFilters = this.state.filters;
    currentFilters[type] = datas;
    this.setState({filters: currentFilters}, () => {
      this.generateCacheLeads();
    });
  }

  onChangeDate(start, end) {
    this.setState({
      dateStart: start,
      dateEnd: end,
    }, () => {
      this.fetchDatas();
    })
  }

  reloadLeads() {
    this.fetchDatas();
  }

  render() {
    return (
      <div>
        <div className="page-sidebar-left">

          <div id="sidebar" className='page-sidebar-left-sidebar'>
            <FilterCampagne campagnes={this.state.distinctCampagnes} onChange={this.onChangeFilters.bind(this)} />
            <FilterAction actions={this.state.distinctActions} onChange={this.onChangeFilters.bind(this)} />
            <FilterOrigine leads={this.state.leads} onChange={this.onChangeFilters.bind(this)} />
            <FilterSocieteSite leads={this.state.leads} onChange={this.onChangeFilters.bind(this)} />
          </div>

          <div className='page-sidebar-left-content'>
            <div className="">
            <FilterDate onChangeDate={this.onChangeDate.bind(this)} type={this.props.params.type} className='pull-left' />

            <div className='pull-right' style={{ marginTop: '5px', marginRight: '10px' }}>{this.state.cacheLead.length} actions</div>

            {this.state.fetchInProgress === false ?
              <div className=''>
                <div className="wrap-table">
                  <table className="table table-eseller-lead table-striped table-hover table-condensed" style={{ tableLayout: 'fixed' }}>
                    <thead>
                      <tr>
                        <th style={{ width: '80px', }}>Type</th>
                        <th style={{ width: '100px', }}>Site</th>
                        <th style={{ width: '60px', }} className='text-center'>Suivi</th>
                        <th style={{ width: '200px', }}>Campagne / Origine</th>
                        <th>Etape</th>
                        <th>Contact</th>
                        <th>Téléphone</th>
                        <th>Action</th>
                        <th>Relance</th>
                        <th style={{ width: '40px', }} className='text-center'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.cacheLead.map((lead, i) => {
                        return <tr key={i}>
                          <td>
                            {lead.Affaire && lead.Affaire.OrigineAction ? lead.Affaire.Type : null}
                          </td>
                          <td style={{ overflow: 'hidden' }}>
                            <TableRowSite Site={lead.Site} />
                          </td>
                          <td className='text-center'>
                            <PastilleVendeur utilisateur={lead.SuiviPar} />
                          </td>
                          <td>
                            <TableRowCampagneOrigine campagne={lead.Campagne} origine={lead.ActionFaite_DO.OrigineAction} />
                          </td>
                          <td>{lead.ActionAFaire_TODO.Etape ? lead.ActionAFaire_TODO.Etape.Description : null}</td>
                          <td>
                            <Link to={'/prospect/' + lead.Prospect.IDProspect}>
                              {lead.Prospect.Nom} {lead.Prospect.Prenom}
                            </Link>
                          </td>
                          <td>
                            {lead.Prospect.Telephones.length ? lead.Prospect.Telephones[0].Numero : null}
                          </td>
                          <td>
                            {lead.ActionAFaire_TODO.ActionAFaire ? lead.ActionAFaire_TODO.ActionAFaire.Description : ''}
                          </td>
                          <td>
                            {lead.ActionFaite_DO.TypeContact && lead.ActionFaite_DO.TypeContact.Description === 'Appel Sortant' ?
                              <Link to={'/lead/create?EstAppelSortant=true&prospect_id='+lead.Prospect.IDProspect+'&IDLead='+lead.IDLead}>
                                <TableRowDateRelance date={lead.ActionAFaire_TODO.DateHRelance} />
                              </Link>
                            :
                              <Link to={'/rapport'+ (!lead.Affaire || !lead.Affaire.OrigineAction || lead.Affaire.Type === 'VN' || lead.Affaire.Type === 'VO' ? '' : 'Atelier') +'/'+lead.IDLead} className={moment(lead.ActionAFaire_TODO.DateHRelance).diff(moment()) < 0 ? 'color red' : null}>
                                <TableRowDateRelance date={lead.ActionAFaire_TODO.DateHRelance} />
                              </Link>
                            }
                          </td>
                          <td className='text-center'>
                            <TableRowComment comment={lead.ActionFaite_DO.Commentaire} />
                          </td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            : <Loader style={{ marginTop: 150 }} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Actions;
