import React, { Component } from 'react';

import Loader from '../Loader';
import Globals from '../../Globals';

import moment from 'moment'
var lodash = require('lodash')

class OverlayAffaire extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr');

    this.state = {
      affaires: [],
    }
  }

  componentWillMount() {
    this.fetch();
  }

  fetch() {
    fetch(localStorage.ClientApiURL + '/Prospects/' + this.props.prospect.IDProspect + '/affaires', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'APIKey': Globals.API_KEY,
        'IDClient': localStorage.user_IDClient,
        'Login': localStorage.user_login,
        'Password': localStorage.user_password
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {

      var affaires = lodash.filter(json, (affaire) => {
        return ((affaire.Statut === 'AValider') || (affaire.Statut === 'EnCours'));
      });

      this.setState({
        affaires: affaires,
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  render() {
    return (<div style={{ width: '200px' }}>
      {this.state.affaires.length ?
        this.state.affaires.map((affaire, i) => {
          return <div key={i} style={{ fontFamily: 'sans-serif', fontWeight: 'light', fontSize: '11px', marginBottom: '2px', marginTop: '2px' }}>Type : {affaire.OrigineAction ? affaire.Type : ''} - Date de début : {moment(affaire.DateDebut).format('DD/MM/Y')}</div>
        })
      : <Loader styleIcon={{ fontSize: '12px', color: 'white' }} /> }
    </div>);
  }
}

export default OverlayAffaire;
